import classNames from 'classnames';
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react";
import ReactFlow, { Background, Controls, Edge, Node, NodeProps, NodeTypes, OnInit, ReactFlowInstance, ReactFlowProps, useReactFlow } from 'reactflow';
import { GraphElements } from '../../config/constants';
import { Icons } from "../../config/icons";
import { ActionButton, ActionButtons, IActionButtonProps } from "../button";
import { FloatingGraphEdge, GraphEdgeConnectionLine } from './edge';
import { getDagreLayoutedElements, getForceFieldLayoutedElements } from './layouts';

export type IGraphCardProps<T extends unknown = any> = NodeProps<(T & {}) | undefined>;

export const createRedirectState = (nodes: {id: string, type: GraphElements}[]) => {
    return {
        nodes,
    };
}

export type IGraphInstance = {
    layout: (type?: "dagre" | "force") => void;
} & ReactFlowInstance;

export type IGraphProps<NodeData extends unknown = any, EdgeData extends unknown = any, ChangesType extends unknown = any> = {
    nodeTypes: NodeTypes;
    children?: ReactNode;
    nodes: Node<NodeData, string | undefined>[];
    setNodes: Dispatch<SetStateAction<Node<NodeData, string | undefined>[]>>;
    onNodesChange: (changes: ChangesType[]) => void;
    edges: Edge<EdgeData>[];
    setEdges: Dispatch<SetStateAction<Edge<EdgeData>[]>>;
    onEdgesChange: (changes: ChangesType[]) => void;
    actions?: IActionButtonProps[];
    onReady?: (instance: IGraphInstance) => void;
} & Partial<ReactFlowProps>;

export const Graph: FC<IGraphProps> = (props) => {
    const { fitView } = useReactFlow();
    const [isLayingOut, setIsLayingOut] = useState(false);
    const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();

    const edgeTypes = useMemo(() => ({
        floatingGraphEdge: FloatingGraphEdge,
    }), []);

    const onLayout = useCallback((type: "dagre" | "force" = "dagre") => {
        const nodes = getNodes();
        const edges = getEdges();

        if (nodes.length === 0) {
            return;
        }

        let layouted: { nodes: Node[], edges: Edge[] } = { nodes: [], edges: [] };
        if (type === "dagre") {
            layouted = getDagreLayoutedElements(nodes, edges);
        } else if (type === "force") {
            layouted = getForceFieldLayoutedElements(nodes, edges);
        }

        setIsLayingOut(true);
        setNodes(layouted.nodes);
        setEdges(layouted.edges);

        setTimeout(() => {
            setIsLayingOut(false);
            fitView({
                duration: 300,
            });
        }, 350);
    }, [fitView, getEdges, getNodes, setEdges, setNodes]);

    const handleInit: OnInit = useCallback((instance) => {
        setTimeout(() => {
            fitView({
                minZoom: 1,
                duration: 500,
                padding: 100,
            });
        }, 100);
        const graphInstance = instance as IGraphInstance;
        graphInstance.layout = onLayout;
        props.onReady?.(graphInstance);
    }, [fitView, onLayout, props]);

    const buttons = useMemo(() => {
        if (props.nodes.length === 0) {
            return [];
        }
        const actionButtons = [
            {
                icon: Icons.GraphLayout,
                onClick: () => onLayout("dagre"),
                children: <div className="hidden group-[.action-buttons_>_*:nth-child(2):hover_&]:flex absolute top-0 right-0 mr-12 pr-2">
                    <ActionButton className="rotate-45" icon={Icons.ForceLayout} onClick={() => onLayout("force")} />
                </div>,
            }
        ];
        if (props.actions == null) {
            return actionButtons;
        }
        return [
            ...props.actions,
            ...actionButtons,
        ]
    }, [onLayout, props.actions, props.nodes.length]);

    return <ReactFlow
        className={classNames("group", {
            "laying-out": isLayingOut,
        })}
        {...props}
        nodeTypes={props.nodeTypes}
        edgeTypes={edgeTypes}
        nodes={props.nodes}
        edges={props.edges}
        panOnScroll
        selectionOnDrag
        onNodesChange={props.onNodesChange}
        onEdgesChange={props.onEdgesChange}
        proOptions={{
            hideAttribution: true,
        }}
        fitView
        onInit={handleInit}
        connectionLineComponent={GraphEdgeConnectionLine}
    >
        <Background />
        <Controls />
        <div className="flex flex-row gap-2 absolute bottom-8 right-5 z-10">
            {
                <ActionButtons buttons={buttons} />
            }
        </div>
        {props.children}
    </ReactFlow>
}