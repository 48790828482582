import { motion } from "framer-motion";
import { FC, ReactElement, ReactNode, useState } from "react";

type ITooltipProps = {
    tooltip: ReactNode;
    children: ReactElement | ReactElement[];
}

export const Tooltip: FC<ITooltipProps> = (props) => {
    const [hover, setHover] = useState(false);
    const status = hover ? "open" : "close";
    return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {props.children}
        <motion.div className="absolute z-10 min-w-[150px] inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm right-0 max-w-[200px]"
            initial={status} variants={{
                open: {
                    opacity: 100,
                    y: 0,
                    scale: 1,
                    display: "flex",
                },
                close: {
                    opacity: 0,
                    y: -20,
                    scale: 0.5,
                    transitionEnd: {
                        display: "none",
                    }
                },
            }} animate={status}>
            {props.tooltip}
        </motion.div>
    </div>
}