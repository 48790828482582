import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CreateCard } from "../../../components/card";
import { Page } from "../../../components/container";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { HookRunCard, IHookRun } from "./hook-run-card";
import { notify } from "../../../store/function";
import { CI_CD_HOOK_ICON } from "./hooks-card";

type IHookRunProps = {}

const getHookRunQuery = loader("./get-hook-runs.graphql");
const runHookMutation = loader("./run-hook.graphql");

export const HookRunPage: FC<IHookRunProps> = () => {
    const [runHook, ] = useMutation(runHookMutation);
    const [getHookRuns, getHookRunsResponse] = useLazyQuery(getHookRunQuery);
    const [searchParams, ] = useSearchParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        getHookRuns({
            variables: {
                hookId: searchParams.get("id"),
            }
        });
    }, [searchParams, navigate, getHookRuns]);

    const handleRun = useCallback(() => {
        const hookId = searchParams.get("id");
        runHook({
            variables: {
                id: hookId,
            },
            onCompleted() {
                notify("Running hook!", "success");
                getHookRunsResponse.refetch({
                    variables: {
                        hookId: hookId,
                    }
                });
            },
        });
    }, [searchParams, runHook, getHookRunsResponse]);

    const hookId = useMemo(() => searchParams.get("id"), [searchParams]);

    if (hookId == null) {
        return <Navigate to={InternalRoutes.CI_CD.HookRun.path} />
    }

    return (
        <Page routes={[InternalRoutes.CI_CD.Hooks, InternalRoutes.CI_CD.HookRun]}>
            <CreateCard icon={{
                    component: Icons.Play,
                    bgClassName: CI_CD_HOOK_ICON.bgClassName,
                }} label="Run your hook" actionLabel="Run" onClick={handleRun} />
            {
                !getHookRunsResponse.loading && (getHookRunsResponse.data?.HookRuns?.map((hookRun: IHookRun, i: number) => (
                    <HookRunCard name={`Run ${getHookRunsResponse.data.HookRuns.length - i}`} hookRun={hookRun} hookId={hookId} />
                )))
            }
        </Page>
    )
}