import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { forEach } from "lodash";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Edge, Node, ReactFlowProps, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import { AnimatedButton, AnimatedButtonDropdown } from "../../../components/button";
import { AdjustableCard, ExpandableCard, GraphBasedExpandableCard } from "../../../components/card";
import { Graph, IGraphCardProps, IGraphInstance, IGraphProps } from "../../../components/graph/graph";
import { InputWithlabel } from "../../../components/input";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { notify } from "../../../store/function";
import { copyToClipboard, getCenterPosition } from "../../../utils/functions";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { FlowGraphViewContext, IFlowGraphData, IFlowGraphViewCache } from "./context";
import { getAllFlowQuery } from "./flow";
import { FlowElements } from "./flow-step-card";
import { DockerBuildFlowStepCard, ICreateDockerBuildFlowFlowStep } from "./steps/docker-build-flow-step-card";
import { DockerPushFlowStepCard, ICreateDockerPushFlowStep } from "./steps/docker-push-flow-step-card";
import { GitChangeBranchFlowStepCard, ICreateGitChangeBranchFlowStep } from "./steps/git-change-branch-flow-step-card";
import { GitHubPullFlowStepCard, ICreateGitPullFlowStep } from "./steps/git-pull-flow-step-card";
import { StartingSelectorFlowStep } from "./steps/start-selector-flow-step";
import { createEdge, createNode } from "../../../components/graph/utils";

export const CI_CD_FLOW_ICON = {
    component: Icons.CI_CD.Flow.Default,
    bgClassName: "bg-orange-500",
}

const getFlowStepsQuery = loader("./get-flow-steps.graphql");
const getOneFlowQuery = loader("./get-one-flow.graphql");
const createFlowMutation = loader("./create-flow.graphql");
const updateFlowMutation = loader("./update-flow.graphql");
const deleteFlowMutation = loader("./delete-flow.graphql");
const createFlowShareableStatusMutation = loader("./create-flow-shareable-status.graphql");

type IGraphFlowCardProps = Pick<IGraphProps, "nodes" | "setNodes" | "onNodesChange" | "edges" | "setEdges" | "onEdgesChange" | "onReady"> & ReactFlowProps;

const GraphFlowCard: FC<IGraphFlowCardProps> = ({ nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, ...props}) => {
    const { setViewport } = useReactFlow();
    const nodeTypes = useMemo(() => ({
        [FlowElements.Starting]: StartingSelectorFlowStep,
        [FlowElements.GitPull]: GitHubPullFlowStepCard,
        [FlowElements.GitChangeBranch]: GitChangeBranchFlowStepCard,
        [FlowElements.DockerBuild]: DockerBuildFlowStepCard,
        [FlowElements.DockerPush]: DockerPushFlowStepCard,
    }), []);

    const handleInit = useCallback(() => {
        if (nodes.length === 0) {
            return;
        }
        setViewport(getCenterPosition(nodes[0], window.innerHeight, window.innerWidth / 2));
    }, [nodes, setViewport]);

    return (
        <Graph nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            minZoom={0.5}
            onInit={handleInit}
            {...props} />
    )
}


export type ICreateFlowStep = (ICreateGitPullFlowStep | ICreateGitChangeBranchFlowStep | ICreateDockerBuildFlowFlowStep | ICreateDockerPushFlowStep);
type IFlowStep = { Id: string } & ICreateFlowStep;

type ICreateFlowCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateFlowCard: FC<ICreateFlowCardProps> = (props) => {
    const [createFlow, ] = useMutation(createFlowMutation);
    const [cache, setCache] = useImmer<IFlowGraphViewCache>({});
    const [name, setName] = useState("");
    const expandCardRef = useRef<Function>();
    const [nodes, setNodes, onNodesChange] = useNodesState([
        createNode({
            id: v4(),
            type: FlowElements.Starting,
            data: undefined,
            deletable: false,
        })
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const handleExpand = useCallback(() => {
        expandCardRef.current?.(true);
    }, [expandCardRef]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    const handleSubmit = useCallback(() => {
        const steps: ICreateFlowStep[] = nodes.map(nd => cache[nd.id].getForm());
        createFlow({
            variables: {
                name,
                steps,
            },
            onCompleted: () => {
                notify("Flow created successfully", "success");
                handleCancel();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create flow", "error");
            },
        });
    }, [nodes, cache, name, createFlow, props, handleCancel]);

    const handleSetCacheItem = useCallback((key: string, value: IFlowGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    return (
        <FlowGraphViewContext.Provider value={{
            cache,
            setCacheItem: handleSetCacheItem,
            removeCacheItem: handleRemoveCacheItem,
        }}>
            <ExpandableCard isExpanded={props.isCreating} fullScreen={true} icon={{
                component: Icons.Add,
                bgClassName: CI_CD_FLOW_ICON.bgClassName,
            }} tag={<div className="flex justify-between transition-all grow px-4">
                <InputWithlabel label="Name" value={name} setValue={setName} />
                <div className="flex flex-row gap-1 items-end">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </div>} setToggleCallback={ref => expandCardRef.current = ref}>
                <>
                    <div className="grow text-lg mt-2">Create flow</div>
                    <div className="flex items-center">
                        <AnimatedButton label="Create" icon={Icons.Add} onClick={handleExpand} />
                    </div>
                </>
                <ReactFlowProvider>
                    <GraphFlowCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                   edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} />
                </ReactFlowProvider>
            </ExpandableCard>
        </FlowGraphViewContext.Provider>
    )
}

export type IFlow = {
    Id: string;
    Name: string;
}

type IFlowCardProps = {
    flow: IFlow;
    refetch?: () => void;
}

export const FlowCard: FC<IFlowCardProps> = (props) => {
    const [getFlowSteps, ] = useLazyQuery(getFlowStepsQuery);
    const [updateFlow, ] = useMutation(updateFlowMutation);
    const [deleteFlow, ] = useMutation(deleteFlowMutation);
    const [createFlowShareableStatus, ] = useMutation(createFlowShareableStatusMutation);
    const [cache, setCache] = useImmer<IFlowGraphViewCache>({});
    const [name, setName] = useState(props.flow.Name);
    const expandCardRef = useRef<Function>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const reactFlowRef = useRef<IGraphInstance>();

    const handleOnReady = useCallback((instance: IGraphInstance) => {
        reactFlowRef.current = instance;
    }, []);


    const handleExpand = useCallback(() => {
        getFlowSteps({
            variables: {
                flowId: props.flow.Id,
            },
            onCompleted(data) {
                const newNodes: Node[] = data?.FlowSteps?.map((flowStep: IFlowStep, i: number) => {
                    const node = createNode({
                        id: flowStep.Id,
                        type: flowStep.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(flowStep.Type) {
                        case FlowElements.GitPull:
                            node.data = flowStep.GitPull;
                            break;
                        case FlowElements.GitChangeBranch:
                            node.data = flowStep.GitChangeBranch;
                            break;
                        case FlowElements.DockerBuild:
                            node.data = flowStep.DockerBuild;
                            break;
                        case FlowElements.DockerPush:
                            node.data = flowStep.DockerPush;
                            // todo: when layout system is in place, this won't be required
                            node.position.x += 200;
                            break;
                    }
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(newNodes, (nd, i) => {
                    if (i >= (newNodes.length - 1) ) {
                        return;
                    }
                    newEdges.push(createEdge(nd.id, newNodes[i+1].id));
                });
                setEdges(newEdges);
                setTimeout(() => {
                    reactFlowRef.current?.layout("dagre");
                }, 300);
            },
        });
        expandCardRef.current?.(true);
    }, [expandCardRef, getFlowSteps, props.flow.Id, setNodes, setEdges]);

    const handleCopyStatusLink = useCallback(() => {
        createFlowShareableStatus({
            variables: {
                id: props.flow.Id,
            },
            onCompleted(data) {
                if (data?.CreateFlowShareableStatus?.Link == null) {
                    return notify("Unable to copy status shareable link", "error");
                }
                copyToClipboard(data.CreateFlowShareableStatus.Link);
                notify("Copied status shareable link", "success");
            },
            onError() {
                notify("Unable to copy status shareable link", "error");
            },
        });
    }, [createFlowShareableStatus, props.flow.Id]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    const handleUpdate = useCallback(() => {
        const steps: ICreateFlowStep[] = nodes.map(nd => cache[nd.id].getForm());
        updateFlow({
            variables: {
                id: props.flow.Id,
                name,
                steps,
            },
            onCompleted: () => {
                notify("Flow updated successfully", "success");
                handleCancel();
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to update flow", "error");
            },
        });
    }, [nodes, cache, name, updateFlow, props, handleCancel]);

    const handleDelete = useCallback(() => {
        deleteFlow({
            variables: {
                id: props.flow.Id,
            },
            onCompleted: () => {
                notify("Flow deleted successfully", "success");
                handleCancel();
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete flow", "error");
            },
        });
    }, [deleteFlow, props, handleCancel]);

    const handleSetCacheItem = useCallback((key: string, value: IFlowGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    const handleNavigateRuns = useCallback(() => {
        navigate({
            pathname: InternalRoutes.CI_CD.FlowRun.path,
            search: createSearchParams({
                id: props.flow.Id,
            }).toString(),
        });
    }, [navigate, props.flow]);

    return <FlowGraphViewContext.Provider value={{
        cache,
        setCacheItem: handleSetCacheItem,
        removeCacheItem: handleRemoveCacheItem,
    }}>
        <GraphBasedExpandableCard id={props.flow.Id} type={GraphElements.Flow} fullScreen={true} icon={{
            component: Icons.CI_CD.Flow.Default,
            bgClassName: CI_CD_FLOW_ICON.bgClassName,
        }} tag={<div className="flex justify-between transition-all grow px-4">
            <InputWithlabel label="Name" value={name} setValue={setName} />
            <div className="flex flex-row gap-1 items-end">
                <AnimatedButton className="mr-8" label="Delete" icon={Icons.Delete} onClick={handleDelete} labelClassName="text-red-800" iconClassName="text-red-600" />
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleUpdate} />
            </div>
        </div>} setToggleCallback={ref => expandCardRef.current = ref} highlight={searchParams.get("id") === props.flow.Id}>
            <>
                <div className="grow text-lg mt-2">{props.flow.Name}</div>
                <div className="flex flex-row items-center justify-between">
                    <AnimatedButtonDropdown buttons={[
                        {
                            label:"Edit",
                            icon: Icons.Edit,
                            onClick: handleExpand,
                        },
                        {
                            label:"Copy status link",
                            icon: Icons.Clipboard,
                            onClick: handleCopyStatusLink,
                        },
                    ]} />
                    <AnimatedButton label="Runs" icon={Icons.Play} onClick={handleNavigateRuns} />
                </div>
            </>
            <ReactFlowProvider>
                <GraphFlowCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                    edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onReady={handleOnReady} />
            </ReactFlowProvider>
        </GraphBasedExpandableCard>
    </FlowGraphViewContext.Provider>
}

export const FlowDashboardCard: FC<{ flow: IFlow }> = (props) => {
    return <AdjustableCard showTools={true} icon={CI_CD_FLOW_ICON}>
        <div className="flex flex-col grow mt-2">
            <div className="text-md">
                {props.flow.Name}
            </div>
        </div>
    </AdjustableCard>
}

export const transformFlowData: IDataTransform<IFlow>  = (data: { Flow: IFlow[]}) => data.Flow;

export const FlowGraphCard: FC<IGraphCardProps<IFlow>> = (props) => {
    return <GraphCardLoader loader={getOneFlowQuery} transform={(data) => transformFlowData(data)[0]} {...props}>
        {(data: IFlow) => (<FlowDashboardCard flow={data} />)}
    </GraphCardLoader>;
}

export const AddFlowDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Flows"
        query={getAllFlowQuery}
        transform={transformFlowData} type={GraphElements.Flow}
        icon={Icons.CI_CD.Flow.Default}
        link={InternalRoutes.CI_CD.CreateFlow.path} />
    );
}