import { find, map } from "lodash";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Handle, Position, getIncomers, useReactFlow } from "reactflow";
import { Card } from "../../../../components/card";
import { IDropdownItem } from "../../../../components/dropdown";
import { IGraphCardProps } from "../../../../components/graph/graph";
import { Search } from "../../../../components/search";
import { Icons } from "../../../../config/icons";
import { HooksElements } from "../hooks-step-card";
import { HookGraphViewContext } from "../context";

const allStepsMap = {
    [HooksElements.GitWebHook]: {
        id: HooksElements.GitWebHook,
        label: "Git Web Hook",
        icon: Icons.Logos.GitHub,
    },
    [HooksElements.FlowOperation]: {
        id: HooksElements.FlowOperation,
        label: "Flow operations",
        icon: Icons.CI_CD.Flow.Default,
    },
    [HooksElements.OperationResult]: {
        id: HooksElements.OperationResult,
        label: "Operations results handler",
        icon: Icons.CI_CD.Flow.Default,
    },
    [HooksElements.QuickContainerOperation]: {
        id: HooksElements.QuickContainerOperation,
        label: "Quick container operations",
        icon: Icons.Deploy.QuickContainer.Default,
    },
    [HooksElements.GitPullRequestComment]: {
        id: HooksElements.GitPullRequestComment,
        label: "Git pull request comment",
        icon: Icons.Logos.GitHub,
    },
}

export const StartingSelectorHooksStep: FC<IGraphCardProps> = (props) => {
    const [steps, setSteps] = useState<IDropdownItem[]>([]);
    const { setNodes, getNodes, getEdges, getNode } = useReactFlow();
    const { cache } = useContext(HookGraphViewContext);
    const handleSelect = useCallback((item: IDropdownItem) => {
        setNodes(nodes => {
            for (const node of nodes) {
                if (node.id === props.id) {
                    node.type = item.id;
                }
            }
            return nodes;
        });
    }, [props, setNodes]);


    useEffect(() => {
        const node = getNode(props.id);
        if (node == null) {
            return;
        }
        const nodes = getNodes();
        const edges = getEdges();
        const nodesSet = new Set(map(nodes, node => node.type));
        const references = getIncomers(node, nodes, edges);
        setSteps(() => {
            const stepsToAdd = [];
            if (!nodesSet.has(HooksElements.GitWebHook)) {
                stepsToAdd.push(allStepsMap[HooksElements.GitWebHook]);
            }
            if (nodesSet.has(HooksElements.GitWebHook) && find(references, (reference) => reference.type === HooksElements.FlowOperation) == null) {
                stepsToAdd.push(allStepsMap[HooksElements.FlowOperation]);
            }
            if (nodesSet.has(HooksElements.GitWebHook)) {
                const gitWebHook = nodes.find(node => node.type === HooksElements.GitWebHook)!;
                if (gitWebHook != null && gitWebHook.id in cache) {
                    const form = cache[gitWebHook.id].getForm();
                    if (form.Type === HooksElements.GitWebHook && form.GitWebHook.Events.includes("pull_request")) {
                        stepsToAdd.push(allStepsMap[HooksElements.GitPullRequestComment]);
                    }
                }
            }
            if (find(references, (reference) => reference.type === HooksElements.FlowOperation || reference.type === HooksElements.QuickContainerOperation) != null) {
                stepsToAdd.push(allStepsMap[HooksElements.OperationResult]);
            }
            if (nodesSet.has(HooksElements.FlowOperation) && find(references, (reference) => reference.type === HooksElements.QuickContainerOperation) == null) {
                stepsToAdd.push(allStepsMap[HooksElements.QuickContainerOperation]);
            }
            return stepsToAdd;
        });
    }, [props.id, getEdges, getNodes, getNode, cache]);

    return (<>
        <Handle type="target" position={Position.Left} />
        <Card icon={{
            component: Icons.Add,
            bgClassName: "bg-teal-500",
        }} className="relative w-[300px] h-[300px] group/hook-step-selector">
            <div className="flex flex-col gap-1 my-4 grow">
                <div className="text-lg my-2">Create step</div>
                <Search label="Search for steps" className="flex-grow" items={steps} isOpen={true} onSelect={handleSelect} noItemsLabel="No steps found" dropdownProps={{
                    scrollContainerClassName: "h-28",
                }} />
            </div>
        </Card>
        <Handle type="source" position={Position.Right} />
    </>)
}
