import { createContext } from "react";
import { IValidationCallback } from "../../../components/form";
import { ICreateHookStep } from "./hooks-card";


export type IHookGraphData = {
    getForm: () => ICreateHookStep;
    isFormValid: IValidationCallback;
}
export type IHookGraphViewCache = Record<string, IHookGraphData>;
export type ISetCacheItem = (key: string, data: IHookGraphData) => void;

export type IHookGraphViewData = {
    cache: IHookGraphViewCache;
    setCacheItem: ISetCacheItem;
    removeCacheItem: (key: string) => void;
}

export const HookGraphViewContext = createContext<IHookGraphViewData>({} as IHookGraphViewData);
