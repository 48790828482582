import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Node, Edge, ReactFlowProps, ReactFlowProvider, getIncomers, getOutgoers, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import { AnimatedButton } from "../../../components/button";
import { AdjustableCard, ExpandableCard, GraphBasedExpandableCard } from "../../../components/card";
import { Graph, IGraphCardProps, IGraphInstance, IGraphProps } from "../../../components/graph/graph";
import { createNode, createEdge } from "../../../components/graph/utils";
import { InputWithlabel } from "../../../components/input";
import { Icons } from "../../../config/icons";
import { getCenterPosition } from "../../../utils/functions";
import { HookGraphViewContext, IHookGraphData, IHookGraphViewCache } from "./context";
import { HooksElements } from "./hooks-step-card";
import { FlowOperationHookStepCard, ICreateFlowOperationHookStep } from "./steps/flow-operation-hook-step-card";
import { OperationResultHookStepCard, ICreateOperationResultHookStep } from "./steps/operation-result-hook-step-card";
import { GitWebHookHookStepCard, ICreateGitWebHookHookStep } from "./steps/git-webhook-hook-step-card";
import { StartingSelectorHooksStep } from "./steps/starting-selector-hooks-node";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { loader } from "graphql.macro";
import { notify } from "../../../store/function";
import { forEach, map } from "lodash";
import { InternalRoutes } from "../../../config/internal-routes";
import { IDataTransform } from "../../dashboard/context";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { getAllHookQuery } from "./hooks";
import { GraphElements } from "../../../config/constants";
import { ICreateQuickContainerOperationHookStep, QuickContainerOperationHookStepCard } from "./steps/quick-container-operation-hook-step-card";
import { GitPullRequestCommentHookStepCard, ICreateGitPullRequestCommentHookStep } from "./steps/git-pr-comment-hook-step-card";

export const CI_CD_HOOK_ICON = {
    component: Icons.CI_CD.Hooks.Default,
    bgClassName: "bg-orange-500",
}

const getHookStepsQuery = loader("./get-hook-steps.graphql");
const getOneHookQuery = loader("./get-one-hook.graphql");
const createHookMutation = loader("./create-hook.graphql");
const updateHookMutation = loader("./update-hook.graphql");
const deleteHookMutation = loader("./delete-hook.graphql");

type IGraphHooksCardProps = Pick<IGraphProps, "nodes" | "setNodes" | "onNodesChange" | "edges" | "setEdges" | "onEdgesChange" | "onReady"> & ReactFlowProps;

export const GraphHooksCard: FC<IGraphHooksCardProps> = ({ nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, ...props}) => {
    const { setViewport } = useReactFlow();
    const nodeTypes = useMemo(() => ({
        [HooksElements.Starting]: StartingSelectorHooksStep,
        [HooksElements.GitWebHook]: GitWebHookHookStepCard,
        [HooksElements.FlowOperation]: FlowOperationHookStepCard,
        [HooksElements.OperationResult]: OperationResultHookStepCard,
        [HooksElements.QuickContainerOperation]: QuickContainerOperationHookStepCard,
        [HooksElements.GitPullRequestComment]: GitPullRequestCommentHookStepCard,
    }), []);

    const handleInit = useCallback(() => {
        if (nodes.length === 0) {
            return;
        }
        setViewport(getCenterPosition(nodes[0], window.innerHeight, window.innerWidth / 2));
    }, [nodes, setViewport]);

    return (
        <Graph nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            minZoom={0.5}
            onInit={handleInit}
            {...props} />
    )
}

export type ICreateHookStep = (ICreateGitWebHookHookStep | ICreateFlowOperationHookStep
                | ICreateOperationResultHookStep | ICreateQuickContainerOperationHookStep
                | ICreateGitPullRequestCommentHookStep);
export type IHookStep = {
    Id: string,
    NodeId: string;
    Dependents: string[];
    References: string[];
} & ICreateHookStep;

type ICreateHooksCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateHooksCard: FC<ICreateHooksCardProps> = (props) => {
    const [createHook, ] = useMutation(createHookMutation);
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [name, setName] = useState("");
    const expandCardRef = useRef<Function>();
    const [nodes, setNodes, onNodesChange] = useNodesState([
        createNode({
            id: v4(),
            type: HooksElements.Starting,
            data: undefined,
            deletable: false,
        })
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const handleExpand = useCallback(() => {
        expandCardRef.current?.(true);
    }, [expandCardRef]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    const handleSubmit = useCallback(() => {
        const steps: Omit<IHookStep, "Id">[] = nodes.map(nd => {
            const nodeData = cache[nd.id].getForm();
            const references = map(getIncomers(nd, nodes, edges), node => node.id);
            const dependents = map(getOutgoers(nd, nodes, edges), node => node.id);
            return {
                ...nodeData,
                NodeId: nd.id,
                References: references,
                Dependents: dependents,
            }
        });
        createHook({
            variables: {
                name,
                steps,
            },
            onCompleted: () => {
                notify("Hook created successfully", "success");
                handleCancel();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create hook", "error");
            },
        });
    }, [cache, createHook, edges, handleCancel, name, nodes, props]);

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    return (
        <HookGraphViewContext.Provider value={{
            cache,
            setCacheItem: handleSetCacheItem,
            removeCacheItem: handleRemoveCacheItem,
        }}>
            <ExpandableCard isExpanded={props.isCreating} fullScreen={true} icon={{
                component: Icons.Add,
                bgClassName: CI_CD_HOOK_ICON.bgClassName,
            }} tag={<div className="flex justify-between transition-all grow px-4">
                <InputWithlabel label="Name" value={name} setValue={setName} />
                <div className="flex flex-row gap-1 items-end">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </div>} setToggleCallback={ref => expandCardRef.current = ref}>
                <>
                    <div className="grow text-lg mt-2">Create hook</div>
                    <div className="flex items-center">
                        <AnimatedButton label="Create" icon={Icons.Add} onClick={handleExpand} />
                    </div>
                </>
                <ReactFlowProvider>
                    <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                   edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} />
                </ReactFlowProvider>
            </ExpandableCard>
        </HookGraphViewContext.Provider>
    )
}



export type IHook = {
    Id: string;
    Name: string;
    Status: string;
}

type IHookCardProps = {
    hook: IHook;
    refetch?: () => void;
}

export const HookCard: FC<IHookCardProps> = (props) => {
    const [getHookSteps, ] = useLazyQuery(getHookStepsQuery);
    const [updateHook, ] = useMutation(updateHookMutation);
    const [deleteHook, ] = useMutation(deleteHookMutation);
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [name, setName] = useState(props.hook.Name);
    const expandCardRef = useRef<Function>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const reactFlowRef = useRef<IGraphInstance>();

    const handleOnReady = useCallback((instance: IGraphInstance) => {
        reactFlowRef.current = instance;
    }, []);

    const handleExpand = useCallback(() => {
        getHookSteps({
            variables: {
                hookId: props.hook.Id,
            },
            onCompleted(data?: { HookSteps: IHookStep[] }) {
                const hookSteps = data?.HookSteps ?? [];
                const newNodes: Node[] = hookSteps.map((hookStep, i) => {
                    const node = createNode({
                        id: hookStep.NodeId,
                        type: hookStep.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(hookStep.Type) {
                        case HooksElements.GitWebHook:
                            node.data = hookStep.GitWebHook;
                            break;
                        case HooksElements.FlowOperation:
                            node.data = hookStep.FlowOperation;
                            break;
                        case HooksElements.OperationResult:
                            node.data = hookStep.OperationResult;
                            break;
                        case HooksElements.QuickContainerOperation:
                            node.data = hookStep.QuickContainerOperation;
                            break;
                        case HooksElements.GitPullRequestComment:
                            node.data = hookStep.GitPullRequestComment;
                            break;
                    }
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(hookSteps, (hookStep) => {
                    forEach(hookStep.Dependents, dependent => {
                        newEdges.push(createEdge(hookStep.NodeId, dependent));
                    });
                });
                setEdges(newEdges);
                setTimeout(() => {
                    reactFlowRef.current?.layout("dagre");
                }, 300);
            },
        });
        expandCardRef.current?.(true);
    }, [expandCardRef, getHookSteps, props.hook.Id, setNodes, setEdges]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    const handleUpdate = useCallback(() => {
        const steps: Omit<IHookStep, "Id">[] = nodes.map(nd => {
            const nodeData = cache[nd.id].getForm();
            const references = map(getIncomers(nd, nodes, edges), node => node.id);
            const dependents = map(getOutgoers(nd, nodes, edges), node => node.id);
            return {
                ...nodeData,
                NodeId: nd.id,
                References: references,
                Dependents: dependents,
            }
        });
        updateHook({
            variables: {
                id: props.hook.Id,
                name,
                steps,
            },
            onCompleted: () => {
                notify("Hook updated successfully", "success");
                handleCancel();
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to update hook", "error");
            },
        });
    }, [nodes, updateHook, props, name, cache, edges, handleCancel]);

    const handleDelete = useCallback(() => {
        deleteHook({
            variables: {
                id: props.hook.Id,
            },
            onCompleted: () => {
                notify("Hook deleted successfully", "success");
                handleCancel();
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete hook", "error");
            },
        });
    }, [deleteHook, props, handleCancel]);

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    const handleNavigateRuns = useCallback(() => {
        navigate({
            pathname: InternalRoutes.CI_CD.HookRun.path,
            search: createSearchParams({
                id: props.hook.Id,
            }).toString(),
        });
    }, [navigate, props.hook]);

    return <HookGraphViewContext.Provider value={{
        cache,
        setCacheItem: handleSetCacheItem,
        removeCacheItem: handleRemoveCacheItem,
    }}>
        <GraphBasedExpandableCard id={props.hook.Id} type={GraphElements.Hook} fullScreen={true} icon={CI_CD_HOOK_ICON} tag={<div className="flex justify-between transition-all grow px-4">
            <InputWithlabel label="Name" value={name} setValue={setName} />
            <div className="flex flex-row gap-1 items-end">
                <AnimatedButton className="mr-8" label="Delete" icon={Icons.Delete} onClick={handleDelete} labelClassName="text-red-800" iconClassName="text-red-600" />
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleUpdate} />
            </div>
        </div>} setToggleCallback={ref => expandCardRef.current = ref} highlight={searchParams.get("id") === props.hook.Id}>
            <>
                <div className="grow text-lg mt-2">{props.hook.Name}</div>
                <div className="flex flex-row items-center justify-between">
                    <AnimatedButton label="Edit" icon={Icons.Edit} onClick={handleExpand} />
                    <AnimatedButton label="Runs" icon={Icons.Play} onClick={handleNavigateRuns} />
                </div>
            </>
            <ReactFlowProvider>
                <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                    edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onReady={handleOnReady} />
            </ReactFlowProvider>
        </GraphBasedExpandableCard>
    </HookGraphViewContext.Provider>
}

export const HookDashboardCard: FC<{ hook: IHook }> = (props) => {
    return <AdjustableCard showTools={true} icon={CI_CD_HOOK_ICON}>
        <div className="flex flex-col grow mt-2">
            <div className="text-md">
                {props.hook.Name}
            </div>
        </div>
    </AdjustableCard>
}

export const transformHookData: IDataTransform<IHook>  = (data: { Hook: IHook[]}) => data.Hook;

export const HookGraphCard: FC<IGraphCardProps<IHook>> = (props) => {
    return <GraphCardLoader loader={getOneHookQuery} transform={(data) => transformHookData(data)[0]} {...props}>
        {(data: IHook) => (<HookDashboardCard hook={data} />)}
    </GraphCardLoader>;
}

export const AddHookDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Hooks"
        query={getAllHookQuery}
        transform={transformHookData} type={GraphElements.Hook}
        icon={Icons.CI_CD.Hooks.Default}
        link={InternalRoutes.CI_CD.CreateHook.path} />
    );
}