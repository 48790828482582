import { map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { InternalRoutes } from "../../../../config/internal-routes"
import { getAllFlowQuery } from "../../flow/flow"
import { IFlow } from "../../flow/flow-card"
import { HookGraphViewContext } from "../context"
import { HooksElements, HooksStepCard } from "../hooks-step-card"


function getDefaultForm(navigate: NavigateFunction, flowOperation?: IFlowOperation, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "flowId",
            label: "Flow",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Flow is required",
            query: getAllFlowQuery,
            transform(data) {
                return map(data?.Flow ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                }));
            },
            defaultIcon: Icons.CI_CD.Flow.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a flow" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.CI_CD.CreateFlow.path);
                },
            },
            defaultValue: flowOperation?.Flow?.Id,
            disabled,
        },
        {
            name: "operation",
            label: "Operation Type",
            type: "dropdown",
            fieldType: "text",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Operation type is required",
            dropdownProps: {
                items: [
                    {
                        id: "Run",
                        label: "Run",
                    }
                ],
            },
            defaultValue: flowOperation?.Type,
            disabled,
        },
    ]
}


export type ICreateFlowOperationHookStep = {
    Type: HooksElements.FlowOperation;
    FlowOperation: {
        Type: string;
        FlowId: string;
    }
}

type IFlowOperation = {
    Type: string;
    Flow: IFlow;
    Status?: string;
};

export type IFlowOperationHookStep = {
    Type: HooksElements.FlowOperation;
    FlowOperation: IFlowOperation;
};

export const FlowOperationHookStepCard: FC<IGraphCardProps<IFlowOperation>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const navigate = useNavigate();
    const [formProps, formCallbacks] = useFormHook();
    const disabled = props.data?.Status != null;

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertFlowOperationFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Flow operations" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(navigate, props.data, disabled)} {...formProps} />
        </HooksStepCard>
    )
}

const convertFlowOperationFormToStep = (form: IForm): ICreateFlowOperationHookStep => {
    return {
        Type: HooksElements.FlowOperation,
        FlowOperation: {
            Type: form.operation as string,
            FlowId: form.flowId as string,
        }
    }
}

export const FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES = ["Name", "Status", "BuildLink"];