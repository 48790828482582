import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { find, map } from "lodash";
import { FC, useCallback, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { useAppQuery } from "../../../api/query";
import { AnimatedButton } from "../../../components/button";
import { AdjustableCard, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { IDropdownItem } from "../../../components/dropdown";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Search } from "../../../components/search";
import { PanelSelector } from "../../../components/selector";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { getOptional } from "../../../utils/functions";

export const CONTAINER_REGISTRY_ICON = {
    component: Icons.Container.Registry.Default,
    bgClassName: "bg-teal-500",
}

enum Panels {
    Starting = "Starting",
    DockerIO = "DockerIO",
}

const integrations: IDropdownItem[] = [
    {
        id: Panels.DockerIO,
        label: "Docker IO",
        icon: Icons.Logos.DockerIO,
    },
]

export const getAllRegistriesQuery = loader("./get-registries.graphql");
const getDockerIORegistryIntegrationsQuery = loader("./get-docker-io-integrations.graphql");
const getOneRegistryQuery = loader("./get-one-registry.graphql");
export const createRegistryMutation = loader("./create-registry.graphql");
const updateRegistryMutation = loader("./update-registry.graphql");
const deleteRegistryMutation = loader("./delete-registry.graphql");

function getDefaultForm(navigate: NavigateFunction, registry?: IRegistry): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: registry?.Name,
        },
        {
            name: "type",
            label: "Registry Type",
            fieldType: "text",
            type: "dropdown",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry type is required",
            defaultIcon: Icons.Container.Registry.Default,
            dropdownProps: {
                items: [
                    {
                        id: "DockerIO",
                        label: "DockerHub",
                    },
                    {
                        id: "PublicDockerIO",
                        label: "Public Docker Hub",
                    },
                ],
            },
            defaultValue: registry?.Type,
        },
        {
            name: "dockerIOId",
            label: "Docker Hub Integration",
            fieldType: "text",
            type: "query",
            validate: (value: string) => value.length > 0,
            errorMessage: "Docker Hub integration is required",
            defaultValue: registry?.DockerIO?.Id,
            query: getDockerIORegistryIntegrationsQuery,
            transform(data) {
                return map(data?.DockerIORegistryIntegration ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Registry.Default,
                }));
            },
            defaultIcon: Icons.Container.Registry.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an integration" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Integrations.CreateIntegrations.path);
                },
            },
            hide(form) {
                return form.type !== "DockerIO";
            },
        },
        {
            name: "namespace",
            label: "Docker Hub Namespace",
            fieldType: "text",
            validate: (namespace: string) => namespace.length > 0,
            errorMessage: "DockerHub namespace is required",
            defaultValue: registry?.Namespace,
            defaultIcon: Icons.Container.Registry.Default,
            hide(form) {
                return form.type !== "PublicDockerIO";
            },
        },
    ]
}

type ICreateRegistryCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateRegistryCard: FC<ICreateRegistryCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createRegistry] = useMutation(createRegistryMutation);
    const ref = useRef<Function>();
    const navigate = useNavigate();
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.DockerIO);
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSelect = useCallback((item: IDropdownItem) => {
        setSelectedPanelId(item.id)
    }, []);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createRegistry({
            variables: {
                name: form.name,
                type: form.type,
                dockerIOId: getOptional(form.dockerIOId),
                namespace: getOptional(form.namespace),
            },
            onCompleted: () => {
                notify("Registry created successfully", "success");
                ref.current?.();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create registry", "error");
            }
        });
    }, [isFormValid, getForm, createRegistry, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Registry" icon={{
                component: Icons.Add,
                bgClassName: CONTAINER_REGISTRY_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<div className="flex flex-col gap-1 items-end">
                    <div className="text-red-800 text-xs">{error}</div>
                </div>}>
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.Starting,
                        component: <div className="flex flex-col gap-1 my-4 grow">
                            <div className="text-lg my-2">Create registry</div>
                            <Search label="Search your integration" className="flex-grow" items={integrations} isOpen={true} onSelect={handleSelect} noItemsLabel="No integrations found" />
                            <div className="flex justify-end">
                                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={() => ref.current?.()} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                            </div>
                        </div>,
                    },
                    {
                        id: Panels.DockerIO,
                        component: <>
                            <div className="flex flex-col gap-1 my-4 grow">
                                <Form variables={getDefaultForm(navigate)} {...formProps} />
                            </div>
                            <div className="flex items-center gap-2">
                                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleClose} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                                <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                            </div>
                        </>
                    },
                ]} />
            </CreateCard>
        </>
    )
}

export type RegistryType = "DockerIO" | "PublicDockerIO";

export type IRegistry = {
    Id: string;
    Name: string;
    Type: RegistryType;
    Status: string;
    DockerIO: {
        Id: string;
        Name: string;
        Username: string;
        Password: string;
    }
    Namespace?: string;
}


export const RegistryIntro: FC<{ registry: IRegistry }> = (props) => {
    const getDockerIORegistryIntegrationsResponse = useAppQuery(getDockerIORegistryIntegrationsQuery);
    const selectedDockerIOIntegrationImage = find(getDockerIORegistryIntegrationsResponse.data?.DockerIORegistryIntegration ?? [], ({ Id }) => props.registry.DockerIO?.Id === Id);

    return <div className="flex flex-col grow mt-2">
        <div className="text-md">
            {props.registry.Name}
        </div>
        <div className="text-xs mt-2">
            <strong>Type:</strong> {find(integrations, integration => integration.id === props.registry.Type)?.label}
        </div>
        {
            selectedDockerIOIntegrationImage != null &&
            <div className="text-xs">
                <strong>Docker IO:</strong> {props.registry.DockerIO.Name}
            </div>
        }
    </div>
}

type IRegistryCardProps = {
    registry: IRegistry;
    refetch: () => void;
}

export const RegistryCard: FC<IRegistryCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteRegistry] = useMutation(deleteRegistryMutation);
    const [updateRegistry] = useMutation(updateRegistryMutation);
    const navigate = useNavigate();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateRegistry({
            variables: {
                id: props.registry.Id,
                name: form.name,
                type: form.type,
                dockerIOId: getOptional(form.dockerIOId),
                namespace: getOptional(form.namespace),
            },
            onCompleted: () => {
                notify("Registry updated successfully", "success");
                props.refetch();
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update registry", "error");
            }
        });
    }, [isFormValid, getForm, updateRegistry, props]);

    const handleDelete = useCallback(() => {
        deleteRegistry({
            variables: {
                Id: props.registry.Id,
            },
            onCompleted: () => {
                notify("Registry deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete registry", "error");
            },
        });
    }, [props, deleteRegistry]);


    return <GraphBasedExpandableCard id={props.registry.Id} type={GraphElements.Registry} icon={CONTAINER_REGISTRY_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<div className="flex flex-col gap-1 items-end">
            <div className="text-red-800 text-xs">{error}</div>
        </div>} highlight={searchParams.get("id") === props.registry.Id}>
        <>
            <div className="flex flex-col grow">
                <div className="text-md mt-2">
                    {props.registry.Name}
                </div>
            </div>
            <div className="flex">
                <AnimatedButton label="Edit" icon={Icons.Edit} onClick={() => toggleCardExpansion.current?.(true)} iconClassName="w-3 h-3" />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(navigate, props.registry)} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <AnimatedButton label="Delete" icon={Icons.Delete} labelClassName="text-red-800" iconClassName="text-red-600" onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const RegistryDashboardCard: FC<{ registry: IRegistry }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONTAINER_REGISTRY_ICON}>
        <RegistryIntro registry={props.registry} />
    </AdjustableCard>
}

export const transformRegistryData: IDataTransform<IRegistry>  = (data: { Registry: IRegistry[]}) => data.Registry;

export const RegistryGraphCard: FC<IGraphCardProps<IRegistry>> = (props) => {
    return <GraphCardLoader loader={getOneRegistryQuery} transform={data => transformRegistryData(data)[0]} {...props}>
        {(data: IRegistry) => (<RegistryDashboardCard registry={data} />)}
    </GraphCardLoader>;
}

export const AddRegistryDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Registry"
        query={getAllRegistriesQuery}
        transform={transformRegistryData} type={GraphElements.Registry}
        icon={Icons.Container.Registry.Default}
        link={InternalRoutes.Container.CreateRegistry.path} />
    );
}