import { useLazyQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { loader } from "graphql.macro";
import { FC, cloneElement, useCallback, useState } from "react";
import { ActionButton } from "../../components/button";
import { Input } from "../../components/input";
import { Loading } from "../../components/loading";
import { Icons } from "../../config/icons";
import { notify } from "../../store/function";
import classNames from "classnames";

const aiChatMutation = loader("./ai-chat.graphql");

type IAiBubbleProps = {
    placeholder: string;
    systemPrompt: string;
    onResponse: (text: string) => void;
}

export const AiBubble: FC<IAiBubbleProps> = ({ placeholder, systemPrompt, onResponse }) => {
    const [chat, { loading }] = useLazyQuery(aiChatMutation);
    const [show, setShow] = useState<boolean>(false);
    const [chatText, setChatText] = useState("");

    const handleToggle = useCallback(() => {
        setShow(s => !s);
    }, []);

    const handleSubmit = useCallback(() => {
        chat({
            variables: {
                prompt: chatText,
                systemPrompt,
            },
            onCompleted(data) {
                const responseChat = data?.AIChat;
                if (responseChat == null) {
                    return;
                }
                onResponse(responseChat);
                handleToggle?.();
                setChatText("");
            },
            onError() {
                notify("Co-Pilot had an issue. Please try again soon!");
            },
        });
    }, [chat, chatText, systemPrompt, onResponse, handleToggle]);

    return (
        <div className="relative">
            <div className="rounded-full cursor-pointer transition-all hover:shadow-lg" onClick={handleToggle}>
                {cloneElement(show ?  Icons.Cancel : Icons.Sparkles, {
                    className: classNames("w-6 h-6", {
                        "stroke-teal-500": !show,
                        "stroke-orange-500": show,
                    }),   
                })}
            </div>
            <motion.div className="hidden gap-2 items-center absolute z-10 -top-0 -left-[100px] border-gray-50"
                animate={show ? "open" : "close"}
                variants={{
                    open: {
                        opacity: 1,
                        y: -50,
                        scale: 1,
                        display: "flex",
                    },
                    close: {
                        opacity: 0,
                        y: -20,
                        scale: 0.5,
                        transitionEnd: {
                            display: "none",  
                        },
                    },
                }}>
                <Input placeholder={placeholder} value={chatText} setValue={setChatText} inputProps={{
                    className: "shadow-2xl w-[250px] h-fit border border-gray-300 min-h-[48px]",
                }} multiline={true} autoHeight={true} onSubmit={handleSubmit} />
                <div className="h-full w-fit">
                    {
                        loading
                        ? <Loading hideText={true} />
                        : <ActionButton icon={Icons.CheckCircle} className="stroke-green-500" onClick={handleSubmit} />
                    }
                </div>
            </motion.div>
        </div>
    )
}