import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { FC, useCallback, useRef, useState } from "react";
import { AnimatedButton } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { InternalRoutes } from "../../../config/internal-routes";
import { useSearchParams } from "react-router-dom";

const DOCKER_IO_REGISTRY_ICON = {
    component: Icons.Logos.DockerIO,
    bgClassName: "bg-white",
};

export type IDockerIOIntegrationsFormProps = {
    onCancel?: () => void;
    onComplete?: () => void;
    onError?: (error: string) => void;
}

const getAllDockerIORegistryQuery = loader("./get-all-docker-io-registry.graphql");
const getOneDockerIORegistryQuery = loader("./get-one-docker-io-registry.graphql");
const createDockerIOIntegrationsMutation = loader("./create-docker-io-integrations.graphql");

const getDefaultForm = (dockerIORegistry?: IDockerIORegistry): IFormVariable[] => {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: dockerIORegistry?.Name,
        },
        {
            name: "username",
            label: "Username",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Username is required",
            defaultValue: dockerIORegistry?.Username,
        },
        {
            name: "password",
            label: "Password",
            fieldType: "password",
            validate: (value: string) => value.length > 0,
            errorMessage: "Password is required",
            defaultValue: dockerIORegistry?.Password,
        },
    ];
};

export const CreateDockerIOIntegrationsForm: FC<IDockerIOIntegrationsFormProps> = (props) => {
    const [createRegistry] = useMutation(createDockerIOIntegrationsMutation);
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const status = isFormValid();
        if (!status.isValid) {
            return props.onError?.(status.errorMessage);
        }
        const form = getForm();
        createRegistry({
            variables: {
                name: form.name,
                username: form.username,
                password: form.password,
            },
            onCompleted: () => {
                notify("Docker IO registry integration created successfully", "success");
                props.onComplete?.();
            },
            onError: () => {
                notify("Unable to create docker IO registry integration", "error");
            }
        });
    }, [isFormValid, getForm, createRegistry, props]);

    const handleClose = useCallback(() => {
        props.onCancel?.();
    }, [props]);

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col gap-1 my-4 grow">
                <div className="text-lg my-2 flex gap-1 items-center"><span>{Icons.Logos.DockerIO}</span>Docker IO Integration</div>
                <Form variables={getDefaultForm()} {...formProps} />
            </div>
            <div className="flex items-center gap-2 justify-end">
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleClose} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
            </div>
        </div>
    )
}

const updateDockerIOIntegrationsMutation = loader("./update-docker-io-integrations.graphql");
const deleteDockerIOIntegrationsMutation = loader("./delete-docker-io-integrations.graphql");

export type IDockerIORegistry = {
    Id: string;
    Name: string;
    Username: string;
    Password: string;
}

type IDockerIOIntegrationsCardProps = {
    refetch: () => void;
    dockerIORegistry: IDockerIORegistry;
}

const DockerIORegistryIntro: FC<{ dockerIORegistry: IDockerIORegistry }> = (props) => {
    return <div className="flex flex-col grow">
        <div className="text-md mt-2">
            {props.dockerIORegistry.Name}
        </div>
    </div>
}

export const DockerIOIntegrationsCard: FC<IDockerIOIntegrationsCardProps> = (props) => {
    const [updateDockerIORegistry] = useMutation(updateDockerIOIntegrationsMutation);
    const [deleteDockerIORegistry] = useMutation(deleteDockerIOIntegrationsMutation);
    const toggleCardExpansion = useRef<Function>();
    const [error, setError] = useState("");
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const [searchParams, ] = useSearchParams();

    const handleSubmit = useCallback(() => {
        const status = isFormValid();
        if (!status.isValid) {
            return setError(status.errorMessage);
        }
        const form = getForm();
        updateDockerIORegistry({
            variables: {
                id: props.dockerIORegistry.Id,
                name: form.name,
                username: form.username,
                password: form.password,
            },
            onCompleted: () => {
                notify("Docker IO registry integration updated successfully", "success");
            },
            onError: () => {
                notify("Unable to updated Docker IO registry integration", "error");
            }
        });
    }, [isFormValid, getForm, updateDockerIORegistry, props.dockerIORegistry.Id]);

    const handleDelete = useCallback(() => {
        deleteDockerIORegistry({
            variables: {
                id: props.dockerIORegistry.Id,
            },
            onCompleted: () => {
                notify("Docker IO registry integration deleted successfully!", "success");
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete Docker IO registry integration", "error");
            },
        });
    }, [props, deleteDockerIORegistry]);

    const handleOpen = useCallback(() => {
        toggleCardExpansion.current?.(true);
    }, [toggleCardExpansion]);

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    return (
        <GraphBasedExpandableCard id={props.dockerIORegistry.Id} type={GraphElements.DockerIORegistryIntegration} icon={DOCKER_IO_REGISTRY_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
            tag={<div className="flex flex-col gap-1 items-end">
                <div className="text-red-800 text-xs">{error}</div>
            </div>} highlight={searchParams.get("id") === props.dockerIORegistry.Id}>
            <>
                <DockerIORegistryIntro dockerIORegistry={props.dockerIORegistry} />
                <div className="flex">
                    <AnimatedButton label="Edit" icon={Icons.Edit} onClick={handleOpen} iconClassName="w-3 h-3" />
                </div>
            </>
            <>
                <div className="flex flex-col gap-1 my-4 grow">
                    <div className="text-lg my-2 flex gap-1 items-center"><span>{Icons.Logos.DockerIO}</span>Docker IO Integration</div>
                    <Form variables={getDefaultForm(props.dockerIORegistry)} {...formProps} />
                </div>
                <div className="flex justify-between items-center">
                    <AnimatedButton label="Delete" icon={Icons.Delete} labelClassName="text-red-800" iconClassName="text-red-600" onClick={handleDelete} />
                    <div className="flex items-center gap-2">
                        <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                        <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                    </div>
                </div>
            </>
        </GraphBasedExpandableCard>
    )
}

export const DockerIORegistryDashboardCard: FC<{ dockerIORegistry: IDockerIORegistry }> = (props) => {
    return <AdjustableCard showTools={true} icon={DOCKER_IO_REGISTRY_ICON}>
        <DockerIORegistryIntro dockerIORegistry={props.dockerIORegistry} />
    </AdjustableCard>
}

export const transformDockerIORegistryData: IDataTransform<IDockerIORegistry>  = (data: { DockerIORegistryIntegration: IDockerIORegistry[]}) => data.DockerIORegistryIntegration;

export const DockerIORegistryGraphCard: FC<IGraphCardProps<IDockerIORegistry>> = (props) => {
    return <GraphCardLoader loader={getOneDockerIORegistryQuery} transform={(data) => transformDockerIORegistryData(data)[0]} {...props}>
        {(data: IDockerIORegistry) => (<DockerIORegistryDashboardCard dockerIORegistry={data} />)}
    </GraphCardLoader>;
}

export const AddDockerIORegistryDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Docker IO Registry"
        query={getAllDockerIORegistryQuery}
        transform={transformDockerIORegistryData} type={GraphElements.DockerIORegistryIntegration}
        icon={Icons.Logos.DockerIO}
        link={InternalRoutes.Integrations.CreateIntegrations.path} />
    );
}