import { map } from "lodash";
import { FC, useContext, useEffect, useMemo } from "react";
import { getIncomers, useEdges, useNodes, useReactFlow } from "reactflow";
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form";
import { IGraphCardProps } from "../../../../components/graph/graph";
import { Icons } from "../../../../config/icons";
import { getGitHubRepositoryBranchesQuery } from "../../../integrations/github-integrations/github-integrations-card";
import { FlowGraphViewContext } from "../context";
import { FlowElements, FlowStepCard } from "../flow-step-card";
import { ICreateGitPullFlowStep } from "./git-pull-flow-step-card";

const getDefaultForm = (cache?: ICreateGitPullFlowStep, gitBranch?: IGitChangeBranch): IFormVariable[] => {
    return [
        {
            name: "branch",
            label: "Repository branch",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Repository branch is required",
            query: getGitHubRepositoryBranchesQuery,
            transform(data) {
                return map(data?.GitHubRepositoryBranch ?? [], ({ Name }) => ({
                    id: Name,
                    label: Name,
                }));
            },
            defaultIcon: Icons.Cog,
            dropdownProps: {
                noItemsLabel: "No branches found",
            },
            isLazy: true,
            shouldQuery() {
                if (cache?.GitPull?.IntegrationId == null || cache?.GitPull?.Url == null) {
                    return [false, {}];
                }
                return [true, {
                    id: cache.GitPull.IntegrationId,
                    url: cache.GitPull.Url,
                }]
            },
            defaultValue: gitBranch?.BranchName,
        }
    ];
}

type IGitChangeBranch = {
    BranchName: string;
}

export type ICreateGitChangeBranchFlowStep = {
    Type: FlowElements.GitChangeBranch;
    GitChangeBranch: IGitChangeBranch;
}

export const GitChangeBranchFlowStepCard: FC<IGraphCardProps<IGitChangeBranch>> = (props) => {
    const { cache, setCacheItem } = useContext(FlowGraphViewContext);
    const { getNode } = useReactFlow();
    const nodes = useNodes();
    const edges = useEdges();

    const referencesGitPullCache = useMemo(() => {
        const node = getNode(props.id);
        if (node == null) {
            return;
        }
        const references = getIncomers(node, nodes, edges);
        for (const reference of references) {
            if (reference.type === FlowElements.GitPull) {
                return (cache[reference.id]?.getForm() ?? {}) as ICreateGitPullFlowStep;
            }
        }
        return;
    }, [nodes, edges, props, getNode, cache]);

    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitChangeBranchFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <FlowStepCard label="Git change branch" {...props}>
            <div className="flex flex-col gap-1 grow">
                <Form variables={getDefaultForm(referencesGitPullCache, props.data)} {...formProps} />
            </div>
        </FlowStepCard>
    )
}

const convertGitChangeBranchFormToStep = (form: IForm): ICreateGitChangeBranchFlowStep => {
    return {
        Type: FlowElements.GitChangeBranch,
        GitChangeBranch: {
            BranchName: form.branch as string,
        }
    }
}