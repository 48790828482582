import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { loader } from "graphql.macro";
import { ChangeEventHandler, FC, cloneElement, useCallback, useMemo, useRef, useState } from "react";
import { useAppQuery } from "../../api/query";
import { Icons } from "../../config/icons";
import { InternalRoutes } from "../../config/internal-routes";
import { AuthActions, UserRole } from "../../store/auth";
import { notify } from "../../store/function";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createStub, toTitleCase } from "../../utils/functions";
import { Loading } from "../loading";
import { SideMenu } from "../side-menu";
import { CommonActions } from "../../store/common";

const getMyUserQuery = loader("./my-user.graphql");
const updatePhotoMutation = loader("./update-photo.graphql");

export const sidebarRoutes = [
    {
        title: "Admin",
        icon: Icons.WrenchScrewdriver,
        routes: [
            {
                name: "Add user",
                path: InternalRoutes.Admin.CreateUser.path,
                icon: Icons.Admin.User.Default,
            },
            {
                name: "Users",
                path: InternalRoutes.Admin.User.path,
                icon: Icons.Admin.User.Default,
            },
            // {
            //     name: "Create team",
            //     path: InternalRoutes.Team.Team.path,
            //     icon: Icons.Dev.Repository.Default,
            // },
            // {
            //     name: "Teams",
            //     path: InternalRoutes.Team.CreateTeam.path,
            //     icon: Icons.Dev.Repository.Default,
            // },
        ],
    },
    // {
    //     title: "Dev",
    //     icon: Icons.CodeBracket,
    //     routes: [
    //         {
    //             name: "Create repository",
    //             path: InternalRoutes.Dev.Repository.path,
    //             icon: Icons.Dev.Repository.Default,
    //         },
    //         {
    //             name: "Repository",
    //             path: InternalRoutes.Dev.Repository.path,
    //             icon: Icons.Dev.Repository.Default,
    //         },
    //     ]
    // },
    {
        title: "Images",
        icon: Icons.SquareStack,
        routes: [
            {
                name: "Create registry",
                path: InternalRoutes.Container.CreateRegistry.path,
                icon: Icons.Container.Registry.Default,
            },
            {
                name: "Registry",
                path: InternalRoutes.Container.Registry.path,
                icon: Icons.Container.Registry.Default,
            },
            {
                name: "Create image",
                path: InternalRoutes.Container.CreateImage.path,
                icon: Icons.Container.Image.Default,
            },
            {
                name: "Registry Image",
                path: InternalRoutes.Container.Image.path,
                icon: Icons.Container.Image.Default,
            },
        ],
    },
    {
        title: "CI/CD",
        icon: Icons.SquareGrid,
        routes: [
            {
                name: "Flows",
                path: InternalRoutes.CI_CD.Flow.path,
                icon: Icons.CI_CD.Flow.Default,
            },
            {
                name: "Create flow",
                path: InternalRoutes.CI_CD.CreateFlow.path,
                icon: Icons.CI_CD.Flow.Default,
            },
            {
                name: "Hooks",
                path: InternalRoutes.CI_CD.Hooks.path,
                icon: Icons.CI_CD.Hooks.Default,
            },
            {
                name: "Create hooks",
                path: InternalRoutes.CI_CD.CreateHook.path,
                icon: Icons.CI_CD.Hooks.Default,
            },
        ],
    },
    {
        title: "Config",
        icon: Icons.AdjustmentHorizontal,
        routes: [
            {
                name: "Domain",
                path: InternalRoutes.Config.Domain.path,
                icon: Icons.Config.Domain.Default,
            },
            {
                name: "Create domain",
                path: InternalRoutes.Config.CreatDomain.path,
                icon: Icons.Config.Domain.Default,
            },
            {
                name: "Environment variable",
                path: InternalRoutes.Config.EnvironmentVariable.path,
                icon: Icons.Config.EnvironmentVariable.Default,
            },
            {
                name: "Create environment variable",
                path: InternalRoutes.Config.CreatEnvironmentVariable.path,
                icon: Icons.Config.EnvironmentVariable.Default,
            },
        ]
    },
    {
        title: "Deploy",
        icon: Icons.Window,
        routes: [
            {
                name: "Create quick container",
                path: InternalRoutes.Deployment.CreatQuickContainer.path,
                icon: Icons.Deploy.QuickContainer.Default,
            },
            {
                name: "Quick container",
                path: InternalRoutes.Deployment.QuickContainer.path,
                icon: Icons.Deploy.QuickContainer.Default,
            },
            {
                name: "Create cluster",
                path: InternalRoutes.Deployment.CreatCluster.path,
                icon: Icons.Deploy.Cluster.Default,
            },
            {
                name: "Cluster",
                path: InternalRoutes.Deployment.Cluster.path,
                icon: Icons.Deploy.Cluster.Default,
            },
        ],
    },
    {
        title: "Integrations",
        icon: Icons.Integrations,
        routes: [
            {
                name: "Create integration",
                path: InternalRoutes.Integrations.CreateIntegrations.path,
                icon: Icons.Integrations,
            },
            {
                name: "Integrations",
                path: InternalRoutes.Integrations.Integrations.path,
                icon: Icons.Integrations,
            },
        ],
    },
]

function blobToBase64(blob: Blob) {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}

function getResizedImage(base64Str: string, maxWidth = 200, maxHeight = 200) {
    return new Promise<string>((resolve) => {
        const img = new Image();
        img.src = base64Str;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let width = img.width;
            let height = img.height;
    
            if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
            } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            if (ctx == null) return resolve("");
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
        }
    });
}

const UpdateButton: FC<{ updating: boolean }> = ({ updating }) => {
    if (updating) {
        return <div className="w-10 h-10">
            <Loading className="fill-teal-500" hideText={true} />
        </div>;
    }
    return cloneElement(Icons.Plus, {
            className: "w-10 h-10 duration-300 stroke-white transition-all -rotate-12 opacity-0 scale-90 group-hover/profile:rotate-0 group-hover/profile:opacity-100 group-hover/profile:scale-100",
    });
}

export const Sidebar: FC = () => {
    const ref = useRef<HTMLInputElement>(null);
    const [collapsed, setCollapsed] = useState(false);
    const [updatePhoto, { loading: updatingPhoto }] = useMutation(updatePhotoMutation);
    const roles = useAppSelector(state => state.auth.user?.Roles ?? []);
    const dispatch = useAppDispatch();
    const { loading, data, refetch } = useAppQuery(getMyUserQuery, {
        onCompleted(data) {
            dispatch(AuthActions.setUser(data.MyUser));
        },
    });

    const handleCollapseToggle = useCallback(() => {
        dispatch(CommonActions.setSideMenuCollapsed(!collapsed));
        setCollapsed(!collapsed);
    }, [collapsed, dispatch]);

    const handleProfileChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        if (e.target == null) return;
        if (e.target.files != null) {
        blobToBase64(e.target.files[0])
            .then(base64 => getResizedImage(base64))
            .then((image) => {
                updatePhoto({
                    variables: {
                        image,
                    },
                    onCompleted() {
                        refetch();
                    },
                    onError() {
                        notify("Error updating photo", "error");
                    },
                });
            });
        }
    }, [refetch, updatePhoto]);

    const handleUpload = useCallback(() => {
        ref.current?.click();
    }, [ref]);

    const routes = useMemo(() => {
        const isAdmin = roles.includes(UserRole.AdminRole);
        return sidebarRoutes.filter(route => {
            if (isAdmin) return true;
            return route.title !== "Admin";
        }).map(route => (
            <SideMenu key={`sidebar-routes-${createStub(route.title)}`} collapse={collapsed} title={route.title} icon={route.icon} routes={route.routes} />
        ));
    }, [collapsed, roles]);

    const animate = collapsed ? "hide" : "show";

    return (
        <div className={
            classNames("h-[100vh] flex flex-col gap-4 shadow-md relative transition-all duration-500", {
                "w-[50px] py-20": collapsed,
                "w-[350px] p-20": !collapsed,
            })}>
            <motion.div className={classNames("absolute top-4 cursor-pointer transition-all", {
                "right-2 hover:right-3": !collapsed,
                "right-3 hover:right-2": collapsed,
            })} initial="show" variants={{
                show: {
                    rotate: "180deg",
                },
                hide: {
                    rotate: "0deg",
                }
            }} animate={animate} onClick={handleCollapseToggle} transition={{
                duration: 0.1,
            }}>
                {Icons.DoubleRightArrow}
            </motion.div>
            <motion.div className="flex flex-col gap-4" variants={{
                show: {
                    opacity: 1,
                    transition: {
                        delay: 0.3,
                    }
                },
                hide: {
                    opacity: 0,
                    transition: {
                        duration: 0.1,
                    }
                }
            }} animate={animate}>
                {
                    loading || data == null
                        ? <>
                            <div className="h-[150px] w-[150px] bg-gray-200 rounded-full animate-pulse" />
                            <div className="h-2 w-[150px] rounded-full bg-gray-200 animate-pulse" />
                            <div className="h-2 w-[100px] rounded-full bg-gray-200 animate-pulse" />
                        </>
                        : <>
                            {
                                data.MyUser.Image != null
                                ? <div className="relative cursor-pointer group/profile w-fit" onClick={handleUpload}>
                                    <img className="h-[150px] w-[150px] bg-gray-800 rounded-full" src={data.MyUser.Image} alt="profile" />
                                    <div className="absolute inset-0 transition-all hover:bg-black/60 flex justify-center items-center rounded-full">
                                        <UpdateButton updating={updatingPhoto} />
                                    </div>
                                </div>
                                : <div className="h-[150px] w-[150px] bg-gray-800 rounded-full flex justify-center items-center relative cursor-pointer group/profile" onClick={handleUpload}>
                                    {cloneElement(Icons.User, {
                                        className: "w-20 h-20 stroke-white",
                                    })}
                                    <div className="absolute ml-20 -mt-10">
                                        <UpdateButton updating={updatingPhoto} />
                                    </div>
                                </div>
                            }
                            <input type="file" name="image-upload" accept="image/png,image/jpg,image/jpeg" hidden={true} ref={ref} onChange={handleProfileChange} />
                            <div className={"text-3xl text-gray-900 font-extrabold"}>
                                {toTitleCase(data.MyUser.FirstName)} {toTitleCase(data.MyUser.LastName)}
                            </div>
                        </>
                }
            </motion.div>
            <div className="flex flex-col justify-center mt-12 grow max-w-[150px]">
                <AnimatePresence mode="wait">
                    {routes}
                    <div className="grow" />
                    <SideMenu collapse={collapsed} title="Logout" icon={Icons.Logout} defaultPath={InternalRoutes.Logout.path} />
                </AnimatePresence>
            </div>
        </div>
    )
}