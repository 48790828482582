import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { cloneDeep, every } from "lodash";
import { FC, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Updater } from "use-immer";
import { ActionButton, AnimatedButton } from "../../../components/button";
import { AdjustableCard, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Input, Label } from "../../../components/input";
import { Pills } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";

export const CONFIG_ENVIRONMENT_VARIABLE_ICON = {
    component: Icons.Config.EnvironmentVariable.Default,
    bgClassName: "bg-gray-500",
}

export const getAllEnvironmentVariablesQuery = loader("./get-environment-variables.graphql");
const getOneEnvironmentVariableQuery = loader("./get-one-environment-variable.graphql");
export const createEnvironmentVariableMutation = loader("./create-environment-variable.graphql");
const updateEnvironmentVariableMutation = loader("./update-environment-variable.graphql");
const deleteEnvironmentVariableMutation = loader("./delete-environment-variable.graphql");

const DEFAULT_ENVIRONMENT_VARIABLE = {
    Key: "",
    Value: "",
};

const EnvironmentVariablesField: FC<{ value: IEnvironmentVariableItem[], setValue: Updater<IEnvironmentVariableItem[]> }> = ({ value, setValue }) => {
    const handleAdd = useCallback(() => {
        setValue([...value, cloneDeep(DEFAULT_ENVIRONMENT_VARIABLE)]);
    }, [value, setValue]);

    const handleChange = useCallback((index: number, text: string, type: keyof IEnvironmentVariableItem) => {
        const newValue = cloneDeep(value);
        newValue[index][type] = text;
        setValue(newValue);
    }, [value, setValue]);

    const handleRemove = useCallback((index: number) => {
        setValue(value.filter((_, i) => i !== index));
    }, [value, setValue]);
    
    const disabled = value.length === 1;

    return <div className="flex flex-col mt-2">
        <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden h-[180px]">
            <div className="flex items-center justify-between mt-1">
                <Label label="Variables" />
                <ActionButton icon={Icons.Add} containerClassName="shadow-sm border-0 m-0 h-8 w-8" className="stroke-green-500" onClick={handleAdd} />
            </div>
            {
                value.map(({ Key, Value }, index) => (
                    <div className="flex flex-row gap-1 justify-between">
                        <Input value={Key} setValue={(val) => handleChange(index, val, "Key")} placeholder="Enter key" />
                        <Input value={Value} setValue={(val) => handleChange(index, val, "Value")} placeholder="Enter value" />
                        <ActionButton icon={Icons.Delete} containerClassName="shadow-sm border-0 h-8 w-8 m-0 self-center" className={classNames("transition-all", {
                            "stroke-red-500": !disabled,
                            "stroke-red-200": disabled,
                         })} onClick={() => handleRemove(index)} disabled={disabled} />
                    </div>
                ))
            }
        </div>
    </div>
}

const pattern: RegExp = /^[a-zA-Z_][a-zA-Z0-9_.-]*$/;
function isValidEnvKey(key: string): boolean {
    return pattern.test(key);
}

function getDefaultForm(environmentVariable?: IEnvironmentVariable): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: environmentVariable?.Name,
        },
        {
            name: "variables",
            label: "Variabes",
            fieldType: "custom",
            validate: (value: IEnvironmentVariableItem[]) => value.length > 0 && every(value, envVar => isValidEnvKey(envVar.Key)),
            errorMessage: (value: IEnvironmentVariableItem[]) => {
                if (value.length === 0) {
                    return "At least one variable is required"
                }
                for (const envVar of value) {
                    if (!isValidEnvKey(envVar.Key)) {
                        return `Invalid key ${envVar.Key}`;
                    }
                }
                return "";
            },
            defaultValue: environmentVariable?.Variables ?? [
                DEFAULT_ENVIRONMENT_VARIABLE,
            ],
            onRender(value: IEnvironmentVariableItem[], setValue: Updater<IEnvironmentVariableItem[]>) {
                return <EnvironmentVariablesField value={value} setValue={setValue} />
            },
        },
    ]
}

type ICreateEnvironmentVariableCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateEnvironmentVariableCard: FC<ICreateEnvironmentVariableCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createEnvironmentVariable] = useMutation(createEnvironmentVariableMutation);
    const ref = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createEnvironmentVariable({
            variables: {
                name: form.name,
                variables: form.variables,
            },
            onCompleted: () => {
                notify("Environment variable created successfully", "success");
                ref.current?.();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create environmentVariable", "error");
            }
        });
    }, [isFormValid, getForm, createEnvironmentVariable, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Environment variable" icon={{
                component: Icons.Add,
                bgClassName: CONFIG_ENVIRONMENT_VARIABLE_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<div className="flex flex-col gap-1 items-end">
                    <div className="text-red-800 text-xs">{error}</div>
                </div>}>
                <div className="flex flex-col gap-1 my-4 grow">
                    <Form variables={getDefaultForm()} {...formProps} />
                </div>
                <div className="flex items-center gap-2">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleClose} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

type IEnvironmentVariableItem = {
    Key: string;
    Value: string;
}

export type IEnvironmentVariable = {
    Id: string;
    Name: string;
    Variables: IEnvironmentVariableItem[];
}


export const EnvironmentVariableIntro: FC<{ environmentVariable: IEnvironmentVariable }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className="text-md">
            {props.environmentVariable.Name}
        </div>
        <div className="text-xs mt-2 flex flex-wrap gap-1">
            <Pills pills={props.environmentVariable.Variables.map(({ Key: key }) => ({
                id: key,
                label: key,
              }))} pillsCount={2} />
        </div>
    </div>
}

type IEnvironmentVariableCardProps = {
    environmentVariable: IEnvironmentVariable;
    refetch: () => void;
}

export const EnvironmentVariableCard: FC<IEnvironmentVariableCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteEnvironmentVariable,] = useMutation(deleteEnvironmentVariableMutation);
    const [updateEnvironmentVariable,] = useMutation(updateEnvironmentVariableMutation);
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateEnvironmentVariable({
            variables: {
                id: props.environmentVariable.Id,
                name: form.name,
                variables: form.variables,
            },
            onCompleted: () => {
                notify("Environment variable updated successfully", "success");
                props.refetch();
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update environmentVariable", "error");
            }
        });
    }, [isFormValid, getForm, updateEnvironmentVariable, props]);

    const handleDelete = useCallback(() => {
        deleteEnvironmentVariable({
            variables: {
                id: props.environmentVariable.Id,
            },
            onCompleted: () => {
                notify("Environment variable deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete environment variable", "error");
            },
        });
    }, [props, deleteEnvironmentVariable]);


    return <GraphBasedExpandableCard id={props.environmentVariable.Id} type={GraphElements.EnvironmentVariable} icon={CONFIG_ENVIRONMENT_VARIABLE_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<div className="flex flex-col gap-1 items-end">
            <div className="text-red-800 text-xs">{error}</div>
        </div>} highlight={searchParams.get("id") === props.environmentVariable.Id}>
        <>
            <div className="flex flex-col grow">
                <div className="text-md mt-2">
                    {props.environmentVariable.Name}
                </div>
            </div>
            <div className="flex">
                <AnimatedButton label="Edit" icon={Icons.Edit} onClick={() => toggleCardExpansion.current?.(true)} iconClassName="w-3 h-3" />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(props.environmentVariable)} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <AnimatedButton label="Delete" icon={Icons.Delete} labelClassName="text-red-800" iconClassName="text-red-600" onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const EnvironmentVariableDashboardCard: FC<{ environmentVariable: IEnvironmentVariable }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONFIG_ENVIRONMENT_VARIABLE_ICON}>
        <EnvironmentVariableIntro environmentVariable={props.environmentVariable} />
    </AdjustableCard>
}

export const transformEnvironmentVariableData: IDataTransform<IEnvironmentVariable>  = (data?: { EnvironmentVariable: IEnvironmentVariable[]}) => data?.EnvironmentVariable ?? [];

export const EnvironmentVariableGraphCard: FC<IGraphCardProps<IEnvironmentVariable>> = (props) => {
    return <GraphCardLoader loader={getOneEnvironmentVariableQuery} transform={data => transformEnvironmentVariableData(data)[0]} {...props}>
        {(data: IEnvironmentVariable) => (<EnvironmentVariableDashboardCard environmentVariable={data} />)}
    </GraphCardLoader>;
}

export const AddEnvironmentVariableDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Environment variable"
        query={getAllEnvironmentVariablesQuery}
        transform={transformEnvironmentVariableData} type={GraphElements.EnvironmentVariable}
        icon={Icons.Config.EnvironmentVariable.Default}
        link={InternalRoutes.Config.CreatEnvironmentVariable.path} />
    );
}