import { isArray, isString } from "lodash";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { IRegistryImage, createRegistryImageMutation, getAllRegistryImagesQuery, transformRegistryImageData } from "../../../page/container/registry-image/registry-image-card";
import { RegistryType, createRegistryMutation, getAllRegistriesQuery, transformRegistryData } from "../../../page/container/registry/registry-card";
import { getAllQuickContainersQuery } from "../../../page/deploy/quick-container/quick-container";
import { createQuickContainerMutation, transformQuickContainersData } from "../../../page/deploy/quick-container/quick-container-card";
import { createRedirectLink, isNumeric, toTitleCase } from "../../../utils/functions";
import { Command, ExecutionResult, ExecutionResultStatus } from "./command";
import { createEnvironmentVariableMutation } from "../../../page/config/environment-variable/environment-variable-card";

const MAIN_COMMAND = "docker";
const ALLOWED_SUB_COMMANDS = ["run", "pull"];

enum DockerSteps {
    RegistryStep="RegistryStep",
    RegistryImageStep="RegistryImageStep",
    EnvironmentVariableStep="EnvironmentVariableStep",
    QuickContainerStep="QuickContainerStep",
}

function getRegistryStep(label: string, status: ExecutionResultStatus, link?: string) {
    return {
        id: DockerSteps.RegistryStep,
        icon: Icons.Container.Registry.Default,
        label,
        status,
        link,
    }
}

function getRegistryImageStep(label: string, status: ExecutionResultStatus, link?: string) {
    return {
        id: DockerSteps.RegistryImageStep,
        icon: Icons.Container.Image.Default,
        label,
        status,
        link,
    }
}

function getEnvironmentVaraibleStep(label: string, status: ExecutionResultStatus, link?: string, tooltip?: string) {
    return {
        id: DockerSteps.EnvironmentVariableStep,
        icon: Icons.Config.EnvironmentVariable.Default,
        label,
        status,
        link,
        tooltip,
    }
}

function getQuickContainerStep(label: string, status: ExecutionResultStatus, link?: string, tooltip?: string) {
    return {
        id: DockerSteps.QuickContainerStep,
        icon: Icons.Deploy.QuickContainer.Default,
        label,
        status,
        link,
        tooltip,
    }
}

export class DockerCommand extends Command {
    isValid(mainCommand: string) {
        return MAIN_COMMAND === mainCommand;
    }

    isValidSubCommand(subCommand: string) {
        return ALLOWED_SUB_COMMANDS.includes(subCommand);
    }

    hasValidParameters(parameters: string[]) {
        if (parameters.length !== 1) {
            return false;
        }
        const imageSplit = parameters[0].split("/");
        if (imageSplit.length !== 2) {
            return false;
        }
        return imageSplit.every(image => image.length > 0);
    }

    async *execute(subCommand: string, parameters: string[], options: Record<string, string | string[]>): AsyncIterable<ExecutionResult> {
        const [namespace, imageWithTag] = parameters[0].split("/");
        const [image, tag] = imageWithTag.split(":");
        yield getRegistryStep("Searching existing registry", "pending");
        const { data, error } = await this.query(getAllRegistriesQuery);
        if (error != null) {
            return yield getRegistryStep("Searching registry failed", "failed");
        }
        let registry = transformRegistryData(data).find(registry => registry.DockerIO?.Username === namespace || registry.Namespace === namespace);
        let registryImages: IRegistryImage[] = [];
        let registryImage: IRegistryImage | undefined;
        if (registry == null) {
            yield getRegistryStep(`Creating new ${namespace} registry`, "pending");
            const { data: createdRegistry, error: registryError } = await this.mutate(createRegistryMutation, {
                name: `${toTitleCase(namespace)} registry`,
                type: "PublicDockerIO" as RegistryType,
                namespace,
            });
            if (registryError != null) {
                return yield getRegistryStep("Creating registry failed", "failed");
            }
            registry = createdRegistry.CreateRegistry;
            const registryId = registry!.Id;
            yield getRegistryStep("Registry is ready", "done", createRedirectLink(InternalRoutes.Container.Registry.path, registryId));
            yield getRegistryImageStep(`Creating registry image ${image}`, "pending");
            const { data: registryImageData, error: registryImageError } = await this.mutate(createRegistryImageMutation, {
                name: `${toTitleCase(image)}`,
                image,
                registryId,
            });
            if (registryImageError != null) {
                return yield getRegistryImageStep("Creating registry image failed", "failed");
            }
            registryImage = registryImageData.CreateRegistryImage
            const registryImageId = registryImage!.Id;
            yield getRegistryImageStep("Registry image is ready", "done", createRedirectLink(InternalRoutes.Container.Image.path, registryImageId));
        } else {
            yield getRegistryStep("Registry already exists", "done", createRedirectLink(InternalRoutes.Container.Registry.path, registry.Id));
            yield getRegistryImageStep("Searching registry image", "pending");
            const { data, error } = await this.query(getAllRegistryImagesQuery);
            if (error != null) {
                return yield getRegistryImageStep("Searching registry image failed", "failed");
            }

            registryImages = transformRegistryImageData(data);
            registryImage = registryImages.find(registryImage => registryImage.Image === image && registryImage.Registry.Id === registry!.Id);
            if (registryImage == null) {
                yield getRegistryImageStep(`Creating registry image ${image}`, "pending");
                const { data: registryImageData, error: registryImageError } = await this.mutate(createRegistryImageMutation, {
                    name: `${toTitleCase(image)}`,
                    image,
                    registryId: registry.Id,
                });
                if (registryImageError != null) {
                    return yield getRegistryImageStep("Creating registry image failed", "failed");
                }
                registryImage = registryImageData.CreateRegistryImage;
                const registryImageId = registryImage!.Id;
                yield getRegistryImageStep("Registry image is ready", "done", createRedirectLink(InternalRoutes.Container.Image.path, registryImageId));
            } else {
                yield getRegistryImageStep("Registry image already exists", "done", createRedirectLink(InternalRoutes.Container.Image.path, registryImage.Id));
            }
        }
        
        if (subCommand === "run") {
            let environmentVariables = options.e ?? [];
            if (!isArray(environmentVariables)) {
                environmentVariables = [environmentVariables];
            }

            const variables: { Key: string, Value: string}[] = [];
            
            for (const environmentVariable of environmentVariables) {
                const [Key, Value] = environmentVariable.split("=");
                if (!isString(Key) || !isString(Value) || Key.length === 0 || Value.length === 0) {
                    continue
                }
                variables.push({ Key, Value });
            }
            
            let environmentVariableId: string | undefined;
            if (variables.length > 0) {
                yield getEnvironmentVaraibleStep("Creating environment variables", "pending");
                const { data: environmentVariableData, error: environmentVariableError } = await this.mutate(createEnvironmentVariableMutation, {
                    name: `${toTitleCase(image)} App`,
                    variables,
                });
                if (environmentVariableError != null) {
                    return yield getEnvironmentVaraibleStep("Creating environment variables failed", "failed");
                }
                environmentVariableId = environmentVariableData.CreateEnvironmentVariable.Id;
                yield getEnvironmentVaraibleStep("Environment variables are ready", "done");
            }

            const ports: number[] = [];
            if (!("p" in options)) {
                return yield getQuickContainerStep("No port provided for quick container", "missing-info", undefined, "Re-run with a port provided");
            }
            if (!isArray(options.p)) {
                options.p = [options.p];                
            }

            for (const port of options.p) {
                const containerPort = port.split(":")[1]?.split("/")?.[0];
                if (!isNumeric(containerPort)) {
                    return yield getQuickContainerStep(`Incorrect port defined: ${containerPort}`, "missing-info", undefined, "Re-run with the correct port");
                }
                ports.push(parseInt(containerPort));
            }
            
            yield getQuickContainerStep("Searching for quick containers", "pending");
            
            const { data, error } = await this.query(getAllQuickContainersQuery);
            if (error != null) {
                return yield getQuickContainerStep("Searching quick container failed", "failed");
            }
            const quickContainer = transformQuickContainersData(data).find(qc => {
                const registryImageId = qc.Containers[0].RegistryImageId;
                const foundRegistryImage = registryImages.find(image => image.Id === registryImageId);
                if (foundRegistryImage == null) {
                    return false;
                }
                return foundRegistryImage.Image === image && (foundRegistryImage.Registry.Namespace === namespace || foundRegistryImage.Registry.DockerIO?.Username === namespace);
            });

            if (quickContainer == null) {
                yield getQuickContainerStep(`Creating quick container`, "pending");
                const { data: quickContainerData, error: quickContainerError } = await this.mutate(createQuickContainerMutation, {
                    name: `${toTitleCase(image)} App`,
                    containers: [
                        {
                            RegistryImageId: registryImage!.Id,
                            Tag: tag ?? "latest",
                            Ports: ports,
                            Resource: {
                                LimitsMemory: "100",
                                LimitsCPU: "100",
                                RequestsMemory: "100",
                                RequestsCPU: "100",
                            },
                            Volumes: [],
                            EnvironmentVariableId: environmentVariableId,
                        },
                    ],
                    onlyCreate: false,
                });
                if (quickContainerError != null) {
                    return yield getQuickContainerStep("Creating quick container failed", "failed");
                }
                const quickContainerId = quickContainerData.CreateQuickContainer.Id;
                yield getQuickContainerStep("Quick container is ready", "done", createRedirectLink(InternalRoutes.Container.Image.path, quickContainerId));

                
            } else {
                return yield getQuickContainerStep("Quick container already exists", "done", createRedirectLink(InternalRoutes.Deployment.QuickContainer.path, quickContainer!.Id));
            }
        }
    }
}