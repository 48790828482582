import { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { useReactFlow } from "reactflow"
import { CodeEditor, ICodeEditorSuggestion } from "../../../../components/editor"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Label } from "../../../../components/input"
import { Tooltip } from "../../../../components/tooltip"
import { Icons } from "../../../../config/icons"
import { getAllReferencesRecursively, getExposedVariableSuggestions } from "../../../../utils/functions"
import { HookGraphViewContext } from "../context"
import { HooksElements, HooksStepCard } from "../hooks-step-card"
import { FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES } from "./flow-operation-hook-step-card"

type IHandlebarCodeEditor = {
    value: string;
    setValue: (value: string) => void;
    suggestions: ICodeEditorSuggestion[];
}

const HandlebarCodeEditor: FC<IHandlebarCodeEditor> = ({ value, setValue, suggestions }) => {
    const onSuggestionHover = useCallback((suggestion: ICodeEditorSuggestion, enter: boolean) => {
        const nodeId = suggestion.label.split(".")[1];
        const node = document.querySelector(`div[data-id="${nodeId}"]`)?.querySelector(".group\\/hook-step-selector");
        if (node == null) {
            return;
        }
        if (enter) {
            node?.classList.remove("shadow-sm");
            node?.classList.add("shadow-2xl");
        } else {
            node?.classList.remove("shadow-2xl");
            node?.classList.add("shadow-sm");
        }
    }, []);

    return <div className="flex flex-col grow gap-2">
        <div className="flex justify-between items-center">
            <Label label="Message" />
            <Tooltip tooltip="You can use handlebars to get variable information from previous steps to put in the message. Markdown is supported.">
                {Icons.Info}
            </Tooltip>
        </div>
        <CodeEditor value={value} setValue={setValue} language="hooks-handlebars" suggestions={suggestions} onSuggestionHover={onSuggestionHover} />
    </div>;
}

function getDefaultForm(suggestions: ICodeEditorSuggestion[], gitPullRequestComment?: IGitPullRequestCommentHook): IFormVariable[] {
    return [
        {
            name: "message",
            label: "Message",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Message is required",
            defaultValue: gitPullRequestComment?.Message,
            onRender: (value: string, setValue: (content: string) => void) => {
                return <HandlebarCodeEditor value={value} setValue={setValue} suggestions={suggestions} />
            },
        },
    ]
}

type IGitPullRequestCommentHook = {
    Type: "GitHub";
    Message: string;
};

export type IGitPullRequestCommentHookStep = {
    Type: HooksElements.GitPullRequestComment;
    GitPullRequestComment: IGitPullRequestCommentHook;
};

export type ICreateGitPullRequestCommentHookStep = IGitPullRequestCommentHookStep;

export const GitPullRequestCommentHookStepCard: FC<IGraphCardProps<IGitPullRequestCommentHook>> = (props) => {
    const { getNode, getNodes, getEdges } = useReactFlow();
    const { cache, setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitPullRequestCommentFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suggestions = useMemo(() => {
        const suggestions: ICodeEditorSuggestion[] = [];

        const node = getNode(props.id)!;
        const references = getAllReferencesRecursively(node, getNodes(), getEdges());

        for (let reference of references) {
            if (reference.id in cache) {
                const formValue = cache[reference.id].getForm();
                if (formValue.Type === HooksElements.FlowOperation) {
                    suggestions.push(...getExposedVariableSuggestions(HooksElements.FlowOperation, reference.id, FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES));
                }
            }
        }

        return suggestions;
    }, [cache, getEdges, getNode, getNodes, props.id]);

    return (
        <HooksStepCard label="Git pull request comment" {...props} className="h-[250px] w-[400px]">
            <Form variables={getDefaultForm(suggestions, props.data)} {...formProps} />
        </HooksStepCard>
    )
}

const convertGitPullRequestCommentFormToStep = (form: IForm): ICreateGitPullRequestCommentHookStep => {
    return {
        Type: HooksElements.GitPullRequestComment,
        GitPullRequestComment: {
            Type: "GitHub",
            Message: form.message as string,
        }
    }
}