import { FC } from "react";
import { useAppQuery } from "../../../api/query";
import { Page } from "../../../components/container";
import { CreateRegistryCard, IRegistry, RegistryCard, getAllRegistriesQuery } from "./registry-card";
import { InternalRoutes } from "../../../config/internal-routes";

type IRegistryPageProps = {
  isCreating?: boolean;
}

export const RegistryPage: FC<IRegistryPageProps> = (props) => {
  const getAllRegistriesQueryResponse = useAppQuery(getAllRegistriesQuery);
  return <Page routes={[InternalRoutes.Container.Registry]}>
    <CreateRegistryCard isCreating={props.isCreating} refetch={getAllRegistriesQueryResponse.refetch} />
    {
      getAllRegistriesQueryResponse.data?.Registry.map((registry: IRegistry) => (
        <RegistryCard key={registry.Id} registry={registry} refetch={getAllRegistriesQueryResponse.refetch} />
      ))
    }
  </Page>
}