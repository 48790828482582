import { FC, useCallback, useRef } from "react";
import { AnimatedButton } from "../../../components/button";
import { ExpandableCard } from "../../../components/card";
import { LogViewer } from "../../../components/log-viewer";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { CI_CD_FLOW_ICON } from "./flow-card";

export type IFlowRun = {
    Id: string;
    Status: string;
    Logs: string;
    PublishedImages: string[];
}

type IFlowRunsCardProps = {
    name: string;
    flowRun: IFlowRun;
}

export const FlowRunCard: FC<IFlowRunsCardProps> = ({ name, flowRun }) => {
    const expandCardRef = useRef<Function>();
    
    const handleExpand = useCallback(() => {
        expandCardRef.current?.(true);
    }, [expandCardRef]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    return <ExpandableCard fullScreen={true} icon={CI_CD_FLOW_ICON} tag={
        <div className="flex grow justify-between items-center px-4">
            <div className="flex gap-2 items-center">
                {name}
                <Pill id={flowRun.Status} label={flowRun.Status} />
                {
                    flowRun.PublishedImages.map(image => (
                        <Pill id={image} label={image} />
                    ))
                }
            </div>
            <div className="flex justify-end">
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
            </div>
        </div>
    } setToggleCallback={ref => expandCardRef.current = ref}
        collapsedTag={<Pill id={flowRun.Status} label={flowRun.Status} />}>
        <>
            <div className="grow text-lg mt-2">{name}</div>
            <div className="flex flex-row items-center justify-between">
                <AnimatedButton label="Explore" icon={Icons.Info} onClick={handleExpand} />
            </div>
        </>
        <LogViewer logs={flowRun.Logs} />
    </ExpandableCard>
}