import classNames from "classnames";
import { filter } from "lodash";
import { FC, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import { Handle, Position, useEdges, useNodes, useReactFlow } from "reactflow";
import { twMerge } from "tailwind-merge";
import { v4 } from "uuid";
import { ActionButton } from "../../../components/button";
import { Card } from "../../../components/card";
import { IGraphCardProps } from "../../../components/graph/graph";
import { createNode, createEdge } from "../../../components/graph/utils";
import { Icons } from "../../../config/icons";
import { FlowGraphViewContext } from "./context";

export enum FlowElements {
    Starting = "starting",
    GitPull = "GitPull",
    GitChangeBranch = "GitChangeBranch",
    DockerBuild = "DockerBuild",
    DockerPush = "DockerPush",
}

type IFlowStepCard<T extends unknown = any> = {
    label: string;
    className?: string;
    children: ReactElement;
} & IGraphCardProps<T>;

export const FlowStepCard: FC<IFlowStepCard> = (props) => {
    const edges = useEdges();
    const nodes = useNodes();
    const { getNodes, setNodes, setEdges } = useReactFlow();
    const { cache } = useContext(FlowGraphViewContext);
    const [error, setError] = useState<string>();

    const handleNewStep = useCallback(() => {
        const validState = cache[props.id].isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError(undefined);
        const nodes = getNodes();
        if (filter(nodes, node => node.type === FlowElements.Starting).length > 0) {
            return;
        }
        const nodeId = v4();
        setNodes(nodes => [
            ...nodes,
            createNode({
                id: nodeId,
                type: FlowElements.Starting,
                data: undefined,
            }, {
                x: props.xPos + (nodes[nodes.length - 1]?.width ?? 400) + 200,
                y: props.yPos,
            })
        ]);
        setEdges(edges => [
            ...edges,
            createEdge(props.id, nodeId),
        ]);
    }, [props, getNodes, setNodes, setEdges, cache]);

    const dependentEdges = useMemo(() => {
        return filter(edges, edge => edge.source === props.id);
    }, [edges, props]);

    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Card icon={{
                component: Icons.Add,
                bgClassName: "bg-teal-500",
            }} className={twMerge(classNames("relative w-[300px] h-fit group/flow-step-selector", props.className))} tag={<div className="flex flex-col gap-1 items-end">
                <div className="text-red-800 text-xs">{error}</div>
            </div>}>
                <div className="flex flex-col grow my-2 gap-2">
                    <div className="flex flex-col">
                        <div className="text-sm text-gray-700 font-bold">{props.label}</div>
                        <div className="w-full border-[1px] border-gray-100" />
                    </div>
                    {props.children}
                </div>
                {
                    nodes[nodes.length - 1]?.type !== FlowElements.DockerPush && dependentEdges.length === 0 && <div className="absolute top-1/2 -translate-y-1/2 -right-14 opacity-0 transition-all group-hover/flow-step-selector:opacity-100">
                        <ActionButton className="w-8 h-8" icon={Icons.Plus} onClick={handleNewStep} />
                    </div>
                }
            </Card>
            <Handle type="source" position={Position.Right} />
        </>
    )
}