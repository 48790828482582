import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactElement, useMemo } from "react";

type ITabSelectorProps = {
    selectedTab: string;
    tabs: string[];
    onTabSelect: (tab: string) => void;
}

export const TabSelector: FC<ITabSelectorProps> = (props) => {
    return <div className="flex gap-2 items-center">
        {
            props.tabs.map(tab => (
                <div className="flex flex-col pb-1 relative cursor-pointer" onClick={() => props.onTabSelect(tab)}>
                    <div className="text-sm transition-all hover:-translate-y-[2px]">{tab}</div>
                    {props.selectedTab === tab &&
                        <motion.div layoutId="underline" className="absolute bottom-0 w-full h-1 bg-slate-900 rounded-lg" />}
                </div>
            ))
        }
    </div>
}

export type IPanel = {
    id: string,
    component: ReactElement,
}

type IPanelSelectorProps = {
    panels: IPanel[];
    selectedPanelId: string;
}

export const PanelSelector: FC<IPanelSelectorProps> = (props) => {
    const selectedPanel = useMemo(() => {
        return props.panels.find(panel => panel.id === props.selectedPanelId)!;
    }, [props]);

    return (
        <div className="flex grow relative h-full w-full">
            <AnimatePresence mode="wait">
                <motion.div key={selectedPanel.id} 
                    className="flex flex-col flex-grow w-full absolute top-0 left-0 h-full"
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}>
                    {selectedPanel.component}
                </motion.div>
            </AnimatePresence>
        </div>
    )
}