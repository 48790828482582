import classNames from "classnames";
import { motion } from "framer-motion";
import parseArgs from "minimist";
import { ChangeEvent, KeyboardEvent, ReactNode, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { Icons } from "../../config/icons";
import { UserRole } from "../../store/auth";
import { useAppSelector } from "../../store/hooks";
import { IDropdownItem } from "../dropdown";
import { Loading } from "../loading";
import { Search } from "../search";
import { sidebarRoutes } from "../sidebar/sidebar";
import { Command, ExecutionResult } from "./commands/command";
import { DockerCommand } from "./commands/docker";
import { useDispatch } from "react-redux";
import { CommonActions } from "../../store/common";
import { Tooltip } from "../tooltip";

const allCommands: Command[] = [
    new DockerCommand()
];

type ICommandInformation = { valid: false } | { valid: true, mainCommand: string, subCommand: string, options: Record<string, string>, parameters: string[] };

function getCommandInformation(command: string): ICommandInformation {
    const args = command.split(" ");
    if (args.length < 3) {
        return { valid: false };
    }
    const mainCommand = args[0];
    const subCommand = args[1];
    const options = parseArgs(args.slice(2));
    const parameters = options._;
    options._ = [];
    return {
        valid: true,
        mainCommand,
        subCommand,
        options,
        parameters,
    }
}

function isValidCommand(command: string) {
    const response = getCommandInformation(command);
    if (!response.valid) {
        return false;
    }
    const { mainCommand, parameters, subCommand } = response;
    return allCommands.some(command => command.isValid(mainCommand) && command.isValidSubCommand(subCommand) && command.hasValidParameters(parameters));
}

function getItems(isAdmin: boolean): IDropdownItem[] {
    return sidebarRoutes.filter(route => isAdmin || route.title !== "Admin")
        .flatMap(sidebarRoute => sidebarRoute.routes.map(route => ({
        id: route.path,
        label: route.name,
        icon: route.icon,
        extra: {
            type: "route",
        }
    })));
}

const RUN_COMMAND = {
    id: "RUN_COMMAND",
    label: "Run command",
    icon: Icons.Play,
    fixed: true,
};

const CHECK_CIRCLE = cloneElement(Icons.CheckCircle, {
    className: "w-6 h-6 stroke-green-500",
});

const EXCLAMATION_CIRCLE = cloneElement(Icons.ExclamationCircle, {
    className: "w-6 h-6 stroke-red-500",
});

const EXCLAMATION_TRIANGLE = cloneElement(Icons.ExclamationTriangle, {
    className: "w-6 h-6 stroke-orange-500",
});

export const GlobalSearchBar = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const globalOpen = useAppSelector(state => state.common.globalSearch);
    const [open, setOpen] = useState(globalOpen);
    const [runCommand, setRunCommand] = useState(false);
    const [commandExecutionResults, setCommandExecutionResults] = useImmer<ExecutionResult[]>([]);
    const navigate = useNavigate();
    const roles = useAppSelector(state => state.auth.user?.Roles ?? []);
    const [currentSearch, setCurrentSearch] = useState("");
    const dispatch = useDispatch();

    const resetSteps = useCallback(() => {
        setCommandExecutionResults([]);
        setRunCommand(false);
    }, [setCommandExecutionResults]);

    const handleClose = useCallback(() => {
        if (open) {
            setOpen(false);
        }
        resetSteps();
    }, [open, resetSteps]);

    const handleRunCommand = useCallback(async () => {
        setRunCommand(true);
        for (let command of allCommands) {
            const response = getCommandInformation(currentSearch);
            if (!response.valid) {
                continue;
            }
            const { subCommand, parameters, options } = response;
            for await (const result of command.execute(subCommand, parameters, options)) {
                setCommandExecutionResults(results => {
                    const foundResultIndex = results.findIndex(currentResult => currentResult.id === result.id);
                    if (foundResultIndex !== -1) {
                        results[foundResultIndex] = result;
                    } else {
                        results.push(result);
                    }
                });
            }
            break;
        }
    }, [currentSearch, setCommandExecutionResults]);

    const handleSelect = useCallback((item: IDropdownItem) => {
        if (item === RUN_COMMAND) {
            handleRunCommand();
            return;
        }
        if (item.extra?.type === "route") {
            navigate(item.id);
            handleClose();
        }
    }, [handleRunCommand, navigate, handleClose]);

    const handleClick = useCallback(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
        setOpen(true);
    }, [inputRef]);

    const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            handleClose();
        }
    }, [handleClose]);
    
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCurrentSearch(e.target.value);
        resetSteps();
    }, [resetSteps]);

    useEffect(() => {
        if (globalOpen) {
            handleClick();
        }
    }, [globalOpen, handleClick]);

    useEffect(() => {
        if (!open) {
            dispatch(CommonActions.setGlobalSearch(false));
        }
    }, [dispatch, open]);

    useHotkeys('meta+k', () => {
        handleClick();
    }, [handleClick]);

    const items = useMemo(() => {
        let defaultItems = getItems(roles.includes(UserRole.AdminRole));
        if (isValidCommand(currentSearch)) {
            defaultItems.unshift(RUN_COMMAND);
        }
        return defaultItems;
    }, [roles, currentSearch]);

    const commandResultSteps = useMemo(() => {
        return commandExecutionResults.map(result => {
            let status: ReactNode = <></>;
            switch (result.status) {
                case "pending":
                    status = <Loading hideText={true} className="h-6 w-6" />
                    break;
                case "done":
                    status = CHECK_CIRCLE;
                    break;
                case "failed":
                    status = EXCLAMATION_CIRCLE;
                    break;
                case "missing-info":
                    status = EXCLAMATION_TRIANGLE;
                    break;
            }
            return <div key={result.id} className={classNames("flex justify-between items-center gap-2  p-2 rounded-lg", {
                "cursor-pointer hover:bg-gray-100": result.link != null,
            })} onClick={result.link == null ? undefined : () => navigate(result.link!)}>
                <div className="flex gap-2 items-center grow">
                    {cloneElement(result.icon, {
                        classNames: "w-6 h-6 stroke-gray-700",   
                    })}
                    <span className="text-sm text-gray-700">
                        {result.label}
                    </span>
                </div>
                <div>
                    {
                        result.tooltip != null
                        ? <Tooltip tooltip={result.tooltip}>{status}</Tooltip>
                        : status
                    }
                </div>
            </div>
        });
    }, [commandExecutionResults, navigate]);

    return <>
            <div className={classNames("fixed inset-0 z-30", {
                "hidden": !open,
            })} onClick={handleClose}>
            </div>
            <motion.div layout className={classNames("fixed top-[20%] left-1/2 -translate-x-1/2  h-fit bg-white z-30 rounded-lg", {
                "shadow-2xl": open,
            })} initial="close" variants={{
                open: {
                    opacity: 1,
                    top: `${window.innerHeight*0.2}px`,
                    display: "block",
                    width: `${Math.min(650, window.innerWidth - 20)}px`,
                },
                close: {
                    opacity: 1,
                    top: "20px",
                    width: "180px",
                }
            }} animate={open ? "open" : "close"} onClick={handleClick}>
                <div className="relative">
                    <Search items={items} label={open ? "Search or run commands" : "Command Palette"} actionLabel="Go to"
                        actionIcon={Icons.CircleRightArrow} onSelect={handleSelect} isOpen={open && !runCommand}
                        controlled={true} inputProps={{
                            ref: inputRef,
                            className: classNames("transition-all", {
                                "cursor-pointer rounded-3xl hover:pl-3": !open,
                            }),
                            disabled: !open,
                            onKeyUp: handleKeyUp,
                            onChange: handleChange,
                        }} noItemsLabel="No items or command found" />
                    <div className={classNames("absolute top-[40px] bg-white border border-gray-200 rounded-md shadow-xl w-full min-h-[150px] flex flex-col gap-2 p-4", {
                        "hidden": !open || !runCommand,
                    })}>
                        <div className="text-lg text-gray-700 border-b border-gray-100 mb-2">Executing steps</div>
                        {commandResultSteps}
                    </div>
                </div>
            </motion.div>
    </>
}