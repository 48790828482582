import { map } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { Handle, Position, useNodes, useReactFlow } from "reactflow";
import { Card } from "../../../../components/card";
import { IDropdownItem } from "../../../../components/dropdown";
import { IGraphCardProps } from "../../../../components/graph/graph";
import { Search } from "../../../../components/search";
import { Icons } from "../../../../config/icons";
import { FlowElements } from "../flow-step-card";

const allStepsMap = {
    [FlowElements.GitPull]: {
        id: FlowElements.GitPull,
        label: "Git pull",
        icon: Icons.ArrowDownWithSquare,
    },
    [FlowElements.GitChangeBranch]: {
        id: FlowElements.GitChangeBranch,
        label: "Git change branch",
        icon: Icons.Cog,
    },
    [FlowElements.DockerBuild]: {
        id: FlowElements.DockerBuild,
        label: "Docker build",
        icon: Icons.Logos.DockerIO,
    },
    [FlowElements.DockerPush]: {
        id: FlowElements.DockerPush,
        label: "Docker push",
        icon: Icons.Logos.DockerIO,
    },
}

export const StartingSelectorFlowStep: FC<IGraphCardProps> = (props) => {
    const [steps, setSteps] = useState<IDropdownItem[]>([]);
    const nodes = useNodes();
    const { setNodes } = useReactFlow();
    const handleSelect = useCallback((item: IDropdownItem) => {
        setNodes(nodes => {
            for (const node of nodes) {
                if (node.id === props.id) {
                    node.type = item.id;
                }
            }
            return nodes;
        });
    }, [props, setNodes]);

    useEffect(() => {
        const nodesSet = new Set(map(nodes, node => node.type));
        setSteps(() => {
            const stepsToAdd = [];
            if (!nodesSet.has(FlowElements.GitPull)) {
                stepsToAdd.push(allStepsMap[FlowElements.GitPull]);
            }
            if (!nodesSet.has(FlowElements.GitChangeBranch) && nodesSet.has(FlowElements.GitPull) && !nodesSet.has(FlowElements.DockerBuild)) {
                stepsToAdd.push(allStepsMap[FlowElements.GitChangeBranch]);
            }
            const dockerPushExists = nodesSet.has(FlowElements.DockerPush);
            if (nodesSet.has(FlowElements.GitPull) && !dockerPushExists) {
                stepsToAdd.push(allStepsMap[FlowElements.DockerBuild]);
            }
            if (nodesSet.has(FlowElements.DockerBuild) && !dockerPushExists) {
                stepsToAdd.push(allStepsMap[FlowElements.DockerPush]);
            }
            return stepsToAdd;
        });
    }, [nodes]);

    return (<>
        <Handle type="target" position={Position.Left} />
        <Card icon={{
            component: Icons.Add,
            bgClassName: "bg-teal-500",
        }} className="w-[300px] h-[300px]">
            <div className="flex flex-col gap-1 my-4 grow">
                <div className="text-lg my-2">Create step</div>
                <Search label="Search for steps" items={steps} isOpen={true} onSelect={handleSelect} noItemsLabel="No steps found" dropdownProps={{
                    scrollContainerClassName: "h-28",
                }} />
            </div>
        </Card>
        <Handle type="source" position={Position.Right} />
    </>)
}
