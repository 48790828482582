import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { forEach, map } from "lodash";
import { FC, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Edge, Node, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { useImmer } from "use-immer";
import { AnimatedButton } from "../../../components/button";
import { ExpandableCard } from "../../../components/card";
import { createNode, createEdge } from "../../../components/graph/utils";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { HookGraphViewContext, IHookGraphData, IHookGraphViewCache } from "./context";
import { CI_CD_HOOK_ICON, GraphHooksCard, IHookStep } from "./hooks-card";
import { HooksElements } from "./hooks-step-card";

const getOneHookStepsQuery = loader("./get-one-hook-run-steps.graphql");

type IHookRunPreviewProps = {
    hookRun: IHookRun;
    hookId: string;
}

const HookRunPreview: FC<IHookRunPreviewProps> = ({ hookId, hookRun }) => {
    const [getOneHookSteps, ] = useLazyQuery(getOneHookStepsQuery);
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    useEffect(() => {
        getOneHookSteps({
            variables: {
                hookId,
                id: hookRun.Id,
            },
            onCompleted(data: { HookRuns: IHookRun[] }) {
                const hookRunSteps = data?.HookRuns?.[0]?.Steps ?? [];
                const newNodes: Node[] = map(hookRunSteps, (hookRunStep: IHookRunStep, i: number) => {
                    const node = createNode({
                        id: hookRunStep.Step.NodeId,
                        type: hookRunStep.Step.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(hookRunStep.Step.Type) {
                        case HooksElements.GitWebHook:
                            node.data = hookRunStep.Step.GitWebHook;
                            break;
                        case HooksElements.FlowOperation:
                            node.data = hookRunStep.Step.FlowOperation;
                            break;
                        case HooksElements.OperationResult:
                            node.data = hookRunStep.Step.OperationResult;
                            break;
                        case HooksElements.QuickContainerOperation:
                            node.data = hookRunStep.Step.QuickContainerOperation;
                            break;
                    }
                    node.data = {...node.data, Status: hookRunStep.Status};
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(hookRunSteps, (hookRunStep) => {
                    forEach(hookRunStep.Step.Dependents, dependent => {
                        newEdges.push(createEdge(hookRunStep.Step.NodeId, dependent));
                    });
                });
                setEdges(newEdges);
            },
        });
    }, [hookId, hookRun, navigate, getOneHookSteps, setNodes, setEdges]);

    return <HookGraphViewContext.Provider value={{
        cache,
        setCacheItem: handleSetCacheItem,
        removeCacheItem: handleRemoveCacheItem,
    }}>
        <ReactFlowProvider>
            <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} />
        </ReactFlowProvider>
    </HookGraphViewContext.Provider>
}

type IHookRunStep = {
    Id: string;
    Status: string;
    Step: IHookStep;
}

export type IHookRun = {
    Id: string;
    Status: string;
    Steps: IHookRunStep[];
}

type IHookRunsCardProps = {
    name: string;
    hookId: string;
    hookRun: IHookRun;
}

export const HookRunCard: FC<IHookRunsCardProps> = ({ name, hookRun, hookId }) => {
    const expandCardRef = useRef<Function>();
    
    const handleExpand = useCallback(() => {
        expandCardRef.current?.(true);
    }, [expandCardRef]);

    const handleCancel = useCallback(() => {
        expandCardRef.current?.(false);
    }, [expandCardRef]);

    return <ExpandableCard className="relative" fullScreen={true} icon={CI_CD_HOOK_ICON} tag={<div className="flex grow justify-between items-center px-4">
            <div className="flex gap-2 items-center">
                {name}
                <Pill id={hookRun.Status} label={hookRun.Status} />
            </div>
            <div className="flex justify-end">
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
            </div>
        </div>} setToggleCallback={ref => expandCardRef.current = ref}
        collapsedTag={<Pill id={hookRun.Status} label={hookRun.Status} />}>
        <>
            <div className="grow text-lg mt-2">{name}</div>
            <div className="flex flex-row items-center justify-between">
                <AnimatedButton label="Explore" icon={Icons.Info} onClick={handleExpand} />
            </div>
        </>
        <HookRunPreview hookRun={hookRun} hookId={hookId} />
    </ExpandableCard>
}