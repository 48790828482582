import { FC, useEffect } from "react";
import { Page } from "../../components/container";
import { CreateIntegrationsCard } from "./integrations-card";
import { useAppQuery } from "../../api/query";
import { loader } from "graphql.macro";
import { DockerIOIntegrationsCard, IDockerIORegistry } from "./docker-io-integrations/docker-io-integrations-card";
import { useSearchParams } from "react-router-dom";
import { notify } from "../../store/function";
import { GitHubIntegrationsCard, IGitHubIntegration } from "./github-integrations/github-integrations-card";
import { DigitalOceanIntegrationsCard, IDigitalOceanIntegration } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { InternalRoutes } from "../../config/internal-routes";
import { GoogleIntegrationsCard, IGoogleIntegration } from "./google/google-integrations-card";

export const getAllIntegrationsQuery = loader("./get-integrations.graphql");

type IIntegrationsPageProps = {
    isCreating?: boolean;
}

export const IntegrationsPage: FC<IIntegrationsPageProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, refetch } = useAppQuery(getAllIntegrationsQuery);

    useEffect(() => {
        if (searchParams.has("success")) {
            notify(searchParams.get("success")!, "success");
            searchParams.delete("success");
            setSearchParams(searchParams);
            refetch();
        }
        if (searchParams.has("error")) {
            notify(searchParams.get("error")!, "error");
            searchParams.delete("error");
            setSearchParams(searchParams);
        }
    }, [searchParams, refetch, setSearchParams]);

    return (
        <Page routes={[InternalRoutes.Integrations.Integrations]}>
            <CreateIntegrationsCard isCreating={props.isCreating} refetch={refetch} />
            {data?.Integration.DockerIORegistryIntegration.map((dockerIORegistry: IDockerIORegistry) => (
                <DockerIOIntegrationsCard key={dockerIORegistry.Id} dockerIORegistry={dockerIORegistry} refetch={refetch} />
            ))}
            {data?.Integration.GitHubIntegration.map((githubIntegration: IGitHubIntegration) => (
                <GitHubIntegrationsCard key={githubIntegration.Id} githubIntegration={githubIntegration} refetch={refetch} />
            ))}
            {data?.Integration.DigitalOceanIntegration.map((digitalOceanIntegration: IDigitalOceanIntegration) => (
                <DigitalOceanIntegrationsCard key={digitalOceanIntegration.Id} digitalOceanIntegration={digitalOceanIntegration} refetch={refetch} />
            ))}
            {data?.Integration.GoogleIntegration.map((googleIntegration: IGoogleIntegration) => (
                <GoogleIntegrationsCard key={googleIntegration.Id} googleIntegration={googleIntegration} refetch={refetch} />
            ))}
        </Page>
    )
}