import { includes } from "lodash";
import { DetailedHTMLProps, FC, InputHTMLAttributes, KeyboardEventHandler, ReactElement, ReactNode, cloneElement, useCallback, useMemo, useState } from "react";
import { Icons } from "../config/icons";
import { AnimatedButton } from "./button";
import { Dropdown, IDropdownItem, IDropdownProps } from "./dropdown";
import { Input } from "./input";


type ISearchInputProps = {
    search: string;
    setSearch: (search: string) => void;
    placeholder?: string;
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const SearchInput: FC<ISearchInputProps> = ({ search, setSearch, placeholder, inputProps }) => {
    return (<div className="relative grow">
        <Input value={search} setValue={setSearch} placeholder={placeholder} inputProps={{ autoFocus: true, ...inputProps }} />
        {cloneElement(Icons.Search, {
            className: "w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 stroke-gray-500 cursor-pointer transition-all hover:scale-110 bg-white rounded-full",
        })}
    </div>)
}

export type SearchProps = {
    label: string;
    items: IDropdownItem[];
    className?: string;
    isOpen?: boolean;
    selectedItem?: IDropdownItem | IDropdownItem[];
    onSelect?: (item: IDropdownItem) => void;
    noItemsLabel?: string;
    actionIcon?: ReactElement;
    actionLabel?: string;
    children?: ReactNode;
    dropdownProps?: Partial<IDropdownProps>;
    disabled?: boolean;
    controlled?: boolean;
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const Search: FC<SearchProps> = ({ label, items, className, selectedItem, isOpen, controlled, onSelect, noItemsLabel, actionIcon, actionLabel, children, dropdownProps = {}, disabled, inputProps }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
    const [shouldSearch, setShouldSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedIndex, setSelectedIndex] = useState<number>();

    const filteredItems = useMemo(() => {
        return items.filter(item => item.fixed || includes(item.id, search) || includes(item.label, search));
    }, [items, search]);

    const handleClick = useCallback(() => {
        setShouldSearch(true);
        setIsDropdownOpen(true);
    }, []);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setShouldSearch(false);
        onSelect?.(item);
        setIsDropdownOpen(false);
        setSearch("");
    }, [onSelect]);

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
        if (event.key === "ArrowUp") {
            if (selectedIndex == null) {
                setSelectedIndex(filteredItems.length - 1);
            } else {
                setSelectedIndex((filteredItems.length + (selectedIndex - 1)) % filteredItems.length);
            }
        } else if (event.key === "ArrowDown") {
            if (selectedIndex == null) {
                setSelectedIndex(0);
            } else {
                setSelectedIndex((selectedIndex + 1) % filteredItems.length);
            }
        } else if (event.key === "Enter" && selectedIndex != null) {
            handleSelect(filteredItems[selectedIndex]);
        }
        inputProps?.onKeyUp?.(event);
    }, [selectedIndex, handleSelect, filteredItems, inputProps]);
    
    const handleClearSelection = useCallback(() => {
        setSelectedIndex(undefined);
    }, []);

    return <Dropdown {...dropdownProps} disabled={disabled} className={className} items={filteredItems} isOpen={controlled ? isOpen : isDropdownOpen} action={<div>
        <AnimatedButton label={actionLabel ?? "Select"} icon={actionIcon ?? Icons.CheckCircle} labelClassName="text-blue-800" iconClassName="text-blue-600" />
    </div>} onClick={disabled ? undefined : handleSelect} noItemsLabel={noItemsLabel}
        highlightedDropdownItem={selectedIndex == null ? undefined : filteredItems[selectedIndex]} onItemHover={handleClearSelection}
        selectedItems={selectedItem}>
        <>
            {
                selectedItem != null && !shouldSearch
                    ? <div onClick={disabled ? undefined : handleClick}>{children}</div>
                    : <SearchInput search={search} setSearch={setSearch} placeholder={label} inputProps={{
                        ...inputProps,
                        onKeyUp: handleKeyUp,
                    }} />
            }
        </>
    </Dropdown>
}