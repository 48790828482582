import { DocumentNode, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { find, isArray, isFunction, isString, reduce } from "lodash";
import { ChangeEvent, FC, MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Updater, useImmer } from "use-immer";
import { Dropdown, IDropdownItem, IDropdownProps } from "./dropdown";
import { InputWithlabel } from "./input";
import { PrivateTag } from "./permissions/private-tag";
import { Search } from "./search";

type INativeFormVariableType = "text" | "text[]" | "password" | "custom";
type INativeFormVariableReturnValue = string | string[] | undefined | any;

type INativeFormVariableBase = {
    name: string;
    defaultValue?: INativeFormVariableReturnValue;
    label: string;
    validate: (value: any) => boolean;
    errorMessage: string | ((value: any) => string);
    disabled?: boolean | ((form: IForm) => boolean);
    clearable?: boolean;
    hide?: boolean | ((form: IForm) => boolean);
} & ({ fieldType: "text", onRender?: (value: string, setValue: (value: string) => void) => ReactElement; } | { fieldType: "text[]", onRender?: (value: string[], setValue: (value: string[]) => void) => ReactElement; } | { fieldType: "password" });

type INativeFormVariable = INativeFormVariableBase | {
    name: string;
    defaultValue?: INativeFormVariableReturnValue;
    label: string;
    fieldType: "custom";
    onRender: (value: any, setValue: (value: any) => void) => ReactElement;
    validate: (value: any) => boolean;
    hide?: boolean | ((form: IForm) => boolean);
    errorMessage: string | ((value: any) => string);
}

type IQueryFormVariable<T extends unknown = any> = {
    type: "query";
    query: DocumentNode;
    transform: (data: T, setForm: Updater<IForm>) => IDropdownItem[];
    defaultIcon: ReactElement;
    isLazy?: boolean;
    shouldQuery?: (form: IForm) => [boolean, any];
    dropdownProps?: Partial<IDropdownProps>;
    onClick?: (item: IDropdownItem, setForm: Updater<IForm> ) => void;
    clearable?: boolean;
    disablePermissionLock?: boolean;
    allowCustomValues?: boolean;
} & INativeFormVariable;

type IDropdownFormVariable = {
    type: "dropdown";
    dropdownProps?: Partial<IDropdownProps>;
    defaultIcon: ReactElement;
} & INativeFormVariable;

export type IFormVariable = INativeFormVariable | IQueryFormVariable | IDropdownFormVariable;

export type IForm = { [key: string]: INativeFormVariableReturnValue };

type FormUpdaterProps = { form: IForm, setForm: Updater<IForm>};
type NativeFormVariableProps = { variable: INativeFormVariable, queryItems?: IDropdownItem[] } & FormUpdaterProps;

const NativeFormVariable: FC<NativeFormVariableProps> = ({ variable, form, setForm }) => {
    const formValue = form[variable.name];
    const handleTextValue = useCallback((value: string) => {
        setForm(form => {
            form[variable.name] = value;
        });
    }, [setForm, variable]);
    const handleTextArrayValue = useCallback((value: string) => {
        setForm(form => {
            form[variable.name] = value.split(",");
        });
    }, [setForm, variable]);
    
    return useMemo(() => {
        if ((variable.fieldType === "text" || variable.fieldType === "password") && isString(formValue)) {
            if (variable.fieldType === "text" && variable.onRender != null) {
                return variable.onRender(formValue, handleTextValue);
            }
            const disabled = isFunction(variable.disabled) ? variable.disabled(form) : variable.disabled;
            return <InputWithlabel label={variable.label} value={formValue} setValue={handleTextValue} type={variable.fieldType} inputProps={{
                disabled,
            }} />
        } else if (variable.fieldType === "text[]" && isArray(formValue)) {
            const disabled = isFunction(variable.disabled) ? variable.disabled(form) : variable.disabled;
            return <InputWithlabel label={variable.label} value={formValue.join(",")} setValue={handleTextArrayValue} inputProps={{
                disabled,
            }} />
        } else if (variable.fieldType === "custom") {
            return variable.onRender(formValue, handleTextValue);
        }
        return <></>;
    }, [variable, formValue, form, handleTextValue, handleTextArrayValue]);
}

type IQueryFormVariableProps = IQueryFormVariable & FormUpdaterProps;

const MOCK_SELECTION = {id: "locked", label: "locked"};

const QueryFormVariable: FC<IQueryFormVariableProps> = (props) => {
    const [search, setSearch] = useState("");
    const [query, queryResponse] = useLazyQuery(props.query);

    const dataItems = useMemo(() => {
        return props.transform(queryResponse.data, props.setForm);
    }, [props, queryResponse.data]);

    const items = useMemo(() => {
        if (!props.allowCustomValues || search.length === 0) {
            return dataItems;
        }
        const items: IDropdownItem[] = [];
        if (props.allowCustomValues && search.length > 0) {
            for (const dataItem of dataItems) {
                if (dataItem.id === search) {
                    return dataItems;
                }
                items.push(dataItem);
            }
            items.push({
                id: search,
                label: search,
            });
        }
        return items;
    }, [dataItems, props.allowCustomValues, search]);

    const selectedItems = useMemo(() => {
        if (items.length === 0) {
            return [];
        }
        const values = props.form[props.name];
        const isTextArrayDropdown = isArray(values);
        if (isTextArrayDropdown) {
            let selected: IDropdownItem[] = [];
            for (let value of values) {
                const foundValue = items.find(item => item.id === value);
                if (foundValue == null) {
                    continue;
                }
                selected.push(foundValue);
            }
            return selected;
        }
        return find(items, (item) => item.id === values);
    }, [items, props.form, props.name]);

    const handleClick = useCallback((item: IDropdownItem) => {
        if (isArray(selectedItems) && props.fieldType === "text[]" && selectedItems.find(i => i.id === item.id) != null) {
            props.setForm(form => {
                const value = form[props.name]
                if (isArray(value)) {
                    form[props.name] = value.filter(id => id !== item.id);
                }
            });
            return;
        }
        if (!isArray(selectedItems) && props.fieldType === "text" && selectedItems?.id === item.id) {
            props.setForm(form => {
                form[props.name] = undefined;
            });
            return;
        }
        props.onClick?.(item, props.setForm);
        if (props.fieldType === "text[]") {
            props.setForm(form => {
                const value = form[props.name]
                if (isArray(value)) {
                    form[props.name] = [...value, item.id];
                }
            });
        } else {
            props.setForm(form => {
                form[props.name] = item.id;
            });
        }
    }, [props, selectedItems]);

    useEffect(() => {
        if (!props.isLazy) {
            query();
        }
        if (props.shouldQuery != null) {
            const [shouldQuery, variables] = props.shouldQuery?.(props.form);
            if (shouldQuery) {
                query({
                    variables,
                });
            }
        }
    }, [props, query]);

    const handleClear = useCallback(() => {
        props.setForm(form => {
            form[props.name] = getVariableValues(props.fieldType);
        });
    }, [props]);

    const locked = useMemo(() => {
        if (props.disablePermissionLock) {
            return false;
        }
        if (queryResponse.error != null) {
            return true;
        }
        if (!isString(props.defaultValue)) {
            return false;
        }
        return props.defaultValue != null && queryResponse.called && items.find(item => item.id === props.defaultValue) == null;
    }, [items, props.defaultValue, props.disablePermissionLock, queryResponse.called, queryResponse.error]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }, []);

    const disabled = useMemo(() => {
        if (queryResponse.loading) {
            return false;
        }
        if (locked) {
            return true;
        }
        if (props.fieldType === "custom") {
            return false;
        }
        return isFunction(props.disabled) ? props.disabled(props.form) : props.disabled;
    }, [queryResponse.loading, locked, props]);

    return (
        <div className="flex flex-col w-full gap-1">
            <strong><label className="text-xs text-gray-600 mt-2">{props.label}</label></strong>
            <Search label={`Search for ${props.label}`} className="w-full" selectedItem={locked ? MOCK_SELECTION : selectedItems} items={items} onSelect={handleClick} dropdownProps={{
                scrollContainerClassName: "max-h-20",
                defaultItem: props.dropdownProps?.defaultItem,
                onDefaultItemClick: props.dropdownProps?.onDefaultItemClick,
                loading: queryResponse.loading,
                clearable: props.clearable,
                onClear: handleClear,
                disabled,
            }} noItemsLabel={props.dropdownProps?.noItemsLabel} disabled={disabled} inputProps={{
                disabled,
                onChange: handleChange,
            }}>
                {
                    <button className="h-[34px] appearance-none border border-gray-200 rounded w-full p-1 text-gray-700/75 leading-tight focus:outline-none focus:shadow-outline text-sm text-left flex gap-1 items-center transition-all hover:gap-2">
                        {
                            locked
                            ? props.allowCustomValues ? <div>{props.form[props.name]}</div> : <PrivateTag />
                            : isArray(selectedItems)
                                ? <>
                                    <div>{props.defaultIcon}</div>
                                    <div>{selectedItems.map(item => item.label).join(",")}</div>
                                </>
                                : <>
                                    <div>{selectedItems?.icon ?? props.defaultIcon}</div>
                                    <div>{selectedItems?.label}</div>
                                </>
                        }
                    </button>
                }
            </Search>
        </div>
    )
}

type IDropdownFormVariableProps = IDropdownFormVariable & FormUpdaterProps;

const DropdownFormVariable: FC<IDropdownFormVariableProps> = (props) => {
    const selectedItems = useMemo(() => {
        const items = props.dropdownProps?.items;
        if (items == null || items.length === 0) {
            return [];
        }
        const values = props.form[props.name];
        const isTextArrayDropdown = isArray(values);
        if (isTextArrayDropdown) {
            let selected: IDropdownItem[] = [];
            for (let value of values) {
                const foundValue = items.find(item => item.id === value);
                if (foundValue == null) {
                    continue;
                }
                selected.push(foundValue);
            }
            return selected;
        }
        return find(items, (item) => item.id === values);
    }, [props.dropdownProps?.items, props.form, props.name]);

    const handleClick = useCallback((item: IDropdownItem) => {
        if (isArray(selectedItems) && props.fieldType === "text[]" && selectedItems.find(i => i.id === item.id) != null) {
            props.setForm(form => {
                const value = form[props.name]
                if (isArray(value)) {
                    form[props.name] = value.filter(id => id !== item.id);
                }
            });
            return;
        }
        if (!isArray(selectedItems) && props.fieldType === "text" && selectedItems?.id === item.id) {
            props.setForm(form => {
                form[props.name] = undefined;
            });
            return;
        }
        if (props.fieldType === "text[]") {
            props.setForm(form => {
                const value = form[props.name]
                if (isArray(value)) {
                    form[props.name] = [...value, item.id];
                }
            });
            return;
        }
        props.setForm(form => {
            form[props.name] = item.id;
        });
    }, [props, selectedItems]);

    const handleSetArrayValue = useCallback((value: string) => {
        props.setForm(form => {
            if (value.length === 0) {
                form[props.name] = [];
                return;
            }
            form[props.name] = value.split(",");
        });
    }, [props]);

    const disabled = useMemo(() => {
        if (props.fieldType === "custom") {
            return false;
        }
        if (isFunction(props.disabled)) {
            return props.disabled(props.form);
        }
        return props.disabled;
    }, [props]);

    if (props.fieldType === "text[]") {
        return (<Dropdown className="w-full" {...(props.dropdownProps ?? {})}
            items={props.dropdownProps?.items ?? []}
            scrollContainerClassName="max-h-20"
            onClick={handleClick}
            disabled={disabled}
            selectedItems={selectedItems}>
            <InputWithlabel label={props.label} value={(props.form[props.name] as string[]).join(",")} setValue={handleSetArrayValue} inputProps={{
                disabled: disabled,
            }} />
        </Dropdown>);
    }

    const selectedItem = find(props.dropdownProps?.items ?? [], (item) => item.id === props.form[props.name]);
    return (
        <div className="flex flex-col w-full">
            <strong><label className={twMerge(classNames("text-xs text-gray-600 mt-2", {
                "text-gray-300": disabled,
            }))}>{props.label}</label></strong>
            <Dropdown className="w-full" items={props.dropdownProps?.items ?? []} scrollContainerClassName={twMerge(classNames("max-h-20", props.dropdownProps?.scrollContainerClassName))} onClick={handleClick}
                defaultItem={props.dropdownProps?.defaultItem} onDefaultItemClick={props.dropdownProps?.onDefaultItemClick} noItemsLabel={props.dropdownProps?.noItemsLabel} disabled={disabled}>
                <button className={twMerge(classNames("appearance-none border border-gray-200 rounded w-full p-1 text-gray-700/75 leading-tight focus:outline-none focus:shadow-outline text-sm text-left flex gap-1 items-center", {
                    "cursor-not-allowed text-gray-300": disabled,
                }))}>
                    <div>{selectedItem?.icon ?? props.defaultIcon}</div>
                    <div>{selectedItem?.label}</div>
                </button>
            </Dropdown>
        </div>
    )
}

export type IFormGrid = (IFormVariable | IFormVariable[])[];

type IInternalFormProps = {
    variables: IFormGrid;
} & FormUpdaterProps;

const InternalForm: FC<IInternalFormProps> = ({ variables, form, setForm }) => {
    return <>
        {variables.map(variable => {
            if (isArray(variable)) {
                return <div className="flex flex-row gap-1">
                    <InternalForm variables={variable} form={form} setForm={setForm} />
                </div>
            }
            if (variable.hide != null) {
                if (isFunction(variable.hide) && variable.hide(form)) {
                    return <></>
                } else if (variable.hide === true) {
                    return <></>
                }
            }
            if ("type" in variable) {
                if (variable.type === "query") {
                    return <QueryFormVariable form={form} setForm={setForm} {...variable} />
                } else if (variable.type === "dropdown") {
                    return <DropdownFormVariable form={form} setForm={setForm} {...variable} />
                }
            } else {
                return <NativeFormVariable form={form} setForm={setForm} variable={variable} />
            }
            return <></>
        })}
    </>
}

type IUseFormHook = [
    Pick<IFormProps,"validationRef" | "formRef" | "setFormRef">,
    {
        isFormValid: IValidationCallback;
        getForm: () => IForm;
        setForm: (field: string, value: INativeFormVariableReturnValue) => void;
    }
];

export const useFormHook = (defaultValues?: IForm): IUseFormHook => {
    const validationRef = useRef<IValidationCallback>(() => ({ isValid: true }));
    const formRef = useRef<IForm>(defaultValues ?? {});
    const setFormRef = useRef<(field: string, value: INativeFormVariableReturnValue) => void>(() => {});
    const handleIsValid = useCallback(() => {
        return validationRef.current!();
    }, [validationRef]);
    const handleGetForm = useCallback(() => {
        return formRef.current!;
    }, [formRef]);
    const handleSetForm = useCallback((field: string, value: INativeFormVariableReturnValue) => {
        return setFormRef.current!(field, value);
    }, [setFormRef]);

    return [
        { validationRef, formRef, setFormRef },
        {
            isFormValid: handleIsValid,
            getForm: handleGetForm,
            setForm: handleSetForm,
        }
    ]
};

type IValidationState = {
    isValid: true;
} | {
    isValid: false;
    errorMessage: string;
}

export type IValidationCallback = () => IValidationState;

type IFormProps = {
    variables: IFormGrid;
    validationRef?: MutableRefObject<IValidationCallback>;
    formRef?: MutableRefObject<IForm>;
    setFormRef?: MutableRefObject<(field: string, value: INativeFormVariableReturnValue) => void>;
    defaultExtraValues?: IForm;
}

export const Form: FC<IFormProps> = ({ variables, validationRef, formRef, setFormRef, defaultExtraValues = {} }) => {
    const defaultFormValue = useMemo(() => reduce(variables, (variableMap, variable) => {
        if (isArray(variable)) {
            return {
                ...variableMap,
                ...reduce(variable, (subVariableMap, subVar) => {
                    if (isArray(subVar)) {
                        return {};
                    }
                    return {
                        ...subVariableMap,
                        [subVar.name]: defaultExtraValues[subVar.name] ?? getVariableValues(subVar.fieldType, subVar.defaultValue),
                    }
                }, {})
            };
        }
        return {
            ...variableMap,
            [variable.name]: defaultExtraValues[variable.name] ?? getVariableValues(variable.fieldType, variable.defaultValue),
        }
    }, defaultExtraValues), [variables, defaultExtraValues]);
    const [form, setForm] = useImmer<IForm>(defaultFormValue);

    const handleValidateInternal = useCallback((variable: IFormVariable): [boolean, string] => {
        const formValue = form[variable.name];
        if ((isFunction(variable.hide) && variable.hide(form)) || variable.hide === true) {
            return [true, ""];
        }
        if (!variable.validate(formValue)) {
            let message: string;
            if (isFunction(variable.errorMessage)) {
                message = variable.errorMessage(formValue);
            } else {
                message = variable.errorMessage;
            }
            return [false, message];
        }
        return [true, ""];
    }, [form]);

    const handleFormValidation = useCallback((): IValidationState => {
        for (const variable of variables) {
            if (isArray(variable)) {
                for (const subVariable of variable) {
                    const [isValid, errorMessage] = handleValidateInternal(subVariable);
                    if (!isValid) {
                        return { isValid: false, errorMessage };
                    }
                }
                continue;
            }
            const [isValid, errorMessage] = handleValidateInternal(variable);
            if (!isValid) {
                return  { isValid: false, errorMessage };
            }
        }
        return { isValid: true }
    }, [variables, handleValidateInternal]);

    const handleSetFormRef = useCallback((field: string, value: INativeFormVariableReturnValue) => {
        setForm(f => {
            f[field] = value;
        });
    }, [setForm]);

    useEffect(() => {
        if (validationRef?.current == null) {
            return;
        }
        validationRef.current = handleFormValidation;
    }, [validationRef, handleFormValidation]);

    useEffect(() => {
        if (formRef?.current == null) {
            return;
        }
        formRef.current = form;
    }, [formRef, form]);

    useEffect(() => {
        if (setFormRef?.current == null) {
            return;
        }
        setFormRef.current = handleSetFormRef;
    }, [setFormRef, handleSetFormRef]);

    return <div className="flex flex-col grow gap-1 animate-fade">
            <InternalForm variables={variables} form={form} setForm={setForm} />
        </div>
}

function getVariableValues(type: INativeFormVariableType, defaultValue?: INativeFormVariableReturnValue): INativeFormVariableReturnValue {
    switch (type) {
        case "text":
        case "password":
            return defaultValue ?? "";
        case "text[]":
            return defaultValue ?? [] as string[];
        case "custom":
            return defaultValue;
    }
}