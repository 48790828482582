import { FC } from "react";
import { Page } from "../../../components/container";
import { CreateFlowCard, FlowCard, IFlow } from "./flow-card";
import { useAppQuery } from "../../../api/query";
import { loader } from "graphql.macro";
import { InternalRoutes } from "../../../config/internal-routes";

type IFlowProps = {
    isCreating?: boolean;
}

export const getAllFlowQuery = loader("./get-flows.graphql");

export const FlowPage: FC<IFlowProps> = (props) => {
    const getFlowsResponse = useAppQuery(getAllFlowQuery);
    return (
        <Page routes={[InternalRoutes.CI_CD.Flow]}>
            <CreateFlowCard isCreating={props.isCreating} refetch={getFlowsResponse.refetch} />
            {
                getFlowsResponse.data?.Flow?.map((flow: IFlow) => (
                    <FlowCard key={flow.Id} flow={flow} refetch={getFlowsResponse.refetch} />
                ))
            }
        </Page>
    )
}