import { FC } from "react";
import { useAppQuery } from "../../../api/query";
import { Page } from "../../../components/container";
import { CreateRegistryImageCard, IRegistryImage, RegistryImageCard, getAllRegistryImagesQuery } from "./registry-image-card";
import { InternalRoutes } from "../../../config/internal-routes";

type IRegistryImagePageProps = {
    isCreating?: boolean;
}

export const RegistryImagePage: FC<IRegistryImagePageProps> = (props) => {
    const getAllRegistryImagesQueryResponse = useAppQuery(getAllRegistryImagesQuery);
    return <Page routes={[InternalRoutes.Container.Image]}>
        <CreateRegistryImageCard isCreating={props.isCreating} refetch={getAllRegistryImagesQueryResponse.refetch} />
        {
            getAllRegistryImagesQueryResponse.data?.RegistryImage.map((registryImage: IRegistryImage) => (
                <RegistryImageCard key={registryImage.Id} registryImage={registryImage} refetch={getAllRegistryImagesQueryResponse.refetch} />
            ))
        }
    </Page>
}