import Keycloak from "keycloak-js";
import { FC, useEffect } from "react";
import { CLIDEY_ACCESS_TOKEN, CLIDEY_REFRESH_TOKEN } from "../config/constants";
import { LoadingPage } from "../page/loading";
import { AppDispatch } from "../store";
import { AuthActions } from "../store/auth";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCookie } from "../utils/functions";

const keycloakInstance: Keycloak = new Keycloak({
  url: `${window.location.protocol}//${window.location.hostname}/auth`,
  realm: process.env.REACT_APP_AUTH_REALM!,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
});

function setToken(dispatch: AppDispatch, token: string, refreshToken: string) {
  setCookie(CLIDEY_ACCESS_TOKEN, token, 30);
  setCookie(CLIDEY_REFRESH_TOKEN, refreshToken, 30);
  dispatch(AuthActions.setToken({
    accessToken: token,
    refreshToken: refreshToken,
  }));
}

type KeycloakProviderProps = {
  children?: React.ReactElement;
}

function refreshKeycloakToken(dispatch: AppDispatch) {
  keycloakInstance.onTokenExpired = () => {
    return keycloakInstance.updateToken().then(refreshed => {
      if (refreshed && keycloakInstance.token != null && keycloakInstance.refreshToken != null) {
        setToken(dispatch, keycloakInstance.token, keycloakInstance.refreshToken);
      }
    }).catch(() => {
      console.error('Please contact support if this issue persists');
    });
  }
}

function keepTokenAlive(dispatch: AppDispatch) {
  refreshKeycloakToken(dispatch);
  setInterval(() => {
    refreshKeycloakToken(dispatch);
  }, 1000 * 30);
}

export const KeycloakProvider: FC<KeycloakProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);

  useEffect(() => {
    if (keycloakInstance.authenticated === true) {
      keepTokenAlive(dispatch);
      return;
    };
    if (keycloakInstance.authenticated === false) {
      refreshKeycloakToken(dispatch);
      keepTokenAlive(dispatch);
      return;
    }
    keycloakInstance.init({ onLoad: 'login-required' }).then((authenticated) => {
      if (authenticated == null || keycloakInstance.token == null || keycloakInstance.refreshToken == null) {
        return window.location.reload();
      }
      dispatch(AuthActions.setStatus("success"))
      setToken(dispatch, keycloakInstance.token, keycloakInstance.refreshToken);
      keepTokenAlive(dispatch);
    }).catch((e) => {
      dispatch(AuthActions.setStatus("failed"))
      console.error("failed", e);
    });
  }, [dispatch]);

  useEffect(() => {
    if (auth.status === "logging-out") {
      keycloakInstance.logout({
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
      }).then(() => {
        keycloakInstance.clearToken(); 
          dispatch(AuthActions.setStatus("pending"));
      });
    } 
  }, [auth.status, dispatch]);

  if (auth.status === "failed") {
    return <div className="flex h-[100vh] w-[100vw] justify-center items-center">
      Unauthorized
    </div>
  }

  if (auth.status === "pending") {
    return <LoadingPage />
  }

  return <>
    {children}
  </>;
}
