import { map } from "lodash";
import { FC, useEffect, useRef } from "react";
import MonacoEditor, { EditorDidMount, monaco } from 'react-monaco-editor';

const HOOKS_HANDLEBAR_ID = 'hooks-handlebars';

monaco.languages.register({ id: HOOKS_HANDLEBAR_ID });

monaco.languages.setMonarchTokensProvider(HOOKS_HANDLEBAR_ID, {
  tokenizer: {
    root: [
      [/\{\{[#/]?[^}]+\}\}/, 'variable'],
    ]
  }
});

monaco.languages.setLanguageConfiguration(HOOKS_HANDLEBAR_ID, {
  brackets: [
    ['{{', '}}'],
  ],
  autoClosingPairs: [
    { open: '{{', close: '}}' },
  ],
  surroundingPairs: [
    { open: '{{', close: '}}' },
  ]
});

export type ICodeEditorSuggestion = {
    label: string;
    documentation?: string;
}

function registerSuggestions(getCodeEditorSuggestions: () => ICodeEditorSuggestion[]) {
    return monaco.languages.registerCompletionItemProvider(HOOKS_HANDLEBAR_ID, {
        triggerCharacters: ["{"],
        provideCompletionItems: (model, position) => {
            const textUntilPosition = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: 1,
              endLineNumber: position.lineNumber,
              endColumn: position.column
          });
    
          const range = {
            startLineNumber: position.lineNumber,
            startColumn: textUntilPosition.lastIndexOf('{{') + 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          };
    
          return {
            suggestions: map(getCodeEditorSuggestions(), suggestion => ({
                label: suggestion.label,
                kind: monaco.languages.CompletionItemKind.Variable,
                insertText: suggestion.label,
                insertTextRules: monaco.languages.CompletionItemInsertTextRule.None,
                documentation: suggestion.documentation ?? "",
                range,
            })),
          }
        }
    });
}

type ICodeEditorProps = {
    value: string;
    setValue: (value: string) => void;
    language: "hooks-handlebars" | "dockerfile";
    options?: monaco.editor.IStandaloneEditorConstructionOptions;
    suggestions?: ICodeEditorSuggestion[];
    onSuggestionHover?: (suggestion: ICodeEditorSuggestion, enter: boolean) => void;
}

export const CodeEditor: FC<ICodeEditorProps> = ({ value, setValue, language, options = {}, suggestions, onSuggestionHover }) => {
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
    const widgets = useRef<any[]>([]);
    const getSuggestionsRef = useRef<() => ICodeEditorSuggestion[]>(() => []);

    useEffect(() => {
      if (editorRef.current == null) {
        return;
      }
      let suggestionDisposable: { dispose: () => void };
      const focusDisposable = editorRef.current.onDidFocusEditorText(() => {
        suggestionDisposable = registerSuggestions(getSuggestionsRef.current);
      });
      const blurDisposable = editorRef.current.onDidBlurEditorText(() => {
        suggestionDisposable?.dispose();
      }, []);
      let timeout: NodeJS.Timeout;
      let callback: () => void;
      const keyboardDisposable = editorRef.current.onKeyDown((e) => {
        if (e.keyCode !== monaco.KeyCode.UpArrow && e.keyCode !== monaco.KeyCode.DownArrow) {
          return;
        }
        const suggestionDropdown = document.querySelector("div.editor-widget.suggest-widget.visible");
        if (suggestionDropdown == null) {
          return;
        }
        const label = suggestionDropdown.querySelector("div.monaco-list-row.focused")?.textContent;
        if (label == null) {
          return;
        }
        if (timeout != null) {
          callback?.();
          clearTimeout(timeout);
        }
        onSuggestionHover?.({
          label,
        }, true);
        callback = () => {
          onSuggestionHover?.({
            label,
          }, false);
        };
        timeout = setTimeout(callback, 3000);
      });
      return () => {
        focusDisposable.dispose();
        blurDisposable.dispose();
        keyboardDisposable.dispose();
      }
    }, [onSuggestionHover]);

    useEffect(() => {
      getSuggestionsRef.current = () => (suggestions ?? []);
  }, [suggestions]);

    const editorDidMount: EditorDidMount = (editor, monaco) => {
      editorRef.current = editor;
      updateWidgets();
      editor.onDidChangeModelContent(updateWidgets);
    };
  
    const updateWidgets = () => {
      const editor = editorRef.current;
      if (editor == null) {
        return;
      }
      const model = editor.getModel();
      if (model == null) {
        return;
      }
      const regex = /\{\{([^\s\n\r}]+)\}\}/g;
      const text = model.getValue();
      let match;
      widgets.current.forEach(widget => editor.removeContentWidget(widget));
      widgets.current = [];
  
      while ((match = regex.exec(text)) !== null) {
        const start = model.getPositionAt(match.index);
        const end = model.getPositionAt(match.index + match[0].length);
        const range = new monaco.Range(start.lineNumber, start.column, end.lineNumber, end.column);
  
        const widget = createContentWidget(editor, range, match[1].trim());
        widgets.current.push(widget);
        editor.addContentWidget(widget);
      }
    };
  
    const createContentWidget = (editor: monaco.editor.IStandaloneCodeEditor, range: monaco.Range, content: string) => {
      const domNode = document.createElement('div');
      domNode.className = 'handlebars-widget bg-teal-500 text-white rounded-xl px-[14px] inline-block cursor-pointer whitespace-nowrap transition-all';
      domNode.textContent = content.replace(/\n/g, ' ');
      domNode.onclick = () => {
        domNode.style.display = "none";
        setTimeout(() => {
          domNode.style.display = "flex";
        }, 5000);
      };
      domNode.onmouseenter = () => {
        domNode.style.scale = "1.02";
        onSuggestionHover?.({
          label: content,
        }, true);
      }
      domNode.onmouseleave = () => {
        domNode.style.scale = "1.0";
        onSuggestionHover?.({
          label: content,
        }, false);
      }
 
      return {
        getId: () => `handlebars-widget-${range.startLineNumber}-${range.startColumn}`,
        getDomNode: () => domNode,
        getPosition: () => {
          return {
            position: {
              lineNumber: range.startLineNumber,
              column: range.startColumn
            },
            preference: [monaco.editor.ContentWidgetPositionPreference.EXACT],
          };
        }
      };
    };

    return (
        <MonacoEditor
          className="nodrag"
          language={language}
          value={value}
          onChange={setValue}
          options={{
              fontSize: 12,
              glyphMargin: false,
              minimap: {
                  enabled: false,
              },
              lineNumbers: "off",
              ...options,
          }}
          editorDidMount={editorDidMount}
        />
    );
}