import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";
import { FC, cloneElement, useMemo, useState } from "react";
import { Link } from "react-router-dom";

type IRoute = {
    icon?: React.ReactElement;
    name: string;
    path: string;
}

type IRouteProps = {
    title: string;
    icon: React.ReactElement;
    defaultPath?: string;
    routes?: IRoute[];
    collapse?: boolean;
};

export const SideMenu: FC<IRouteProps> = (props) => {
    const [hover, setHover] = useState(false);
    const status = hover ? "show" : "hide";

    const handleMouseEnter = useMemo(() => {
        return debounce(() => setHover(true));
    }, []);

    const handleMouseLeave = useMemo(() => {
        return debounce(() => setHover(false));
    }, []);

    return <div className={classNames("flex items-center", {
        "justify-center": props.collapse,
    })}  onMouseEnter={handleMouseEnter} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <AnimatePresence mode="wait">
            <div className="cursor-default text-md inline-flex gap-1 transition-all hover:gap-2 relative w-full py-4 rounded-md pl-2 hover:bg-gray-100">
                {cloneElement(props.icon, {
                    className: classNames("transition-all", {
                        "w-4 h-4": !props.collapse,
                        "w-6 h-6 hover:scale-110 ml-1": props.collapse,
                        "cursor-pointer": props.defaultPath != null,
                    })
                })}
                {
                    props.collapse
                    ? null
                    : props.defaultPath == null
                        ? props.title
                        : <Link to={props.defaultPath}>
                            {props.title}
                        </Link>
                }
                {
                    props.routes != null &&
                    <motion.div className="absolute z-40 divide-y rounded-lg shadow-lg min-w-[250px] bg-white left-[100%] -top-[20px] border border-gray-200" variants={{
                        hide: {
                            scale: 0.9,
                            opacity: 0,
                            x: 10,
                            transition: {
                                duration: 0.1,
                            },
                            transitionEnd: {
                                display: "none",
                            }
                        },
                        show: {
                            scale: 1,
                            opacity: 100,
                            x: 0,
                            display: "flex",
                        }
                    }} initial={status} animate={status}>
                        <ul className="py-2 px-2 text-sm flex flex-col justify-center w-full">
                            {props.routes.map(route => (
                                <Link className="flex items-center gap-1 transition-all hover:gap-2 hover:bg-gray-100 w-full rounded-md pl-2 py-2" key={route.path} to={route.path}>
                                    {route.icon && cloneElement(route.icon, {
                                        className: "w-4 h-4"
                                    })}
                                    {route.name}
                                </Link>
                            ))}
                        </ul>
                    </motion.div>
                }
            </div>
        </AnimatePresence>
    </div>
}