import { FC } from "react";
import { CreateUserCard, IUser, UserCard } from "./user-card";
import { loader } from "graphql.macro";
import { useAppQuery } from "../../api/query";
import { Page } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";

const getAllUsersQuery = loader("./get-user.graphql");

export const UserPage: FC = () => {
  const getAllUsersQueryResponse = useAppQuery(getAllUsersQuery);
  return <Page routes={[InternalRoutes.Admin.User]}>
    <CreateUserCard refetch={getAllUsersQueryResponse.refetch} />
    {
      getAllUsersQueryResponse.data?.User.map((user: IUser) => (
        <UserCard key={user.Id} user={user} refetch={getAllUsersQueryResponse.refetch} />
      ))
    }
  </Page>
}