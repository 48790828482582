import { forEach, map } from "lodash";
import { FC, useMemo } from "react";
import { CartesianGrid, XAxis, Area, Legend, AreaChart as ReCharts, ResponsiveContainer, Tooltip, YAxis } from 'recharts';

const lineColors = ["#EFAA97", "#E5BC88"];

export type ILineChartData = {
  x: Date[];
  y: { [id: string]: number }[];
}

type ILineChartProps = ILineChartData;

function formatTime(date: Date) {
  const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  const seconds = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
  return hours + ":" + minutes + ":" + seconds;
}

export const LineChart: FC<ILineChartProps> = (props) => {
  const { data, domain } = useMemo(() => {
    if (props.y.length === 0) {
      return {
        data: [], 
        domain: [0,0]
      };
    }
    let maxX = 0;
    let minX = 0
    const newData: Record<string, string | number>[] = map(props.y, (datum, i) => {
      const newDatum: Record<string, string | number> = {};
      forEach(datum, (value, key) => {
        if (maxX < value) {
          maxX = value;
        }
        if (minX > value) {
          minX = value;
        }
        newDatum[key] = value;
      });
      newDatum.x = formatTime(props.x[i])
      return newDatum;
    });
    return { data: newData.slice(Math.max(newData.length - 20, 0)), domain: [minX, maxX] };
  }, [props.x, props.y]);

  const fields = props.y.length > 0 ? Object.keys(props.y[0]) : [];

  return <ResponsiveContainer className="w-full h-full">
    <ReCharts data={data} margin={{
      left: -20,
    }}>
      <CartesianGrid strokeDasharray="4" stroke="#e4e4e4" vertical={false} opacity={0.50} />
      <XAxis
        dataKey="x"
        interval={0}
        allowDuplicatedCategory={false}
        axisLine={true}
        tickLine={true}
        hide={true}
      />
      <YAxis
        stroke="#555"
        axisLine={false}
        tickLine={false}
        hide={true}
        domain={[ domain[0], domain[1] + 20 ]}
      />
      <Tooltip wrapperClassName="rounded-xl shadow-xl" />
      { fields.length > 1 &&  <Legend wrapperStyle={{
        fontSize: "11px",
        paddingTop: "10px",
      }}  /> }
      {
        fields.map((field, i) => (
          <Area type="monotone" dataKey={field} stroke={lineColors[i]} activeDot={{ r: 2 }} strokeWidth={8} fill={lineColors[i]} opacity={0.20} />
        ))
      }
    </ReCharts>
  </ResponsiveContainer>
}