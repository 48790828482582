import { FC, useCallback, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { ToggleButton } from "../../components/button";
import { CreateCard } from "../../components/card";
import { Page } from "../../components/container";
import { PanelSelector } from "../../components/selector";
import { GraphElements } from "../../config/constants";
import { Icons } from "../../config/icons";
import { IDashboardElement } from "./context";
import { AddDashboardElementForm } from "./dashboard-card";
import { GraphView } from "./graph";
import { QuickContainerDashboardCard } from "../deploy/quick-container/quick-container-card";
import { RegistryImageDashboardCard } from "../container/registry-image/registry-image-card";
import { RegistryDashboardCard } from "../container/registry/registry-card";
import { HookDashboardCard } from "../ci-cd/hooks/hooks-card";
import { FlowDashboardCard } from "../ci-cd/flow/flow-card";
import { DockerIORegistryDashboardCard } from "../integrations/docker-io-integrations/docker-io-integrations-card";
import { GitHubIntegrationDashboardCard } from "../integrations/github-integrations/github-integrations-card";
import { DomainDashboardCard } from "../config/domain/domain-card";
import { DigitalOceanIntegrationDashboardCard } from "../integrations/digital-ocean-integrations/digital-ocean-integrations-card";

export const ADD_DASHBOARD_ICON = {
    component: Icons.Add,
    bgClassName: "bg-teal-500",
};

enum View {
    GRAPH = "GRAPH",
    DASHBOARD = "DASHBOARD",
}

export const DashboardPage: FC = () => {
    const [dashboardElements, setDashboardElements] = useImmer<IDashboardElement[]>([]);
    const [viewId, setViewId] = useState(View.GRAPH);
    const ref = useRef<Function>();

    const handleAddDashboardElement = useCallback((_id: string, _type: GraphElements, element?: IDashboardElement) => {
        if (element == null) {
            return;
        }
        setDashboardElements(elements => {
            elements.push(element);
        });
    }, [setDashboardElements]);

    return <Page className="relative" flag={viewId === View.DASHBOARD ? "Under development" : undefined}>
        <PanelSelector selectedPanelId={viewId} panels={[
            {
                id: View.DASHBOARD,
                component: <div className="flex grow gap-2 flex-wrap content-start">
                    <CreateCard label="Dashboard" actionLabel="Add" icon={ADD_DASHBOARD_ICON} setToggleCallback={(toggle) => ref.current = toggle}>
                        <AddDashboardElementForm
                            isDashboard={true}
                            addDashboardElement={handleAddDashboardElement}
                            onCancel={() => ref.current?.(false)} />
                    </CreateCard>
                    {
                        dashboardElements.map(element => {
                            if (element.type === GraphElements.QuickContainer) {
                                return <QuickContainerDashboardCard quickContainer={element} />
                            } else if (element.type === GraphElements.RegistryImage) {
                                return <RegistryImageDashboardCard registryImage={element} />
                            } else if (element.type === GraphElements.Registry) {
                                return <RegistryDashboardCard registry={element} />
                            } else if (element.type === GraphElements.Hook) {
                                return <HookDashboardCard hook={element} />
                            } else if (element.type === GraphElements.Flow) {
                                return <FlowDashboardCard flow={element} />
                            } else if (element.type === GraphElements.Domain) {
                                return <DomainDashboardCard domain={element} />
                            } else if (element.type === GraphElements.DockerIORegistryIntegration) {
                                return <DockerIORegistryDashboardCard dockerIORegistry={element} />
                            } else if (element.type === GraphElements.GitHubIntegration) {
                                return <GitHubIntegrationDashboardCard gitHubIntegration={element} />
                            } else if (element.type === GraphElements.DigitalOceanIntegration) {
                                return <DigitalOceanIntegrationDashboardCard digitalOcean={element} />
                            }
                            return <></>
                        })
                    }
                </div>
            },
            {
                id: View.GRAPH,
                component: <GraphView />,
            }
        ]} />
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 opacity-25 transition-all hover:opacity-100">
            <ToggleButton checked={viewId === View.GRAPH} label="Graph view" onClick={() => setViewId(v => v === View.DASHBOARD ? View.GRAPH : View.DASHBOARD)} />
        </div>
    </Page>
}