import { DocumentNode, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { forEach } from "lodash";
import { FC, MouseEventHandler, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { ActionButton } from "../../components/button";
import { Card } from "../../components/card";
import { Loading } from "../../components/loading";
import { GraphElements } from "../../config/constants";
import { Icons } from "../../config/icons";
import { notify } from "../../store/function";
import { GraphViewContext } from "./context";
import { IGraphCardProps } from "../../components/graph/graph";
import { InternalRoutes } from "../../config/internal-routes";
import { useNavigate } from "react-router-dom";
import { createRedirectLink } from "../../utils/functions";

const POSITION_OFFSET = 200;

type IGraphExplorer = {
    id: string;
    nodeType: string;
    children: ReactNode;
}

type IGraphNode = {
    Id: string;
    Type: GraphElements;
    Dependents: IGraphNode[];
    References: IGraphNode[];
}

export const getGraphNodeReferences = loader("./get-graph-node-references.graphql");
export const getGraphNodeDependents = loader("./get-graph-node-dependents.graphql");

export const GraphExplorer: FC<IGraphExplorer> = ({ children, id, nodeType }) => {
    const { addDashboardElement, addEdge } = useContext(GraphViewContext);
    const [getReferences,] = useLazyQuery(getGraphNodeReferences);
    const [getDependents,] = useLazyQuery(getGraphNodeDependents);
    const ref = useRef<HTMLDivElement>(null);
    const [hoverDirection, setHoverDirection] = useState<"left" | "right" | "none">("none");
    const { getNode } = useReactFlow();
    const navigate = useNavigate();

    const handleReferences = useCallback(() => {
        getReferences({
            variables: {
                id,
                nodeType,
            },
            onCompleted(data: { GraphNodeReferences: IGraphNode[] }) {
                const currentNodeState = getNode(id);
                const position = currentNodeState != null ? {
                    x: currentNodeState.position.x - POSITION_OFFSET,
                    y: currentNodeState.position.y,
                } : { x: 0, y: 0};
                if (data.GraphNodeReferences.length === 0) {
                    return notify("No references found");
                }
                const offset = 250*(data.GraphNodeReferences.length-1)/2;
                forEach(data.GraphNodeReferences, (graphNode, index) => {
                    const nodePosition = {...position};
                    nodePosition.y += 250*index - offset;
                    addDashboardElement(graphNode.Id, graphNode.Type, undefined, nodePosition);
                    addEdge(graphNode.Id, id);
                });
            },
        });
    }, [id, nodeType, getReferences, addEdge, addDashboardElement, getNode]);

    const handleDependents = useCallback(() => {
        getDependents({
            variables: {
                id,
                nodeType,
            },
            onCompleted(data: { GraphNodeDependents: IGraphNode[] }) {
                const currentNodeState = getNode(id);
                const position = currentNodeState != null ? {
                    x: currentNodeState.position.x + (2*POSITION_OFFSET),
                    y: currentNodeState.position.y,
                } : { x: 0, y: 0};
                if (data.GraphNodeDependents.length === 0) {
                    return notify("No dependents found");
                }
                const offset = 250*(data.GraphNodeDependents.length-1)/2;
                forEach(data.GraphNodeDependents, (graphNode, index) => {
                    const nodePosition = {...position};
                    nodePosition.y += 250*index - offset;
                    addDashboardElement(graphNode.Id, graphNode.Type, undefined, nodePosition);
                    addEdge(id, graphNode.Id);
                });
            },
        });
    }, [id, nodeType, getDependents, addEdge, addDashboardElement, getNode]);

    const handleMouseMove: MouseEventHandler = useCallback((event) => {
        if (ref.current == null) {
            return;
        }
        const hoverPosition = event.clientX - ref.current.getBoundingClientRect().left;
        setHoverDirection(hoverPosition < ref.current.offsetWidth / 2 ? 'left' : 'right');
    }, [ref]);

    const handleMouseLeave = useCallback(() => {
        setHoverDirection("none");
    }, []);

    const handleGoTo = useCallback(() => {
        let link: string;
        switch (nodeType) {
            case GraphElements.Registry:
                link = InternalRoutes.Container.Registry.path;
                break;
            case GraphElements.RegistryImage:
                link = InternalRoutes.Container.Image.path;
                break;
            case GraphElements.Flow:
                link = InternalRoutes.CI_CD.Flow.path;
                break;
            case GraphElements.Hook:
                link = InternalRoutes.CI_CD.Hooks.path;
                break;
            case GraphElements.QuickContainer:
                link = InternalRoutes.Deployment.QuickContainer.path;
                break;
            case GraphElements.Domain:
                link = InternalRoutes.Config.Domain.path;
                break;
            case GraphElements.Cluster:
                link = InternalRoutes.Deployment.Cluster.path;
                break;
            case GraphElements.EnvironmentVariable:
                link = InternalRoutes.Config.EnvironmentVariable.path;
                break;
            default:
                link = InternalRoutes.Integrations.Integrations.path;
                break;
        }
        navigate(createRedirectLink(link, id));
    }, [id, navigate, nodeType]);

    return <div ref={ref} className="relative group/graphcard" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
        <div className={classNames("absolute top-1/2 -translate-y-1/2 -left-14 opacity-0 transition-all", {
            "opacity-100": hoverDirection === "left",
        })}>
            <ActionButton icon={Icons.Expand} onClick={handleReferences} />
        </div>
        {children}
        <div className="absolute -top-4 -right-4 opacity-0 transition-all group-hover/graphcard:opacity-100">
            <ActionButton icon={Icons.RightArrowUp} onClick={handleGoTo} />
        </div>
        <div className={classNames("absolute top-1/2 -translate-y-1/2 -right-14 opacity-0 transition-all", {
            "opacity-100": hoverDirection === "right",
        })}>
            <ActionButton icon={Icons.Expand} onClick={handleDependents} />
        </div>
    </div>
}


type IGraphBaseCardProps<T extends unknown = any> = {
    children: ReactElement;
} & IGraphCardProps<T>;

export const GraphBaseCard: FC<IGraphBaseCardProps> = (props) => {
    const { selectedNodes } = useContext(GraphViewContext);
    const selected = props.id in selectedNodes;

    return <div className={classNames("rounded-[26px] border-2", {
        "border-gray-500": selected,
        "border-transparent": !selected,
    })}>
        <Handle type="target" position={Position.Left} />
        {props.children}
        <Handle type="source" position={Position.Right} />
    </div>
}

export type IGraphCardLoaderProps<T extends unknown = any, V extends unknown = any> = {
    loader: DocumentNode;
    transform: (data: T) => V;
    children: (data: V) => ReactElement;
} & IGraphCardProps<V>;

export const GraphCardLoader: FC<IGraphCardLoaderProps> = (props) => {
    const [data, setData] = useState<typeof props.data>(props.data);
    const [query, { error }] = useLazyQuery(props.loader, {
        variables: {
            id: props.id,
        },
    });

    useEffect(() => {
        if (data != null || error != null) {
            return;
        }
        query({
            onCompleted(data) {
                const transformedData = props.transform(data);
                setData(transformedData);
            },
            onError() {
                notify("Issue getting information about the graph node", "error");
            },
        });
    }, [props, data, error, query]);

    const children = useMemo(() => {
        return data == null
            ? <Card icon={{
                component: Icons.Fetch,
                bgClassName: "bg-green-500",
            }}>
                <Loading hideText={true} />
            </Card>
            : <GraphExplorer id={props.id} nodeType={props.type}>
                {props.children(data)}
            </GraphExplorer>
    }, [data, props]);

    return <>
        <GraphBaseCard {...props}>
            {children}
        </GraphBaseCard>
    </>
}


