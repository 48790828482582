import { FC, cloneElement } from "react";
import { Icons } from "../../config/icons";
import classNames from "classnames";
import { ELLIPSES_CLASS } from "../classes";
import { twMerge } from "tailwind-merge";

type IPrivateTagProps<T extends unknown = any>  = {
    data?: T;
    size?: "small" | "normal";
}

export const PrivateTag: FC<IPrivateTagProps> = ({ data, size = "normal" }) => {
    if (data != null) {
        return <span className={twMerge(ELLIPSES_CLASS, "max-w-[120px]")}>{data}</span>;
    }
    return <div className="flex items-center">
        {cloneElement(Icons.Lock, {
            className: classNames("stroke-orange-800",{
                "w-6 h-6": size === "normal",
                "w-4 h-4": size === "small",
            })
        })}
        <div className={classNames("text-orange-800", {
            "text-xs": size === "small",
            "text-sm": size === "normal",
        })}>Private</div>
    </div>
}