import { createContext } from "react";
import { XYPosition } from "reactflow";
import { GraphElements } from "../../config/constants";
import { IRegistryImage } from "../container/registry-image/registry-image-card";
import { IRegistry } from "../container/registry/registry-card";
import { IQuickContainer } from "../deploy/quick-container/quick-container-card";
import { IDockerIORegistry } from "../integrations/docker-io-integrations/docker-io-integrations-card";
import { IHook } from "../ci-cd/hooks/hooks-card";
import { IFlow } from "../ci-cd/flow/flow-card";
import { IGitHubIntegration } from "../integrations/github-integrations/github-integrations-card";
import { IDomain } from "../config/domain/domain-card";
import { IDigitalOceanIntegration } from "../integrations/digital-ocean-integrations/digital-ocean-integrations-card";

export type IDataTransform<T extends unknown = any, V extends unknown = any> = (data: V) => T[]

export type IDashboardElement = ({ type: GraphElements.QuickContainer } & IQuickContainer) |
    ({ type: GraphElements.Registry } & IRegistry) |
    ({ type: GraphElements.RegistryImage } & IRegistryImage) |
    ({ type: GraphElements.DockerIORegistryIntegration } & IDockerIORegistry) |
    ({ type: GraphElements.Hook } & IHook) |
    ({ type: GraphElements.Flow } & IFlow) |
    ({ type: GraphElements.Domain } & IDomain) |
    ({ type: GraphElements.GitHubIntegration } & IGitHubIntegration) |
    ({ type: GraphElements.DigitalOceanIntegration } & IDigitalOceanIntegration);


export type IAddDashboardElementGraphView = (id: string, type: GraphElements, data?: IDashboardElement, position?: XYPosition) => void;

type IGraphViewContext = {
    addDashboardElement: IAddDashboardElementGraphView;
    removeDashboardElement: (id: string) => void;
    addEdge: (fromId: string, toId: string) => void;
    selectedNodes: Record<string, boolean>;
}

export const GraphViewContext = createContext<IGraphViewContext>({} as IGraphViewContext);
