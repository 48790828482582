import { DocumentNode } from "graphql";
import { find, lowerCase, map } from "lodash";
import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppQuery } from "../../api/query";
import { AnimatedButton } from "../../components/button";
import { IDropdownItem } from "../../components/dropdown";
import { Search } from "../../components/search";
import { PanelSelector } from "../../components/selector";
import { GraphElements } from "../../config/constants";
import { Icons } from "../../config/icons";
import { AddFlowDashboardCard } from "../ci-cd/flow/flow-card";
import { AddHookDashboardCard } from "../ci-cd/hooks/hooks-card";
import { AddDomainDashboardCard } from "../config/domain/domain-card";
import { AddEnvironmentVariableDashboardCard } from "../config/environment-variable/environment-variable-card";
import { AddRegistryImageDashboardCard } from "../container/registry-image/registry-image-card";
import { AddRegistryDashboardCard } from "../container/registry/registry-card";
import { AddClusterDashboardCard } from "../deploy/cluster/cluster-card";
import { AddQuickContainerDashboardCard } from "../deploy/quick-container/quick-container-card";
import { AddDigitalOceanIntegrationDashboardCard } from "../integrations/digital-ocean-integrations/digital-ocean-integrations-card";
import { AddDockerIORegistryDashboardCard } from "../integrations/docker-io-integrations/docker-io-integrations-card";
import { AddGitHubIntegrationDashboardCard } from "../integrations/github-integrations/github-integrations-card";
import { IAddDashboardElementGraphView, IDataTransform } from "./context";

const dashboardElements: IDropdownItem[] = [
    // {
    //     id: DashboardElements.GitHubPullRequests,
    //     label: "GitHub Pull Requests",
    //     icon: Icons.Logos.GitHub,
    // }
];

const graphElements: IDropdownItem[] = [
    {
        id: GraphElements.QuickContainer,
        label: "Quick container",
        icon: Icons.Deploy.QuickContainer.Default,
    },
    {
        id: GraphElements.Registry,
        label: "Registry",
        icon: Icons.Container.Registry.Default,
    },
    {
        id: GraphElements.RegistryImage,
        label: "Registry image",
        icon: Icons.Container.Image.Default,
    },
    {
        id: GraphElements.Flow,
        label: "Flows",
        icon: Icons.CI_CD.Flow.Default,
    },
    {
        id: GraphElements.Hook,
        label: "Hooks",
        icon: Icons.CI_CD.Hooks.Default,
    },
    {
        id: GraphElements.Domain,
        label: "Domains",
        icon: Icons.Config.Domain.Default,
    },
    {
        id: GraphElements.Cluster,
        label: "Clusters",
        icon: Icons.Deploy.Cluster.Default,
    },
    {
        id: GraphElements.EnvironmentVariable,
        label: "Environment variables",
        icon: Icons.Config.EnvironmentVariable.Default,
    },
    {
        id: GraphElements.DockerIORegistryIntegration,
        label: "Docker IO Integrations",
        icon: Icons.Logos.DockerIO,
    },
    {
        id: GraphElements.GitHubIntegration,
        label: "GitHub Integrations",
        icon: Icons.Logos.GitHub,
    },
    {
        id: GraphElements.DigitalOceanIntegration,
        label: "Digital Ocean",
        icon: Icons.Logos.DigitalOcean,
    },
];

type IAddDashboardElementProps = {
    label: string;
    query: DocumentNode;
    transform: (data: any) => IDropdownItem[];
    onCancel: () => void;
    onComplete: (id: string, data: any) => void;
    link: string;
}

const AddDashboardElement: FC<IAddDashboardElementProps> = ({ label, query, transform, onCancel, onComplete, link }) => {
    const navigate = useNavigate();
    const queryResponse = useAppQuery(query);

    const items = queryResponse.data != null ? transform(queryResponse.data) : [];
    const lowerCaseLabel = lowerCase(label);

    const handleAdd = useCallback((item: IDropdownItem) => {
        onComplete(item.id, queryResponse.data);
    }, [onComplete, queryResponse.data]);

    const handleDefaultClick = useCallback(() => {
        navigate(link);
    }, [link, navigate]);

    return (<div className="flex flex-col grow my-4">
        <div className="text-md my-2">Select {lowerCaseLabel}</div>
        <Search label="Search your integration" className="flex-grow" items={items} isOpen={true} onSelect={handleAdd} noItemsLabel={`No ${lowerCaseLabel} found`}
            actionIcon={Icons.Add} actionLabel="Add" dropdownProps={{
                noItemsLabel: `No ${label} found`,
                defaultItem: {
                    label: `Create ${label}`,
                    icon: Icons.Add,
                },
                onDefaultItemClick: handleDefaultClick,
            }} />
        <div className="flex justify-end">
            <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={onCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
        </div>
    </div>)
}


export enum DashboardPanels {
    STARTING = "STARTING",
}

type ICreateDashboardFormProps = {
    addDashboardElement: IAddDashboardElementGraphView;
    onCancel?: () => void;
    isDashboard?: boolean;
}

export type IAddDashboardCardProps = {
    handleOnCancel: () => void;
    setSelectedPanelId: (panelId: string) => void;
} & ICreateDashboardFormProps;

export const AddDashboardCard: FC<IAddDashboardCardProps & {
    label: string;
    type: GraphElements;
    query: DocumentNode;
    transform: IDataTransform;
    icon: ReactElement;
    link: string;
}> = (props) => {
    const handleOnComplete = useCallback((id: string, data: any) => {
        const item = find(props.transform(data), ({ Id }) => Id === id);
        if (item == null) {
            return;
        }
        const type = props.type;
        props.addDashboardElement(item.Id, type, {
            type,
            ...item,
        });
        props.setSelectedPanelId(DashboardPanels.STARTING);
    }, [props]);
    return (<AddDashboardElement
        label={props.label}
        onComplete={handleOnComplete}
        onCancel={props.handleOnCancel}
        query={props.query}
        link={props.link}
        transform={data => map(props.transform(data), ({ Id, Name }) => ({ id: Id, label: Name, icon: props.icon }))} />)
}

export const AddDashboardElementForm: FC<ICreateDashboardFormProps> = (props) => {
    const [selectedPanelId, setSelectedPanelId] = useState<string>(DashboardPanels.STARTING);
    
    const elements = useMemo(() => {
        if (props.isDashboard) {
            return [...graphElements, ...dashboardElements];
        }
        return graphElements;
    }, [props.isDashboard]);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setSelectedPanelId(item.id)
    }, []);

    const handleOnCancel = useCallback(() => {
        setSelectedPanelId(DashboardPanels.STARTING);
        props.onCancel?.();
    }, [props]);


    return (<PanelSelector selectedPanelId={selectedPanelId} panels={[
        {
            id: DashboardPanels.STARTING,
            component: <div className="flex flex-col gap-1 my-4 grow">
                <div className="text-md my-2">Add an element to Space</div>
                <Search label="Search your element" className="flex-grow" items={elements} isOpen={true} onSelect={handleSelect} noItemsLabel="No elements found"
                        dropdownProps={{
                            scrollContainerClassName: "max-h-32",
                        }} />
                {
                    props.onCancel != null &&
                    <div className="flex justify-end">
                        <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={props.onCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    </div>
                }
            </div>,
        },
        {
            id: GraphElements.QuickContainer,
            component: <AddQuickContainerDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.RegistryImage,
            component: <AddRegistryImageDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.Registry,
            component: <AddRegistryDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.DockerIORegistryIntegration,
            component: <AddDockerIORegistryDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.Hook,
            component: <AddHookDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.Domain,
            component: <AddDomainDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.Cluster,
            component: <AddClusterDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.Flow,
            component: <AddFlowDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.EnvironmentVariable,
            component: <AddEnvironmentVariableDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.GitHubIntegration,
            component: <AddGitHubIntegrationDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
        {
            id: GraphElements.DigitalOceanIntegration,
            component: <AddDigitalOceanIntegrationDashboardCard {...props} handleOnCancel={handleOnCancel} setSelectedPanelId={setSelectedPanelId} />
        },
    ]} />)
}