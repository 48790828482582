import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import 'reactflow/dist/style.css';
import { graphqlClient } from './api/client';
import App from './app';
import { KeycloakProvider } from './components/keycloak';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { reduxStore } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={graphqlClient}>
      <Provider store={reduxStore}>
        <KeycloakProvider>
          <Router>
            <App />
          </Router>
        </KeycloakProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
