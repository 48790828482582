import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { map } from "lodash";
import { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useAppQuery } from "../../../api/query";
import { ActionButton, AnimatedButton } from "../../../components/button";
import { IDropdownItem } from "../../../components/dropdown";
import { Loading } from "../../../components/loading";
import { Search } from "../../../components/search";
import { Icons } from "../../../config/icons";
import { notify } from "../../../store/function";
import { getAllQuickContainersQuery } from "./quick-container";
import { IQuickContainer, getQuickContainerIcon, transformQuickContainersData } from "./quick-container-card";
import { Icon } from "../../../components/card";

const getQuickContainersConnectionsQuery = loader("./get-quick-container-incoming-connections.graphql");
const updateQuickContainerConnectionsMutation = loader("./update-quick-container-incoming-connections.graphql");

type IQuickContainerIncomingConnection = {
    QuickContainer: IQuickContainer;
    EnvironmentVariable?: string;
}

type IQuickContainerConnectionsProps = {
    quickContainer: IQuickContainer;
    onCancel?: () => void;
}

export const QuickContainerConnections: FC<IQuickContainerConnectionsProps> = ({ quickContainer, onCancel }) => {
    const [connections, setConnections] = useImmer<IQuickContainerIncomingConnection[]>([]);
    const [searchText, setSearchText] = useState("");
    const {data: quickContainersData} = useAppQuery(getAllQuickContainersQuery);
    const [getConnections, { loading }] = useLazyQuery(getQuickContainersConnectionsQuery);
    const [updateConnections, { loading: updateConnectionsLoading }] = useMutation(updateQuickContainerConnectionsMutation);

    const handleBlur = useCallback((e: FocusEvent<HTMLElement>) => {
        if (e.relatedTarget?.tagName === "BUTTON") {
            return;
        }
        setSearchText("");
    }, []);

    const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        if (e.target.value.length <= 1) {
            return;
        }
    }, []);

    const quickContainers = useMemo(() => {
        if (quickContainersData == null) {
            return [];
        }
        return transformQuickContainersData(quickContainersData);
    }, [quickContainersData]);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setConnections(c => {
            c.push({
                QuickContainer: quickContainers.find((quickContainer: IQuickContainer) => quickContainer.Id === item.id)!,
            });
        });
        setSearchText("");
    }, [quickContainers, setConnections]);

    useEffect(() => {
        getConnections({
            variables: {
                id: quickContainer.Id,
            },
            onCompleted(data) {
                setConnections(data?.QuickContainerIncomingConnections ?? []);
            },
            onError() {
                notify("Unable to get connections", "error");
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdate = useCallback(() => {
        if (updateConnectionsLoading) {
            return;
        }
        updateConnections({
            variables: {
                id: quickContainer.Id,
                connections: map(connections, connection => ({
                    QuickContainerId: connection.QuickContainer.Id,
                    EnvironmentVariable: connection.EnvironmentVariable,
                })),
            },
            onError() {
                notify("Unable to update connections", "error");
            },
            onCompleted() {
                notify("Connections updated successfully", "success");
            },
        });
    }, [connections, quickContainer.Id, updateConnections, updateConnectionsLoading]);

    const handleRemove = useCallback((connection: IQuickContainerIncomingConnection) => {
        setConnections(cs => cs.filter(c => c.QuickContainer.Id !== connection.QuickContainer.Id));
    }, [setConnections]);

    const filterCurrent = useMemo(() => {
        return (qc: IQuickContainer) => qc.Id !== quickContainer.Id && connections.find(c => c.QuickContainer.Id === qc.Id) == null;
    }, [connections, quickContainer.Id]);

    const items: IDropdownItem[] = useMemo(() => {
        if (searchText.length === 0) {
            return quickContainers
            .filter(filterCurrent)
            .map(datum => ({
                id: datum.Id,
                label: datum.Name,
            }));
        }
        const lowerCaseSearch = searchText.toLowerCase();
        return quickContainers
            .filter(filterCurrent)
            .filter((qc: IQuickContainer) => qc.Name.toLowerCase().includes(lowerCaseSearch))
            .map((qc: IQuickContainer) => ({
                id: qc.Id,
                label: qc.Name,
            }));
    }, [filterCurrent, quickContainers, searchText]);

    return <div className="flex flex-col overflow-hidden grow">
    <div className="mt-4">
        Incoming Connections
    </div>
    {
        loading
        ? <Loading />
        : <>
            <Search label="Search quick containers..." controlled={true} items={items} inputProps={{
                value: searchText,
                onChange: handleSearchChange,
                onBlur: handleBlur,
            }} onSelect={handleSelect} noItemsLabel="No quick containers found..." actionLabel="Add" className="mt-2" />
            <div className="overflow-y-auto mb-4 grow">
                <div className="flex flex-col gap-2 mt-2">
                    {connections.map(connection => (
                        <div className="flex items-center justify-between border border-gray-200 rounded-lg shadow-sm p-2">
                            <div className="flex gap-2 items-center">
                                <Icon {...getQuickContainerIcon(connection.QuickContainer)} className="h-4 w-4" bgClassName="h-6 w-6" />
                                <span className="text-gray-700 text-xs">
                                    {connection.QuickContainer.Name}
                                </span>
                            </div>
                            <ActionButton className="w-4 h-4 stroke-red-500" containerClassName="w-6 h-6" icon={Icons.Delete} onClick={() => handleRemove(connection)} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex items-center w-full">
                <div className="flex justify-between items-center w-full">
                    <AnimatedButton
                        label="Cancel"
                        icon={Icons.Cancel}
                        onClick={onCancel}
                    />
                    <AnimatedButton label="Update"
                        icon={Icons.CheckCircle}
                        labelClassName="text-green-800"
                        iconClassName="text-green-600"
                        onClick={handleUpdate} />
                </div>
            </div>
        </>
    }
</div>
}