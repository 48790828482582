import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { keyBy, keys, mapValues, omitBy } from "lodash";
import { FC, ReactElement, useCallback, useState } from "react";
import { useImmer } from "use-immer";
import { AnimatedButton } from "../../../components/button";
import { CheckboxInput, InputWithlabel } from "../../../components/input";
import { Icons } from "../../../config/icons";
import { notify } from "../../../store/function";

const getOAuthUrlMutation = loader("./get-oauth-url.graphql");

export enum OAuthElements {
    GITHUB = "github",
    DIGITAL_OCEAN = "digitalocean",
    GOOGLE = "google",
}

type IOAuthLoginCardProps = {
    name?: string;
    label: string;
    icon: ReactElement;
    element: OAuthElements;
    onCancel: () => void;
    scopes: {
        scope: string;
        description: string;
        checked?: boolean,
    }[];
    hideTitle?: boolean;
    onDelete?: () => void;
    id?: string;
}

export const OAuthLoginCard: FC<IOAuthLoginCardProps> = (props) => {
    const [getOAuthUrl, { loading }] = useMutation(getOAuthUrlMutation);
    const [name, setName] = useState(props.name ?? "");
    const [scopesForm, setScopesForm] = useImmer(mapValues(keyBy(props.scopes.map(scope => ({
        name: scope.scope,
        value: scope.checked ?? false,
    })), "name"), "value"));

    const handleLogin = useCallback(() => {
        if (loading) {
            return;
        }
        const scopes = keys(omitBy(scopesForm, (checked) => !checked));
        getOAuthUrl({
            variables: {
                id: props.id,
                type: props.element,
                name,
                scopes,
            },
            onCompleted(data) {
                window.open(data.GetOAuthUrl.Url, "_self");
            },
            onError() {
                notify("Unable to login currently. Please try again soon.", "error")
            },
        });
    }, [name, scopesForm, getOAuthUrl, loading, props]);

    return <div className="flex flex-col w-full grow">
        <div className="flex flex-col my-2 grow">
            {
                !props.hideTitle &&
                <div className="text-lg my-2 flex gap-1 items-center"><span>{props.icon}</span>{props.label}</div>
            }
            <InputWithlabel label="Name" value={name} setValue={setName} />
            <div className="flex flex-col gap-1 h-[150px] overflow-y-scroll mt-2">
                {
                    props.scopes.map(scope => (
                        <div className="flex flex-row py-2 gap-2 px-1">
                            <CheckboxInput className="mt-1" checked={scopesForm[scope.scope]} onChecked={(checked) => setScopesForm(scopes => {
                                scopes[scope.scope] = checked;
                                return scopes;
                            })} />
                            <div className="flex flex-col">
                                <div className="text-sm font-bold">
                                    {scope.scope}
                                </div>
                                <div className="text-xs">
                                    {scope.description}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className={classNames("flex items-center", {
            "justify-between": props.onDelete != null,
            "justify-end": props.onDelete == null,
        })}>
            {
                props.onDelete != null &&
                <AnimatedButton label="Delete" icon={Icons.Delete} labelClassName="text-red-800" iconClassName="text-red-600" onClick={props.onDelete} />
            }
            <div className="flex items-center gap-2">
                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={props.onCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                <AnimatedButton label={props.id != null ? "Update" : "Login"} icon={Icons.RightArrowUp} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleLogin} />
            </div>
        </div>
    </div>
}