import { FC } from "react";
import { useAppQuery } from "../../../api/query";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateDomainCard, DomainCard, IDomain, getAllDomainsQuery } from "./domain-card";

type IDomainPageProps = {
    isCreating?: boolean;
}

export const DomainPage: FC<IDomainPageProps> = (props) => {
    const getAllDomainsResponse = useAppQuery(getAllDomainsQuery);

    return (
        <Page routes={[InternalRoutes.Config.Domain]}>
            <CreateDomainCard isCreating={props.isCreating} refetch={getAllDomainsResponse.refetch} />
            {
                getAllDomainsResponse.data?.Domain.map((domain: IDomain) => (
                    <DomainCard key={domain.Id} domain={domain} refetch={getAllDomainsResponse.refetch} />
                ))
            }
        </Page>
    )
}