import { useMutation, useSubscription } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { filter, forEach, keys, map, once, times, toString, values } from "lodash";
import { FC, ReactNode, cloneElement, useCallback, useMemo, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useImmer } from "use-immer";
import { useAppQuery } from "../../../api/query";
import { ActionButton, AnimatedButton, AnimatedButtonDropdown, IButtonProps } from "../../../components/button";
import { AdjustableCard, Card, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { ILineChartData, LineChart } from "../../../components/charts/line-chart";
import { ELLIPSES_CLASS, ICON_CLASS } from "../../../components/classes";
import { Dropdown, IDropdownItem } from "../../../components/dropdown";
import { Form, IFormGrid, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Loading } from "../../../components/loading";
import { LogViewer } from "../../../components/log-viewer";
import { PrivateTag } from "../../../components/permissions/private-tag";
import { Pill } from "../../../components/pill";
import { PanelSelector, TabSelector } from "../../../components/selector";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { notify } from "../../../store/function";
import { copyToClipboard, getInstallNamespace, getOptional, isNumeric, toTitleCase } from "../../../utils/functions";
import { IDomain, getAllDomainsQuery, transformDomainData } from "../../config/domain/domain-card";
import { getAllEnvironmentVariablesQuery, transformEnvironmentVariableData } from "../../config/environment-variable/environment-variable-card";
import { getAllRegistryImagesQuery, getDockerRegistryImageTagsQuery, getOneRegistryImageQuery, transformRegistryImageData } from "../../container/registry-image/registry-image-card";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphBaseCard, GraphCardLoader } from "../../dashboard/graph-card";
import { ClusterType, ICluster, getAllClustersQuery, transformClusterData } from "../cluster/cluster-card";
import { getAllQuickContainersQuery } from "./quick-container";
import { QuickContainerConnections } from "./quick-container-connections";

export const DEPLOY_CLIDEY_QUICK_CONTAINER_ICON = {
    component: Icons.Logos.Clidey,
    className: "h-6 w-auto",
    bgClassName: "bg-white",
};


export function getQuickContainerIcon(quickContainer: IQuickContainer) {
    if (quickContainer.Cluster == null) {
        return DEPLOY_CLIDEY_QUICK_CONTAINER_ICON;
    }
    switch (quickContainer.Cluster.Type) {
        case ClusterType.DigitalOcean:
            return {
                ...DEPLOY_CLIDEY_QUICK_CONTAINER_ICON,
                component: Icons.Logos.DigitalOcean,
            };
        case ClusterType.Google:
            return {
                ...DEPLOY_CLIDEY_QUICK_CONTAINER_ICON,
                component: Icons.Logos.Google,
            };
        default:
            return DEPLOY_CLIDEY_QUICK_CONTAINER_ICON;
    }
}


const getOneQuickContainerQuery = loader("./get-one-quick-container.graphql");
export const createQuickContainerMutation = loader("./create-quick-container.graphql");
const updateQuickContainerMutation = loader("./update-quick-container.graphql");
const startQuickContainerMutation = loader("./start-quick-container.graphql");
const stopQuickContainerMutation = loader("./stop-quick-container.graphql");
const restartQuickContainerMutation = loader("./restart-quick-container.graphql");
const deleteQuickContainerMutation = loader("./delete-quick-container.graphql");
const getQuickContainerInstanceLogs = loader("./get-quick-container-instance-logs.graphql");
const getQuickContainerInstances = loader("./get-quick-container-instances.graphql");
const subscribeQuickContainerMetrics = loader("./subscribe-quick-container-metrics.graphql");

enum Panels {
    Details="Details",
    Data="Data",
    Advanced="Advanced",
    Metrics="Metrics",
}

type ICreateQuickContainerCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

const portConfigDropdownItems: IDropdownItem[] = map([80, 443, 22], (port) => ({ id: toString(port), label: toString(port), icon: Icons.Cog }));
const configs = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 4000];
const cpuConfigDropdownItems: IDropdownItem[] = map(configs, (config) => ({ id: toString(config), label: `${config}m`, icon: Icons.Chip }));
const memoryConfigDropdownItems: IDropdownItem[] = map(configs, (config) => ({ id: toString(config), label: `${config}Mi`, icon: Icons.Chip }));
const storageConfigDropdownItems: IDropdownItem[] = map(Array(10), (_, index) => ({ id: toString((index + 1) * 10), label: `${(index + 1) * 10}Gi`, icon: Icons.CircleStacked }));
const instancesConfigDropdownItems: IDropdownItem[] = map(Array(4), (_, index) => ({ id: toString(index + 1), label: toString(index + 1), icon: Icons.SquareStack }));

function getDetailsDefaultForm(navigate: NavigateFunction, quickContainer?: IQuickContainer): IFormGrid {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: quickContainer?.Name,
        },
        {
            name: "imageId",
            label: "Registry Image",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry image is required",
            query: getAllRegistryImagesQuery,
            transform(data, setForm) {
                const dropdownItems = map(transformRegistryImageData(data), ({ Id, Name, Image, Registry }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Image.Default,
                    extra: {
                        image: Image,
                        registryId: Registry.Id,
                    },
                }));
                const foundItem = dropdownItems.find(item => item.id === quickContainer?.Containers[0].RegistryImageId);
                if (foundItem != null) {
                    setForm(f => {
                        f.image = foundItem.extra.image;
                        f.registryId = foundItem.extra.registryId;
                    });
                }
                return dropdownItems;
            },
            defaultIcon: Icons.Container.Image.Default,
            onClick(item, setForm) {
                setForm(f => {
                    f.image = item.extra.image;
                    f.registryId = item.extra.registryId;
                });
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a registry image" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Container.CreateImage.path);
                },
            },
            defaultValue: quickContainer?.Containers[0].RegistryImageId,
        },
        {
            name: "tag",
            label: "Image Tag",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Image tag is required",
            query: getDockerRegistryImageTagsQuery,
            transform(data) {
                return map(data?.DockerRegistryImageTag ?? [], (tag) => ({
                    id: tag,
                    label: tag,
                    icon: Icons.Container.Image.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.registryId == null || form.image == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.registryId,
                    image: form.image,
                }];
            },
            defaultIcon: Icons.Container.Image.Default,
            defaultValue: quickContainer?.Containers[0].Tag,
            disabled: (form) => form.imageId != null && form.imageId === "",
            dropdownProps: {
                noItemsLabel: "No tags found",
            }
        },
        {
            name: "ports",
            label: "Ports",
            type: "dropdown",
            fieldType: "text[]",
            validate: (ports: string[]) => ports.length > 0 && filter(ports, port => !isNumeric(port)).length === 0,
            defaultIcon: Icons.Cog,
            errorMessage: (ports: string[]) => {
                if (ports.length === 0) {
                    return "At least one port is required";
                }
                return "Valid comma separted ports required";
            },
            dropdownProps: {
                items: portConfigDropdownItems,
            },
            defaultValue: map(quickContainer?.Containers[0].Ports ?? [], toString),
        },
    ]
}

function getDataDefaultForm(navigate: NavigateFunction, quickContainer?: IQuickContainer): IFormGrid {
    return [
        {
            name: "environmentVariableId",
            label: "Environment variable",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: getAllEnvironmentVariablesQuery,
            transform(data) {
                return map(transformEnvironmentVariableData(data) ?? [], ({ Id, Name, }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Config.EnvironmentVariable.Default,
                }));
            },
            defaultIcon: Icons.Config.EnvironmentVariable.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an environment variable" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Config.CreatEnvironmentVariable.path);
                },
            },
            defaultValue: quickContainer?.Containers[0].EnvironmentVariableId,
            clearable: true,
        },
        {
            name: "volumes",
            label: "Persistent paths",
            type: "dropdown",
            fieldType: "text[]",
            validate: () => true,
            defaultIcon: Icons.CircleStacked,
            errorMessage: "",
            dropdownProps: {
                isOpen: false,
            },
            defaultValue: quickContainer?.Containers[0].Volumes ?? [],
        },
        {
            name: "size",
            label: "Storage size",
            type: "dropdown",
            fieldType: "text",
            validate: (size: string) => isNumeric(size),
            defaultIcon: Icons.CircleStacked,
            errorMessage: "Storage size has to be a valid number",
            dropdownProps: {
                items: storageConfigDropdownItems,
            },
            defaultValue: quickContainer?.Size?.toString() ?? "10",
            disabled: (form) => form.volumes == null || form.volumes.length === 0,
        },
    ]
}

function getAdvancedDefaultForm(navigate: NavigateFunction, quickContainer?: IQuickContainer): IFormGrid {
    return [
        [
            {
                name: "instances",
                label: "Instances",
                type: "dropdown",
                fieldType: "text",
                validate: () => true,
                defaultIcon: Icons.SquareStack,
                errorMessage: "",
                dropdownProps: {
                    items: instancesConfigDropdownItems,
                },
                defaultValue: quickContainer?.Instances?.toString() ?? "1",
            },
            {
                name: "cpu",
                label: "CPU",
                type: "dropdown",
                fieldType: "text",
                validate: (cpu: string) => cpu.length > 0 && isNumeric(cpu),
                defaultIcon: Icons.Chip,
                errorMessage: (cpu: string) => {
                    if (cpu.length === 0) {
                        return "CPU is required";
                    }
                    return "CPU has to be a valid number";
                },
                dropdownProps: {
                    items: cpuConfigDropdownItems,
                },
                defaultValue: quickContainer?.Containers[0].ContainerResource.LimitsCPU.toString() ?? "100",
            },
            {
                name: "memory",
                label: "Memory",
                type: "dropdown",
                fieldType: "text",
                validate: (memory: string) => memory.length > 0 && isNumeric(memory),
                defaultIcon: Icons.Chip,
                errorMessage: (memory: string) => {
                    if (memory.length === 0) {
                        return "Memory is required";
                    }
                    return "Memory has to be a valid number";
                },
                dropdownProps: {
                    items: memoryConfigDropdownItems,
                },
                defaultValue: quickContainer?.Containers[0].ContainerResource.LimitsMemory.toString() ?? "100",
            },
        ],
        {
            name: "domainId",
            label: "Domain",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: getAllDomainsQuery,
            transform(data) {
                return map(transformDomainData(data), (domain) => ({
                    id: domain.Id,
                    label: domain.Name,
                    icon: Icons.Config.Domain.Default,
                }));
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a domain" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Config.CreatDomain.path);
                },
            },
            defaultIcon: Icons.Config.Domain.Default,
            defaultValue: quickContainer?.Domain?.Id,
            clearable: true,
        },
        {
            name: "clusterId",
            label: "Cluster",
            type: "query",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            query: getAllClustersQuery,
            transform(data) {
                return map(transformClusterData(data), (cluster) => ({
                    id: cluster.Id,
                    label: cluster.Name,
                    icon: Icons.Config.Domain.Default,
                }));
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a cluster" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Deployment.CreatCluster.path);
                },
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: quickContainer?.Cluster?.Id,
            clearable: true,
            disabled: quickContainer?.Id != null,
        },
    ]
}

export const CreateQuickContainerCard: FC<ICreateQuickContainerCardProps> = (props) => {
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.Details);
    const [createQuickContainer,] = useMutation(createQuickContainerMutation);
    const navigate = useNavigate();
    const [detailsFormProps, { isFormValid: isDetailsFormValid, getForm: getDetailsForm }] = useFormHook();
    const [dataFormProps, { isFormValid: isDataFormValid, getForm: getDataForm }] = useFormHook();
    const [advancedFormProps, { isFormValid: isAdvancedFormValid, getForm: getAdvancedForm }] = useFormHook();
    const [error, setError] = useState("");
    const expandCardRef = useRef<Function>();

    const handleClose = useCallback(() => {
        setError("");
        expandCardRef.current?.();
    }, [expandCardRef]);

    const handleSubmit = useCallback((onlyCreate: boolean) => {
        let state = isDetailsFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isDataFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isAdvancedFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        setError("");
        const detailsForm = getDetailsForm();
        const dataForm = getDataForm();
        const advancedForm = getAdvancedForm();
        createQuickContainer({
            variables: {
                name: detailsForm.name,
                containers: [
                    {
                        RegistryImageId: detailsForm.imageId,
                        Tag: detailsForm.tag,
                        Ports: map(detailsForm.ports, port => parseInt(port)),
                        Resource: {
                            LimitsMemory: advancedForm.memory ?? "100",
                            LimitsCPU: advancedForm.cpu ?? "100",
                            RequestsMemory: advancedForm.memory ?? "100",
                            RequestsCPU: advancedForm.cpu ?? "100",
                        },
                        Volumes: dataForm.volumes ?? [],
                        EnvironmentVariableId: getOptional(dataForm.environmentVariableId),
                    },
                ],
                domainId: getOptional(advancedForm.domainId),
                size: getOptional(dataForm.size),
                clusterId: getOptional(advancedForm.clusterId),
                instances: getOptional(advancedForm.instances),
                onlyCreate,
            },
            onCompleted: () => {
                notify("Quick container created successfully", "success");
                handleClose();
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to create quick container", "error");
            }
        });
    }, [isDetailsFormValid, isDataFormValid, isAdvancedFormValid, getDetailsForm, getDataForm, getAdvancedForm, createQuickContainer, handleClose, props]);

    return (
        <CreateCard isExpanded={props.isCreating} label="Quick container" icon={{
            component: Icons.Add,
            bgClassName: "bg-green-500",
          }} tag={<div className="flex flex-col gap-1 items-end">
            <div className="text-red-800 text-xs">{error}</div>
          </div>} setToggleCallback={(toggle) => expandCardRef.current = toggle}>
            <div className="flex flex-col flex-grow mt-2 gap-2">
                <TabSelector selectedTab={selectedPanelId} tabs={filter(values(Panels), panel => panel !== Panels.Metrics)} onTabSelect={tab => setSelectedPanelId(tab)} />
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.Details,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDetailsDefaultForm(navigate)} defaultExtraValues={getDetailsForm()} {...detailsFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Data,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDataDefaultForm(navigate,)} defaultExtraValues={getDataForm()} {...dataFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Advanced,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getAdvancedDefaultForm(navigate)} defaultExtraValues={getAdvancedForm()} {...advancedFormProps} />
                        </div>,
                    },
                ]} />
                <div className="flex items-center gap-2 justify-end">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleClose} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButtonDropdown buttons={[
                        {
                            label: "Start",
                            icon: Icons.CheckCircle,
                            labelClassName: "text-green-800",
                            iconClassName: "text-green-600", 
                            onClick: () => handleSubmit(false),
                        },
                        {
                            label: "Just Create",
                            icon: Icons.CheckCircle,
                            labelClassName: "text-green-800",
                            iconClassName: "text-green-600", 
                            onClick: () => handleSubmit(true),
                        },
                    ]} />
                </div>
            </div>
        </CreateCard>
    )
}

type IContainerResource = {
    LimitsMemory: number;
    LimitsCPU: number;
    RequestsMemory: number;
    RequestsCPU: number;
}

type IContainer = {
    RegistryImageId: string;
    EnvironmentVariableId: string;
    Tag: string;
    Ports: number[];
    Volumes: string[];
    ContainerResource: IContainerResource;
}

export type IQuickContainerStatus = "Created" | "Starting" | "Started" | "Stopping" | "Stopped" | "Deleting" | "Deleted" | "Failed";
export type IQuickContainer = {
    Id: string;
    Name: string;
    Type: string;
    Status: IQuickContainerStatus;
    Containers: IContainer[];
    Domain?: IDomain;
    Cluster?: ICluster;
    Size?: number;
    Instances?: number;
}

const OpenLinkButton: FC<{ quickContainer: IQuickContainer }> = (props) => {
    const handleOpenDefaultLink = useCallback(() => {
        const host = window.location.hostname;
        window.open(`${window.location.protocol}//qc-${props.quickContainer.Id}.${host}`);
    }, [props.quickContainer]);

    const openLinkButtons = useMemo(() => {
        const dropdownItems = [
            {
                label: "Open link",
                icon: Icons.RightArrowUp,
                onClick: handleOpenDefaultLink,
                iconClassName: "w-3 h-3",
            }
        ];
        forEach(props.quickContainer.Domain?.Domains ?? [], domain => {
            dropdownItems.push({
                label: `Open ${domain}`,
                icon: Icons.RightArrowUp,
                onClick: () => window.open(`${window.location.protocol}//${domain}`),
                iconClassName: "w-3 h-3",
            });
        });
        return dropdownItems;
    }, [props.quickContainer, handleOpenDefaultLink]);
    
    return <AnimatedButtonDropdown buttons={openLinkButtons} />;
}

export const QuickContainerIntro: FC<{ quickContainer: IQuickContainer }> = (props) => {
    const { data } = useAppQuery(getOneRegistryImageQuery, {
        variables: {
            id: props.quickContainer.Containers[0].RegistryImageId,
        }
    });

    const selectedRegistryImage = transformRegistryImageData(data)?.[0];

    return <div className="flex flex-col grow mt-2 gap-1 min-w-[165px]">
        <div className={twMerge(ELLIPSES_CLASS, "text-md max-w-[180px]")}>
            {props.quickContainer.Name}
        </div>
        <div className="text-xs flex items-center gap-1">
            <strong>Image:</strong> <PrivateTag data={selectedRegistryImage?.Name} size="small" />
        </div>
        <div className="text-xs flex items-center gap-1">
            <strong>Tag:</strong> <PrivateTag data={selectedRegistryImage?.Name == null ? undefined : props.quickContainer.Containers[0].Tag} size="small" />
        </div>
        <div className="text-xs">
            <strong>Ports:</strong> {props.quickContainer.Containers[0].Ports.join(",")}
        </div>
    </div>
}

export const QuickContainerInfo: FC<{ quickContainer: IQuickContainer }> = (props) => {
    const containerResource = props.quickContainer.Containers[0].ContainerResource;
    return <div className="flex flex-col grow mt-2 gap-1">
        <div className="text-xs">
            <strong>CPU:</strong> {containerResource.LimitsCPU}m
        </div>
        <div className="text-xs">
            <strong>Memory:</strong> {containerResource.LimitsMemory}Mi
        </div>
        <div className="text-xs">
            <strong>Volumes:</strong> {props.quickContainer.Containers[0].Volumes?.join(" | ")}
        </div>
        <div className="text-xs">
            <strong>Instances:</strong> {props.quickContainer.Instances}
        </div>
    </div>
}

const QuickContainerLogs: FC<{ quickContainer: IQuickContainer, instanceMap: Record<string, string>, instanceNames: string[] }> = ({ quickContainer, instanceMap, instanceNames }) => {
    const [instanceName, setInstanceName] = useState(instanceNames[0]);
    const { data, loading } = useAppQuery(getQuickContainerInstanceLogs, {
        variables: {
            id: quickContainer.Id,
            instanceName,
        },
    });

    if (data?.QuickContainerLogs == null || loading) {
        return <Loading hideText={true}  />
    }

    return (
        <div className="flex flex-col gap-1 items-start justify-start">
            <LogViewer logs={data.QuickContainerLogs[0].Logs} actions={instanceNames.length === 1 ? undefined : <div className="flex max-w-[250px] items-center gap-1 h-full">
                <Dropdown className="h-full" items={instanceNames.map(instanceName => ({
                    id: instanceName,
                    label: instanceMap[instanceName],
                }))} onClick={(item) => setInstanceName(item.id)}>
                    <div className="flex flex-row gap-1 text-sm border px-2 py-1 border-gray-200 rounded-md justify-center items-center text-gray-500 cursor-pointer h-full">
                        {instanceMap[instanceName]}
                        {cloneElement(Icons.DownCaret, {
                            className: "w-4 h-4 stroke-gray-500",
                        })}
                    </div>
                </Dropdown>
            </div>} />
        </div>
    )
}

type IQuickContainerMetrics = {
    InstanceName: string;
    Name: string;
    CPU: number;
    RAM: number;
    Time: number;
}

type IListenQuickContainerMetricsSubscription = {
    ListenQuickContainerMetrics: IQuickContainerMetrics;
}

const getBasicDates = once(() => {
    const date = new Date();
    return map([...new Array(10)], (_, i) => new Date(date.getTime() - (10000 - i*1000)))
});

const getBasicData = once((instanceNames: string[], instanceMap: Record<string, string>) => {
    return times(10, () => instanceNames.reduce((all, instanceName) => {
        all[instanceMap[instanceName]] = 0;
        return all;
    }, {} as Record<string, number>));
});

const QuickContainerMetrics: FC<{ quickContainer: IQuickContainer, instanceMap: Record<string, string>, instanceNames: string[] }> = ({ quickContainer, instanceMap, instanceNames }) => {
    const [cpu, setCPU] = useImmer<ILineChartData>({ x: getBasicDates(), y: getBasicData(instanceNames, instanceMap) });
    const [ram, setRAM] = useImmer<ILineChartData>({ x: getBasicDates(), y: getBasicData(instanceNames, instanceMap) });
    
    const { loading } = useSubscription<IListenQuickContainerMetricsSubscription>(subscribeQuickContainerMetrics, {
        variables: {
            id: quickContainer.Id,
            instanceNames,
        },
        onData({ data: { data }}) {
            if (data?.ListenQuickContainerMetrics == null) {
                return;
            }
            const instanceName = data.ListenQuickContainerMetrics.InstanceName;
            const date = new Date(data.ListenQuickContainerMetrics.Time * 1000);
            setCPU(cpu => {
                if (cpu.x.at(-1)?.getTime() === date.getTime()) {
                    cpu.y.at(-1)![instanceMap[instanceName]] = data.ListenQuickContainerMetrics.CPU;
                    return;
                }
                cpu.x.push(date);
                cpu.y.push({
                    [instanceMap[instanceName]]: data.ListenQuickContainerMetrics.CPU,
                });
            });
            setRAM(ram => {
                if (ram.x.at(-1)?.getTime() === date.getTime()) {
                    ram.y.at(-1)![instanceMap[instanceName]] = data.ListenQuickContainerMetrics.RAM;
                    return;
                }
                ram.x.push(date);
                ram.y.push({
                    [instanceMap[instanceName]]: data.ListenQuickContainerMetrics.RAM,
                });
            });
        },
    });
    
    if (loading) {
        return <Loading hideText={true}  />
    }

    return (
        <>
            <div className="h-[100px] relative max-w-[366px]">
                <Pill id="CPU" label="CPU" className="absolute left-0 top-0 text-[10px] z-5 shadow-md" />
                <LineChart x={cpu.x} y={cpu.y} />
            </div>
            <div className="h-[100px] relative max-w-[366px]">
                <Pill id="RAM" label="RAM" className="absolute left-0 top-0 text-[10px] z-5 shadow-md" />
                <LineChart x={ram.x} y={ram.y}  />
            </div>
        </>
    )
}

const QuickContainerMetricsCard: FC<{ quickContainer: IQuickContainer, showLogs: boolean }> = ({ quickContainer, showLogs }) => {
    const [instanceMap, setInstanceMap]  = useState<Record<string, string>>({});
    const { data, loading } = useAppQuery(getQuickContainerInstances, {
        variables: {
            id: quickContainer.Id,
        },
        onCompleted(data) {
            if (data?.QuickContainerInstances != null) {
                setInstanceMap((data.QuickContainerInstances as string[]).reduce((map, instance, i) => {
                    map[instance] = `Instance ${i+1}`; 
                    return map;
                }, {} as Record<string, string>));
            }
        }
    });

    const mapSize = useMemo(() => {
        return keys(instanceMap).length;
    }, [instanceMap]);

    if (data?.QuickContainerInstances == null || loading || mapSize === 0) {
        return <Loading hideText={true}  />
    }

    if (showLogs) {
        return <QuickContainerLogs quickContainer={quickContainer} instanceMap={instanceMap} instanceNames={data.QuickContainerInstances} />
    }

    return <QuickContainerMetrics quickContainer={quickContainer} instanceMap={instanceMap} instanceNames={data.QuickContainerInstances} />
}

type IQuickContainerCardProps = {
    quickContainer: IQuickContainer;
    refetch: () => void;
}

export const QuickContainerCard: FC<IQuickContainerCardProps> = (props) => {
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.Details);
    const navigate = useNavigate();
    const toggleCardExpansion = useRef<Function>();
    const [error, setError] = useState("");
    const [updateQuickContainer,] = useMutation(updateQuickContainerMutation);
    const [deleteQuickContainer] = useMutation(deleteQuickContainerMutation);
    const [startQuickContainer] = useMutation(startQuickContainerMutation);
    const [stopQuickContainer] = useMutation(stopQuickContainerMutation);
    const [restartQuickContainer] = useMutation(restartQuickContainerMutation);
    const [searchParams, ] = useSearchParams();
    const [showLogs, setShowLogs] = useState(false);

    const [detailsFormProps, { isFormValid: isDetailsFormValid, getForm: getDetailsForm }] = useFormHook({
        name: props.quickContainer.Name,
        imageId: props.quickContainer?.Containers[0].RegistryImageId,
        tag: props.quickContainer?.Containers[0].Tag,
        ports: map(props.quickContainer?.Containers[0].Ports ?? [], toString),
    });
    const [dataFormProps, { isFormValid: isDataFormValid, getForm: getDataForm }] = useFormHook({
        volumes: props.quickContainer?.Containers[0].Volumes ?? [],
        size: props.quickContainer.Size?.toString() ?? "10",
        environmentVariableId: props.quickContainer?.Containers[0].EnvironmentVariableId,
    });
    const [advancedFormProps, { isFormValid: isAdvancedFormValid, getForm: getAdvancedForm }] = useFormHook({
        cpu: props.quickContainer?.Containers[0].ContainerResource.LimitsCPU.toString() ?? "100",
        ram: props.quickContainer?.Containers[0].ContainerResource.LimitsMemory.toString() ?? "100",
        domainId: props.quickContainer?.Domain?.Id,
        clusterId: props.quickContainer?.Cluster?.Id,
    });

    const handleCancel = useCallback(() => {
        setError("");
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    const handleStart = useCallback(() => {
        startQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Starting ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to start ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, startQuickContainer]);

    const handleStop = useCallback(() => {
        stopQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Stopping ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to stop ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, stopQuickContainer]);

    const handleRestart = useCallback(() => {
        restartQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Restarting ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to restart ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, restartQuickContainer]);

    const handleDelete = useCallback(() => {
        deleteQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Deleting ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to delete ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, deleteQuickContainer]);

    const handleUpdate = useCallback(() => {
        let state = isDetailsFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isDataFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isAdvancedFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        setError("");
        const detailsForm = getDetailsForm();
        const dataForm = getDataForm();
        const advancedForm = getAdvancedForm();
        updateQuickContainer({
            variables: {
                id: props.quickContainer.Id,
                name: detailsForm.name,
                containers: [
                    {
                        RegistryImageId: detailsForm.imageId,
                        Tag: detailsForm.tag,
                        Ports: map(detailsForm.ports, port => parseInt(port)),
                        Resource: {
                            LimitsMemory: advancedForm.memory ?? "100",
                            LimitsCPU: advancedForm.cpu ?? "100",
                            RequestsMemory: advancedForm.memory ?? "100",
                            RequestsCPU: advancedForm.cpu ?? "100",
                        },
                        Volumes: dataForm.volumes ?? [],
                        EnvironmentVariableId: getOptional(dataForm.environmentVariableId),
                    },
                ],
                domainId: getOptional(advancedForm.domainId),
                size: getOptional(dataForm.size),
                clusterId: getOptional(advancedForm.clusterId),
                instances: getOptional(advancedForm.instances),
            },
            onCompleted: () => {
                notify("Quick container updated successfully", "success");
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update quick container", "error");
            }
        });
    }, [isDetailsFormValid, isDataFormValid, isAdvancedFormValid, getDetailsForm, getDataForm, getAdvancedForm, updateQuickContainer, props.quickContainer.Id]);

    const toggleShowLogs = useCallback(() => {
        setShowLogs(s => !s);
    }, []);

    const handleTabSelect = useCallback((tab: string) => {
        if (tab !== Panels.Metrics) {
            setShowLogs(false);
        }
        setSelectedPanelId(tab);
    }, []);

    const handleCardExpansion = useCallback(() => {
        toggleCardExpansion.current?.(true)
    }, [toggleCardExpansion]);
    
    const handleShowLogsPreview = useCallback(() => {
        handleCardExpansion();
        setSelectedPanelId(Panels.Metrics);
        setShowLogs(true);
    }, [handleCardExpansion]);

    const exploreButtons = useMemo(() => {
        return [
            {
                label: "Edit",
                icon: Icons.Edit,
                onClick: handleCardExpansion,
                iconClassName: "w-3 h-3",
            },
            {
                label: "Logs",
                icon: Icons.DocumentText,
                onClick: handleShowLogsPreview,
                iconClassName: "w-3 h-3",
            },
            {
                label: "Copy internal link",
                icon: Icons.Clipboard,
                onClick: once(() => copyToClipboard(`quick-container-${props.quickContainer.Id}.${getInstallNamespace()}.svc.cluster.local`)),
                iconClassName: "w-3 h-3",
            },
        ]
    }, [handleCardExpansion, handleShowLogsPreview, props.quickContainer.Id]);
    
    const operationButtons = useMemo(() => {
        const dropdownItems: IButtonProps[] = [];
        if (props.quickContainer.Status.endsWith("ing") && props.quickContainer.Status !== "Starting") {
            dropdownItems.push({
                label: props.quickContainer.Status,
                icon: Icons.Disabled,
                labelClassName: "text-yellow-800",
                iconClassName: "text-yellow-600",
            });
        } else if (["Created", "Stopped"].includes(props.quickContainer.Status)) {
            dropdownItems.push({
                label: "Start",
                icon: Icons.Play,
                onClick: handleStart,
                labelClassName: "text-green-800",
                iconClassName: "text-green-600",
            });
        } else if (["Starting", "Started"].includes(props.quickContainer.Status)) {
            dropdownItems.push({
                label: "Stop",
                icon: Icons.Stop,
                onClick: handleStop,
                labelClassName: "text-orange-800",
                iconClassName: "text-orange-600",
            });
            if (props.quickContainer.Status === "Started") {
                dropdownItems.push({
                    label: "Restart",
                    icon: Icons.RectangularArrowLoop,
                    onClick: handleRestart,
                    labelClassName: "text-orange-800",
                    iconClassName: "text-orange-600",
                });
            }
        }
        dropdownItems.push({
            label: "Delete",
            icon: Icons.Delete,
            labelClassName: "text-red-800",
            iconClassName: "text-red-600",
            onClick: handleDelete,
        });
        return dropdownItems;
    }, [handleDelete, handleRestart, handleStart, handleStop, props.quickContainer.Status]);

    const quickContainerIcon = useMemo(() => {
        return getQuickContainerIcon(props.quickContainer);
    }, [props.quickContainer]); 

    const status = toTitleCase(props.quickContainer.Status);

    return (
        <GraphBasedExpandableCard className="group/quick-container" id={props.quickContainer.Id} type={GraphElements.QuickContainer} icon={quickContainerIcon} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
            collapsedTag={<Pill id={status} label={status} className="opacity-50 transition-all group-hover/quick-container:opacity-100" />}
            tag={<div className="flex flex-col gap-1 items-end">
                <Pill id={status} label={status} />
                <div className="text-red-800 text-xs">{error}</div>
            </div>} highlight={searchParams.get("id") === props.quickContainer.Id} fullScreen={showLogs} permissionsEnabled={true}
            actionPanels={[
                {
                    id: "connections",
                    component: <QuickContainerConnections quickContainer={props.quickContainer} />,
                    icon: <div className={classNames(ICON_CLASS, "bg-white")} onClick={handleCardExpansion}>
                        {cloneElement(Icons.LeftRight, {
                            className: classNames("w-8 h-8 stroke-yellow-500 transition-all hover:scale-110"),
                        })}
                    </div>,
                }
            ]}>
            <>
                <QuickContainerIntro quickContainer={props.quickContainer} />
                <div className="flex gap-1">
                    <AnimatedButtonDropdown buttons={exploreButtons} />
                    <OpenLinkButton quickContainer={props.quickContainer} />
                </div>
            </>
            <div className="flex flex-col flex-grow mt-2 gap-2">
                <TabSelector selectedTab={selectedPanelId} tabs={values(Panels).filter(panel => props.quickContainer.Status === "Started" || panel !== Panels.Metrics)} onTabSelect={handleTabSelect} />
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.Details,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDetailsDefaultForm(navigate, props.quickContainer)} defaultExtraValues={getDetailsForm()} {...detailsFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Data,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getDataDefaultForm(navigate, props.quickContainer)} defaultExtraValues={getDataForm()} {...dataFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Advanced,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <Form variables={getAdvancedDefaultForm(navigate, props.quickContainer)} defaultExtraValues={getAdvancedForm()} {...advancedFormProps} />
                        </div>,
                    },
                    {
                        id: Panels.Metrics,
                        component: <div className="flex flex-col gap-1 grow scroll-smooth">
                            <QuickContainerMetricsCard quickContainer={props.quickContainer} showLogs={showLogs} />
                        </div>,
                    },
                ]} />
                <div className="flex justify-between items-center">
                    <PanelSelector panels={[
                        {
                            id: Panels.Metrics,
                            component: <div>
                                <AnimatedButton label={showLogs ? "Hide logs" : "Logs"} icon={Icons.DocumentText} onClick={toggleShowLogs} labelClassName="text-gray-800" iconClassName="text-gray-600" />
                            </div>
                        },
                        {
                            id: Panels.Details,
                            component: <AnimatedButtonDropdown buttons={operationButtons} />,
                        },
                    ]} selectedPanelId={selectedPanelId === Panels.Metrics ? Panels.Metrics : Panels.Details} />
                    <div className="flex items-center gap-2">
                        <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                        <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleUpdate} />
                    </div>
                </div>
            </div>
        </GraphBasedExpandableCard>
    )
}


export const QuickContainerDashboardCard: FC<{ quickContainer: IQuickContainer, showInstances?: boolean }> = (props) => {
    const quickContainerIcon = useMemo(() => {
        return getQuickContainerIcon(props.quickContainer);
    }, [props.quickContainer]); 

    const instances = useMemo(() => {
        if (!props.showInstances || props.quickContainer.Instances == null || props.quickContainer.Instances === 1) {
            return <></>;
        }
        const children: ReactNode[] = [];
        for (let i = 1; i <= Math.min(props.quickContainer.Instances-1, 2); i++) {
            const offset = 8*i;
            children.push(<div className="absolute top-0 bg-white h-full w-full rounded-3xl shadow-md border" style={{
                transform: `translate(${offset}px,${offset}px)`,
                zIndex: -i,
            }}></div>);
        }
        return children;
    }, [props.quickContainer.Instances, props.showInstances]);

    return <div className="relative">
        <AdjustableCard showTools={true} icon={quickContainerIcon} tag={<Pill id={props.quickContainer.Status} label={props.quickContainer.Status} />}
            className={classNames({
                "border-red-500/50": props.quickContainer.Status === "Failed",
            })}>
            <QuickContainerIntro quickContainer={props.quickContainer} />
            <QuickContainerInfo quickContainer={props.quickContainer} />
            <div className="flex flex-col gap-1 grow scroll-smooth mt-4">
                <QuickContainerMetricsCard quickContainer={props.quickContainer} showLogs={false} />
            </div>
        </AdjustableCard>
        {instances}
    </div>
}

export const transformQuickContainersData: IDataTransform<IQuickContainer> = (data: { QuickContainer: IQuickContainer[] }) => data.QuickContainer;

export const QuickContainerGraphCard: FC<IGraphCardProps<IQuickContainer>> = (props) => {
    return <GraphCardLoader loader={getOneQuickContainerQuery} transform={(data) => transformQuickContainersData(data)[0]} {...props}>
        {(data: IQuickContainer) => (<QuickContainerDashboardCard quickContainer={data} showInstances={true} />)}
    </GraphCardLoader>;
}

const zoomOutResolution = 366/1280;

export const QuickContainerPreviewGraphCard: FC<IGraphCardProps<IQuickContainer>> = (props) => {
    const webPage = useMemo(() => {
        const link = props.id.split(":")[1];
        if (link == null) {
            return;
        }
        return `${window.location.protocol}//${link}`;
    }, [props.id]);

    const handleOpenLink = useCallback(() => {
        window.open(webPage, "_blank");
    }, [webPage]);

    return <GraphBaseCard {...props}>
        <Card className="w-[400px] h-[400px] group/preview overflow-hidden">
            {
                webPage != null &&
                <div className="w-full h-full" style={{
                    zoom: zoomOutResolution,
                }}>
                    <iframe src={webPage} className="w-[1280px] h-full pointer-events-none" title="Preview" />
                </div>
            }
            <div className="absolute inset-0  group-hover/preview:bg-black/20" />
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 scale-125 group-hover/preview:opacity-100">
                <ActionButton icon={Icons.RightArrowUp} onClick={handleOpenLink} />
            </div>
        </Card>
    </GraphBaseCard>;
}

export const AddQuickContainerDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Quick Container"
        query={getAllQuickContainersQuery}
        transform={transformQuickContainersData} type={GraphElements.QuickContainer}
        icon={Icons.Deploy.QuickContainer.Default}
        link={InternalRoutes.Deployment.CreatQuickContainer.path} />
    );
}