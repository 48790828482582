import { ChangeEventHandler, DetailedHTMLProps, FC, InputHTMLAttributes, KeyboardEvent, KeyboardEventHandler, cloneElement, useCallback, useState } from "react";
import { Icons } from "../config/icons";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useDispatch } from "react-redux";
import { CommonActions } from "../store/common";

export const Label: FC<{ label: string }> = ({ label }) => {
    return <strong><label className="text-xs text-gray-600 mt-2">{label}</label></strong>
}

type InputProps = {
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    placeholder?: string;
    value: string;
    setValue?: (value: string) => void;
    type?: "text" | "password";
    multiline?: boolean;
    autoHeight?: boolean;
    onSubmit?: () => void;
}

const TextArea: FC<InputProps> = ({ value, setValue, type, placeholder, inputProps = {}, autoHeight, onSubmit }) => {
    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        setValue?.(e.target.value);
    }, [setValue]);

    const handleInput: KeyboardEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if (autoHeight) {
            const target = e.target as HTMLTextAreaElement;
            target.style.height = 'inherit';
            target.style.height = `${Math.min(target.scrollHeight, 300)}px`;
        }
    },[autoHeight]);

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if (e.ctrlKey && e.key === "Enter") {
            onSubmit?.();
        }
    }, [onSubmit]);

    return <textarea placeholder={placeholder} name={type} cols={40} rows={1}
        value={value} onChange={handleTextChange}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        className={twMerge(classNames("transition-all resize-none overflow-hidden appearance-none border border-gray-200 rounded-lg w-full p-1 text-gray-700 leading-tight outline-none text-sm h-[34px] px-2", inputProps.className))} />
};

export const Input: FC<InputProps> = ({ value, setValue, type, placeholder, multiline, inputProps = {}, autoHeight, onSubmit }) => {
    const dispatch = useDispatch();

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        setValue?.(e.target.value);
        inputProps.onChange?.(e);
    }, [inputProps, setValue]);

    const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.metaKey && e.key === "k") {
            dispatch(CommonActions.setGlobalSearch(true));
            return e.preventDefault();
        }
        inputProps.onKeyDown?.(e);
    }, [dispatch, inputProps]);
    
    if (multiline) {
        return <TextArea value={value} setValue={setValue} type={type} placeholder={placeholder}
                    inputProps={inputProps} autoHeight={autoHeight} onSubmit={onSubmit} />
    }
    return <input type={type} placeholder={placeholder}
        value={value}  {...inputProps} onChange={handleChange} onKeyDown={handleKeyDown}
        className={twMerge(classNames("appearance-none border border-gray-200 rounded w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[34px] px-2", inputProps.className))} />
}

type InputWithLabelProps = {
    label: string;
} & InputProps;

export const InputWithlabel: FC<InputWithLabelProps> = ({ value, setValue, label, type = "text", placeholder = `Enter ${label.toLowerCase()}`, inputProps }) => {
    const [hide, setHide] = useState(true);

    const handleShow = useCallback(() => {
        setHide(status => !status);
    }, []);

    const inputType = type === "password" ? hide ? "password" : "text" : type;

    return <div className="flex flex-col gap-1">
        <Label label={label} />
        <div className="relative">
            <Input type={inputType} value={value} setValue={setValue} inputProps={inputProps} placeholder={placeholder} />
            {type === "password" && cloneElement(hide ? Icons.Show : Icons.Hide, {
                className: "w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer transition-all hover:scale-110",
                onClick: handleShow,
            })}
        </div>
    </div>
}

type ICheckboxInputProps = {
    className?: string;
    checked?: boolean;
    onChecked?: (checked: boolean) => void;
}

export const CheckboxInput: FC<ICheckboxInputProps> = (props) => {
    return <input type="checkbox" checked={props.checked} onChange={e => props.onChecked?.(e.target.checked)}
                className={classNames("w-4 h-4 text-teal-600 bg-gray-100 border-gray-300 rounded focus:ring-teal-500 focus:ring-2", props.className)} />
}