import { FC } from "react";
import { Page } from "../../../components/container";
import { CreateQuickContainerCard } from "./quick-container-card";
import { useAppQuery } from "../../../api/query";
import { loader } from "graphql.macro";
import { IQuickContainer, IQuickContainerStatus, QuickContainerCard } from "./quick-container-card";
import { useSubscription } from "@apollo/client";
import { notify } from "../../../store/function";
import { InternalRoutes } from "../../../config/internal-routes";

const getQuickContainerStatusSubscription = loader("./get-quick-container-status.graphql");
export const getAllQuickContainersQuery = loader("./get-quick-container.graphql");

type IQuickContainerPageProps = {
    isCreating?: boolean;
}

type ISubscriptionData = {
    ListenQuickContainerStatus: {
        Id: string;
        Status: IQuickContainerStatus;
    }
}

const statusToListen = new Set<IQuickContainerStatus>(["Deleted", "Stopped", "Started","Failed"]);

export const QuickContainerPage: FC<IQuickContainerPageProps> = (props) => {
    const getAllQuickContainersResponse = useAppQuery(getAllQuickContainersQuery);
   
     useSubscription<ISubscriptionData>(getQuickContainerStatusSubscription, {
        variables: {
            ids: getAllQuickContainersResponse.data?.QuickContainer?.map((quickContainer: IQuickContainer) => quickContainer.Id) ?? [], 
        },
        onData({ data: { data } }) {
            const status = data?.ListenQuickContainerStatus;
            if (status == null) {
                return;
            }
            if (!statusToListen.has(status.Status)) {
                return;
            }
            const quickContainer: IQuickContainer = getAllQuickContainersResponse.data?.QuickContainer?.find((qc: IQuickContainer) => qc.Id === status.Id);
            if (quickContainer == null) {
                return;
            }
            notify(`${quickContainer.Name} has ${status.Status.toLowerCase()}.`, status.Status === "Failed" ? "error" : "success");
            getAllQuickContainersResponse.refetch();
        },
    });

    return (
        <Page routes={[InternalRoutes.Deployment.QuickContainer]}>
            <CreateQuickContainerCard isCreating={props.isCreating} refetch={getAllQuickContainersResponse.refetch} />
            {
                getAllQuickContainersResponse.data?.QuickContainer.map((quickContainer: IQuickContainer) => (
                    <QuickContainerCard key={quickContainer.Id} quickContainer={quickContainer} refetch={getAllQuickContainersResponse.refetch} />
                ))
            }
        </Page>
    )
}