import { FC, useCallback, useRef, useState } from "react";
import { AnimatedButton } from "../../components/button";
import { CreateCard } from "../../components/card";
import { IDropdownItem } from "../../components/dropdown";
import { Search } from "../../components/search";
import { PanelSelector } from "../../components/selector";
import { Icons } from "../../config/icons";
import { DigitalOceanOAuthIntegrationsLoginCard } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { CreateDockerIOIntegrationsForm } from "./docker-io-integrations/docker-io-integrations-card";
import { GitHubOAuthIntegrationsLoginCard } from "./github-integrations/github-integrations-card";
import { GoogleOAuthIntegrationsLoginCard } from "./google/google-integrations-card";

export type ICreateIntegrationsCardProps = {
    isCreating?: boolean;
    refetch?: () => void;
}

enum Panels {
    STARTING = "STARTING",
    GITHUB = "GITHUB",
    DOCKER_IO = "DOCKER_IO",
    DIGITAL_OCEAN = "DIGITAL_OCEAN",
    GOOGLE = "GOOGLE",
    AWS = "AWS",
    AZURE = "AZURE",
    FLY_IO = "FLY_IO",
}

const integrations: IDropdownItem[] = [
    {
        id: Panels.GITHUB,
        label: "GitHub",
        icon: Icons.Logos.GitHub,
    },
    {
        id: Panels.DOCKER_IO,
        label: "Docker IO",
        icon: Icons.Logos.DockerIO,
    },
    {
        id: Panels.DIGITAL_OCEAN,
        label: "Digital Ocean",
        icon: Icons.Logos.DigitalOcean,
    },
    {
        id: Panels.GOOGLE,
        label: "Google",
        icon: Icons.Logos.Google,
    },
]

export const CreateIntegrationsCard: FC<ICreateIntegrationsCardProps> = (props) => {
    const [error, setError] = useState("");
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.STARTING);
    const ref = useRef<Function>();

    const handleSelect = useCallback((item: IDropdownItem) => {
        setSelectedPanelId(item.id)
    }, []);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Integration" icon={{
                component: Icons.Add,
                bgClassName: "bg-blue-500",
            }} tag={<div className="flex flex-col gap-1 items-end">
                <div className="text-red-800 text-xs">{error}</div>
            </div>} setToggleCallback={(toggle) => ref.current = toggle}>
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.STARTING,
                        component: <div className="flex flex-col gap-1 my-4 grow">
                            <div className="text-lg my-2">Create integration</div>
                            <Search label="Search your integration" className="flex-grow" items={integrations} isOpen={true} onSelect={handleSelect} noItemsLabel="No integrations found" />
                            <div className="flex justify-end">
                                <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={() => ref.current?.()} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                            </div>
                        </div>,
                    },
                    {
                        id: Panels.DOCKER_IO,
                        component: <CreateDockerIOIntegrationsForm
                            onCancel={() => setSelectedPanelId(Panels.STARTING)}
                            onComplete={() => {
                                setSelectedPanelId(Panels.STARTING);
                                ref.current?.();
                                props.refetch?.();
                            }}
                            onError={setError} />
                    },
                    {
                        id: Panels.GITHUB,
                        component: <GitHubOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                    {
                        id: Panels.DIGITAL_OCEAN,
                        component: <DigitalOceanOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                    {
                        id: Panels.GOOGLE,
                        component: <GoogleOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                ]} />
            </CreateCard>
        </>
    )
}