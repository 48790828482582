import { FC, useContext, useEffect } from "react"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { HookGraphViewContext } from "../context"
import { HooksElements, HooksStepCard } from "../hooks-step-card"


function getDefaultForm(operationResult?: IOperationResult, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "operation",
            label: "Result Type",
            type: "dropdown",
            fieldType: "text",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Operation type is required",
            dropdownProps: {
                items: [
                    {
                        id: "Success",
                        label: "Success",
                    },
                    {
                        id: "Failure",
                        label: "Failure",
                    }
                ],
            },
            defaultValue: operationResult?.Type,
            disabled,
        },
    ]
}


export type ICreateOperationResultHookStep = {
    Type: HooksElements.OperationResult;
    OperationResult: {
        Type: string;
    }
}

type IOperationResult = {
    Type: string;
    Status?: string;
};

export type IOperationResultHookStep = {
    Type: HooksElements.OperationResult;
    OperationResult: IOperationResult;
};

export const OperationResultHookStepCard: FC<IGraphCardProps<IOperationResult>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();
    const disabled = props.data?.Status != null;

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertOperationResultFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Operations results condition" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(props.data, disabled)} {...formProps} />
        </HooksStepCard>
    )
}

const convertOperationResultFormToStep = (form: IForm): ICreateOperationResultHookStep => {
    return {
        Type: HooksElements.OperationResult,
        OperationResult: {
            Type: form.operation as string,
        }
    }
}