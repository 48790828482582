import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateHooksCard, HookCard, IHook } from "./hooks-card";
import { loader } from "graphql.macro";
import { useAppQuery } from "../../../api/query";

type IHooksProps = {
    isCreating?: boolean;
}

export const getAllHookQuery = loader("./get-hooks.graphql");

export const HooksPage: FC<IHooksProps> = (props) => {
    const getHooksResponse = useAppQuery(getAllHookQuery);
    return (
        <Page routes={[InternalRoutes.CI_CD.Hooks]}>
            <CreateHooksCard isCreating={props.isCreating} refetch={getHooksResponse.refetch} />
            {
                getHooksResponse.data?.Hook?.map((hook: IHook) => (
                    <HookCard key={hook.Id} hook={hook} refetch={getHooksResponse.refetch} />
                ))
            }
        </Page>
    )
}