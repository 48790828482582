import classNames from 'classnames';
import { motion } from 'framer-motion';
import { findIndex, forEach } from 'lodash';
import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Node, ReactFlowProvider, XYPosition, useEdgesState, useNodesState, useReactFlow } from 'reactflow';
import { Card } from '../../components/card';
import { Graph } from '../../components/graph/graph';
import { createNode, createEdge } from "../../components/graph/utils";
import { GraphElements } from "../../config/constants";
import { Icons } from '../../config/icons';
import { FlowGraphCard } from '../ci-cd/flow/flow-card';
import { HookGraphCard } from '../ci-cd/hooks/hooks-card';
import { DomainGraphCard } from '../config/domain/domain-card';
import { EnvironmentVariableGraphCard } from '../config/environment-variable/environment-variable-card';
import { RegistryImageGraphCard } from '../container/registry-image/registry-image-card';
import { RegistryGraphCard } from '../container/registry/registry-card';
import { ClusterGraphCard } from '../deploy/cluster/cluster-card';
import { QuickContainerGraphCard, QuickContainerPreviewGraphCard } from '../deploy/quick-container/quick-container-card';
import { DigitalOceanIntegrationGraphCard } from '../integrations/digital-ocean-integrations/digital-ocean-integrations-card';
import { DockerIORegistryGraphCard } from '../integrations/docker-io-integrations/docker-io-integrations-card';
import { GitHubIntegrationGraphCard } from '../integrations/github-integrations/github-integrations-card';
import { GraphViewContext, IDashboardElement } from './context';
import { ADD_DASHBOARD_ICON } from './dashboard';
import { AddDashboardElementForm } from './dashboard-card';


const View: FC = () => {
    const { state } = useLocation();
    const [selectedNodes, setSelectedNodes] = useState({});
    const { getViewport, getNode } = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [showAddElement, setShowAddElement] = useState(false);

    const nodeTypes = useMemo(() => ({
        [GraphElements.QuickContainer]: QuickContainerGraphCard,
        [GraphElements.Registry]: RegistryGraphCard,
        [GraphElements.RegistryImage]: RegistryImageGraphCard,
        [GraphElements.DockerIORegistryIntegration]: DockerIORegistryGraphCard,
        [GraphElements.Hook]: HookGraphCard,
        [GraphElements.Flow]: FlowGraphCard,
        [GraphElements.Domain]: DomainGraphCard,
        [GraphElements.Cluster]: ClusterGraphCard,
        [GraphElements.EnvironmentVariable]: EnvironmentVariableGraphCard,
        [GraphElements.GitHubIntegration]: GitHubIntegrationGraphCard,
        [GraphElements.DigitalOceanIntegration]: DigitalOceanIntegrationGraphCard,
        [`${GraphElements.QuickContainer}:preview`]: QuickContainerPreviewGraphCard,
    }), []);

    const addDashboardElement = useCallback((id: string, type: GraphElements, data?: IDashboardElement, position?: XYPosition) => {
        if (getNode(id) != null) {
            return;
        }

        const viewPort = getViewport();
        const newNode: Node = createNode({
            id,
            type,
            data,
        }, position, viewPort);
        setNodes(nds => {
            const index = findIndex(nds, nd => nd.id === id);
            if (index >= 0) {
                nds[index] = newNode;
                return [...nds];
            }
            return [...nds, newNode];
        });
    }, [getViewport, setNodes, getNode]);
    
    const removeDashboardElement = useCallback((id: string) => {
        setNodes(nds => nds.filter(nd => nd.id === id));
        setEdges(edgs => edgs.filter(edg => edg.source === id || edg.target === id));
    }, [setNodes, setEdges]);

    const addEdge = useCallback((source: string, target: string) => {
        const newEdge = createEdge(source, target);
        setEdges(edgs => {
            if (findIndex(edgs, edg => edg.id === newEdge.id) >= 0) {
                return [...edgs];
            }
            return [...edgs, newEdge];
        });
    }, [setEdges]);

    const handleSelection = useCallback((_: MouseEvent, node: Node) => {
        setSelectedNodes({ [node.id]: true });
    }, []);

    const handlePaneClick = useCallback(() => {
        setSelectedNodes({});
    }, []);

    const handleAddElement = useCallback(() => {
        setShowAddElement(status => !status);
    }, []);

    useEffect(() => {
        forEach(state?.nodes ?? [], (node) => {
            addDashboardElement(node.id, node.type);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const status = showAddElement ? "open" : "close";

    return (
        <GraphViewContext.Provider value={{
            addDashboardElement,
            removeDashboardElement,
            addEdge,
            selectedNodes,
        }}>
            <Graph nodes={nodes}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={handleSelection}
                nodeTypes={nodeTypes}
                fitViewOptions={{
                    padding: 5,
                }}
                minZoom={0.1}
                onPaneClick={handlePaneClick}
                actions={nodes.length === 0 ? undefined : [
                    {
                        icon: showAddElement ? Icons.Cancel : Icons.Add,
                        onClick: handleAddElement,
                        className: classNames({
                            "stroke-green-500": !showAddElement,
                            "stroke-orange-500": showAddElement,
                        })
                    },
                ]}>
                {
                    (nodes.length === 0 || showAddElement) &&
                    <motion.div className={classNames("absolute z-10", {
                        "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2": nodes.length === 0,
                        "right-20 bottom-8": showAddElement,
                    })} variants={{
                        open: {
                            opacity: 1,
                            display: "flex",
                        },
                        close: {
                            opacity: 0,
                            transitionEnd: {
                                display: "none",
                            }
                        },
                    }} animate={nodes.length === 0 ? undefined : status} initial={nodes.length === 0 ? undefined : status}>
                        <Card icon={ADD_DASHBOARD_ICON} className={classNames("w-[400px] h-[400px]", {
                            "shadow-xl": showAddElement,
                        })}>
                            <AddDashboardElementForm addDashboardElement={addDashboardElement} />
                        </Card>
                    </motion.div>
                }
            </Graph>
        </GraphViewContext.Provider>
    )
}

export const GraphView: FC = () => {
    return <ReactFlowProvider>
        <View />
    </ReactFlowProvider>
}