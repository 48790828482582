import classNames from "classnames";
import { motion } from "framer-motion";
import { FC, ReactElement, ReactNode, cloneElement } from "react";
import { twMerge } from "tailwind-merge";
import { AnimatedDropdown } from "./dropdown";

export type IButtonProps = {
  className?: string;
  label: string;
  icon: ReactElement;
  iconClassName?: string;
  labelClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button: FC<IButtonProps> = (props) => {
  return <motion.button className={twMerge(classNames("rounded-lg border flex justify-center items-center text-xs px-2 py-1 cursor-pointer gap-1 bg-white hover:bg-gray-100", props.className, {
    "cursor-not-allowed": props.disabled,
  }))} onClick={props.onClick} disabled={props.disabled} whileTap={{ scale: 0.8 }}>
    <div className={classNames("text-xs", props.labelClassName)}>
      {props.label}
    </div>
    {cloneElement(props.icon, {
      className: classNames("w-4 h-4", props.iconClassName),
    })}
  </motion.button>
}

export const AnimatedButton: FC<IButtonProps> = (props) => {
  return <Button {...props} className={twMerge(classNames("transition-all hover:gap-2", props.className))} />
}

type IAnimatedButtonDropdownProps = {
  buttons: IButtonProps[];
}

export const AnimatedButtonDropdown: FC<IAnimatedButtonDropdownProps> = (props) => {
  return <AnimatedDropdown items={props.buttons.map(button => (
    <Button {...button} className={twMerge(classNames("transition-all hover:gap-2 justify-start whitespace-nowrap", button.className))} />
  ))} /> 
}

type IToggleButtonProps = {
  checked: boolean;
  label: string;
  onClick: () => void;
}

export const ToggleButton: FC<IToggleButtonProps> = (props) => {
  return (<label className="relative inline-flex items-center mb-5 cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" checked={props.checked} onClick={props.onClick} />
    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-500"></div>
    <span className="ms-3 text-sm font-medium text-gray-900">{props.label}</span>
  </label>)
}

export type IActionButtonProps = {
  icon: ReactElement;
  className?: string;
  containerClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: ReactNode;
}

export const ActionButton: FC<IActionButtonProps> = ({ onClick, icon, className, containerClassName, disabled, children }) => {
  return (
  <div className="group relative">
    <motion.button className={twMerge(classNames("rounded-full bg-white h-12 w-12 transition-all border border-gray-300 shadow-sm flex items-center justify-center", containerClassName, {
      "cursor-not-allowed": disabled,
      "hover:shadow-lg hover:cursor-pointer hover:scale-110": !disabled,
    }))} onClick={disabled ? undefined : onClick} whileTap={{ scale: 0.6, transition: { duration: 0.05 }, }}>
      {cloneElement(icon, {
          className: twMerge(classNames("w-8 h-8 stroke-gray-500 cursor-pointer", className))
      })}
    </motion.button>
    {children}
  </div>);
}

type IActionButtonsProps = {
  buttons: IActionButtonProps[];
}

export const ActionButtons: FC<IActionButtonsProps> = ({ buttons }) => {
  return <AnimatedDropdown className="group action-buttons" reverse={true} items={buttons.map(button => 
    <ActionButton {...button} />
  )} />
}