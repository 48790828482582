import { FC, useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import { AuthActions } from "../store/auth";

export const LogoutPage: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(AuthActions.setStatus("logging-out"));
  }, [dispatch]);

  return <div className="flex grow justify-center items-center">
    <div className="text-md">
      Logging out
    </div>
  </div>
}