import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { FC, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CreateCard } from "../../../components/card";
import { Page } from "../../../components/container";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { FlowRunCard, IFlowRun } from "./flow-run-card";
import { notify } from "../../../store/function";
import { CI_CD_FLOW_ICON } from "./flow-card";

type IFlowRunProps = {}

const getFlowRunQuery = loader("./get-flow-runs.graphql");
const runFlowMutation = loader("./run-flow.graphql");

export const FlowRunPage: FC<IFlowRunProps> = () => {
    const [runFlow, ] = useMutation(runFlowMutation);
    const [getFlowRuns, getFlowRunsResponse] = useLazyQuery(getFlowRunQuery);
    const [searchParams, ] = useSearchParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!searchParams.has("id")) {
            navigate(InternalRoutes.CI_CD.Flow.path);
            return;
        }
        getFlowRuns({
            variables: {
                flowId: searchParams.get("id"),
            }
        });
    }, [searchParams, navigate, getFlowRuns]);

    const handleRun = useCallback(() => {
        const flowId = searchParams.get("id");
        runFlow({
            variables: {
                id: flowId,
            },
            onCompleted() {
                notify("Running flow!", "success");
                getFlowRunsResponse.refetch({
                    variables: {
                        flowId: flowId,
                    }
                });
            },
        });
    }, [searchParams, runFlow, getFlowRunsResponse]);

    return (
        <Page routes={[InternalRoutes.CI_CD.Flow, InternalRoutes.CI_CD.FlowRun]}>
            <CreateCard icon={{
                    component: Icons.Play,
                    bgClassName: CI_CD_FLOW_ICON.bgClassName,
                }} label="Run your flow" actionLabel="Run" onClick={handleRun} />
            {
                !getFlowRunsResponse.loading && (getFlowRunsResponse.data?.FlowRuns?.map((flowRun: IFlowRun, i: number) => (
                    <FlowRunCard name={`Run ${getFlowRunsResponse.data.FlowRuns.length - i}`} flowRun={flowRun} />
                )))
            }
        </Page>
    )
}