import { FC } from "react";
import { useAppQuery } from "../../../api/query";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateClusterCard, ClusterCard, ICluster, getAllClustersQuery } from "./cluster-card";

type IClusterPageProps = {
    isCreating?: boolean;
}

export const ClusterPage: FC<IClusterPageProps> = (props) => {
    const getAllClustersResponse = useAppQuery(getAllClustersQuery);

    return (
        <Page routes={[InternalRoutes.Deployment.Cluster]}>
            <CreateClusterCard isCreating={props.isCreating} refetch={getAllClustersResponse.refetch} />
            {
                getAllClustersResponse.data?.Cluster.map((cluster: ICluster) => (
                    <ClusterCard key={cluster.Id} cluster={cluster} refetch={getAllClustersResponse.refetch} />
                ))
            }
        </Page>
    )
}