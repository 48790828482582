import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { FC, cloneElement, useCallback, useRef, useState } from "react";
import { AnimatedButton } from "../../../components/button";
import { AdjustableCard, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { InternalRoutes } from "../../../config/internal-routes";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { IDigitalOceanIntegration } from "../../integrations/digital-ocean-integrations/digital-ocean-integrations-card";
import { getAllIntegrationsQuery } from "../../integrations/integrations";
import { forEach, map } from "lodash";
import { IDropdownItem } from "../../../components/dropdown";
import { Tooltip } from "../../../components/tooltip";
import { IGoogleIntegration } from "../../integrations/google/google-integrations-card";

export const DEPLOY_CLUSTER_ICON = {
    component: Icons.Deploy.Cluster.Default,
    bgClassName: "bg-green-500",
}

export const getAllClustersQuery = loader("./get-clusters.graphql");
const getDigitalOceanClusterQuery = loader("./get-digital-ocean-clusters.graphql");
const getGoogleClusterQuery = loader("./get-google-clusters.graphql");
const getGoogleProjectsQuery = loader("./get-google-projects.graphql");
const getOneClusterQuery = loader("./get-one-cluster.graphql");
const createClusterMutation = loader("./create-cluster.graphql");
const updateClusterMutation = loader("./update-cluster.graphql");
const deleteClusterMutation = loader("./delete-cluster.graphql");

export enum ClusterType {
    DigitalOcean="DigitalOcean",
    Google="Google",
}

function getDefaultForm(navigate: NavigateFunction, cluster?: ICluster): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: cluster?.Name,
        },
        {
            name: "integrationId",
            label: "Integration",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration is required",
            query: getAllIntegrationsQuery,
            transform(data) {
                const dropdownItems: IDropdownItem[] = [];
                forEach(data?.Integration?.DigitalOceanIntegration ?? [], ({ Id, Name }) => {
                    dropdownItems.push({
                        id: Id,
                        label: Name,
                        icon: Icons.Logos.DigitalOcean,
                        extra: {
                            type: ClusterType.DigitalOcean,
                        }
                    });
                });
                forEach(data?.Integration?.GoogleIntegration ?? [], ({ Id, Name }) => {
                    dropdownItems.push({
                        id: Id,
                        label: Name,
                        icon: Icons.Logos.Google,
                        extra: {
                            type: ClusterType.Google,
                        }
                    });
                });
                return dropdownItems;
            },
            defaultIcon: Icons.Integrations,
            onClick(item, setForm) {
                setForm(f => {
                    f.type = item.extra.type;
                });
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an integration" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Integrations.CreateIntegrations.path);
                },
            },
            defaultValue: getClusterIntegrationId(cluster),
            disabled: getClusterIntegrationId(cluster) != null,
        },
        {
            name: "integrationClusterId",
            label: "Available clusters",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration cluster is required",
            query: getDigitalOceanClusterQuery,
            transform(data) {
                return map(data?.DigitalOceanKubernetesClusters ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a digital ocean cluster",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://cloud.digitalocean.com/kubernetes/clusters/new", "_blank");
                },
            },
            disabled: cluster?.DigitalOcean?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.DigitalOcean;
            },
        },
        {
            name: "googleProjectId",
            label: "Available projects",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Project id is required",
            query: getGoogleProjectsQuery,
            transform(data) {
                return map(data?.GoogleProjects ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterData?.Google?.ProjectId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a Google Cloud Project",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://console.cloud.google.com/projectcreate", "_blank");
                },
            },
            disabled: cluster?.Google?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.Google;
            },
        },
        {
            name: "integrationClusterId",
            label: "Available clusters",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration cluster is required",
            query: getGoogleClusterQuery,
            transform(data) {
                return map(data?.GoogleKubernetesClusters ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null || form.googleProjectId.length === 0) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                    projectId: form.googleProjectId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a Google Kubernetes cluster",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://console.cloud.google.com/kubernetes/auto/add", "_blank");
                },
            },
            disabled: cluster?.Google?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.Google;
            },
        },
    ]
}

type ICreateClusterCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateClusterCard: FC<ICreateClusterCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createCluster, createClusterResponse] = useMutation(createClusterMutation);
    const ref = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const navigate = useNavigate();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createCluster({
            variables: {
                name: form.name,
                type: form.type,
                integrationId: form.integrationId,
                integrationClusterId: form.integrationClusterId,
                googleClusterData: {
                    ProjectId: form.googleProjectId,
                },
            },
            onCompleted: () => {
                notify("Cluster created successfully", "success");
                ref.current?.();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create cluster", "error");
            }
        });
    }, [isFormValid, getForm, createCluster, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Cluster" icon={{
                component: Icons.Add,
                bgClassName: "bg-green-500",
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<div className="flex flex-col gap-1 items-end">
                    <Tooltip tooltip={<div className="text-sm">Clidey will install a few things on your cluster. This could affect pricing.</div>}>
                        {cloneElement(Icons.ExclamationTriangle, {
                            className: "w-6 h-6 stroke-orange-800",
                        })}
                    </Tooltip>
                    <div className="text-red-800 text-xs">{error}</div>
                </div>} loading={createClusterResponse.loading} loadingText="Creating cluster. This can take a few minutes.">
                <div className="flex flex-col gap-1 my-4 grow">
                    <Form variables={getDefaultForm(navigate)} {...formProps} />
                </div>
                <div className="flex items-center gap-2">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleClose} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Submit" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

export type ICluster = {
    Id: string;
    Name: string;
    Type: ClusterType;
    DigitalOcean?: IDigitalOceanIntegration;
    Google?: IGoogleIntegration;
    IntegrationClusterId: string;
    IntegrationClusterName: string;
    IntegrationClusterData?: {
        Google?: {
            ProjectId: string;
        }
    }
}

export const ClusterIntro: FC<{ cluster: ICluster }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className="text-md">
            {props.cluster.Name}
        </div>
        <div className="text-xs mt-2 flex flex-wrap gap-1">
            <Pill id={props.cluster.Id} label={props.cluster.IntegrationClusterName} ellipses={true} />
        </div>
    </div>
}

function getClusterIntegrationId(cluster?: ICluster) {
    if (cluster == null) {
        return null;
    }
    switch(cluster.Type) {
        case ClusterType.Google:
            return cluster.Google?.Id;
        case ClusterType.DigitalOcean:
            return cluster.DigitalOcean?.Id;
    }
}

type IClusterCardProps = {
    cluster: ICluster;
    refetch: () => void;
}

export const ClusterCard: FC<IClusterCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteCluster, deleteClusterResponse] = useMutation(deleteClusterMutation);
    const [updateCluster, updateClusterResponse] = useMutation(updateClusterMutation);
    const [formProps, { isFormValid, getForm }] = useFormHook({
        type: props.cluster.Type,
        integrationId: getClusterIntegrationId(props.cluster),
        googleProjectId: props.cluster.IntegrationClusterData?.Google?.ProjectId,
    });
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();
    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateCluster({
            variables: {
                id: props.cluster.Id,
                name: form.name,
            },
            onCompleted: () => {
                notify("Cluster updated successfully", "success");
                props.refetch();
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update cluster", "error");
            }
        });
    }, [isFormValid, getForm, updateCluster, props]);

    const handleDelete = useCallback(() => {
        deleteCluster({
            variables: {
                id: props.cluster.Id,
            },
            onCompleted: () => {
                notify("Cluster deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete cluster", "error");
            },
        });
    }, [props, deleteCluster]);


    return <GraphBasedExpandableCard id={props.cluster.Id} type={GraphElements.Cluster} icon={DEPLOY_CLUSTER_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<div className="flex flex-col gap-1 items-end">
            <div className="text-red-800 text-xs">{error}</div>
        </div>} highlight={searchParams.get("id") === props.cluster.Id} loading={deleteClusterResponse.loading || updateClusterResponse.loading}>
        <>
            <div className="flex flex-col grow">
                <div className="text-md mt-2">
                    {props.cluster.Name}
                </div>
            </div>
            <div className="flex">
                <AnimatedButton label="Edit" icon={Icons.Edit} onClick={() => toggleCardExpansion.current?.(true)} iconClassName="w-3 h-3" />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(navigate, props.cluster)} defaultExtraValues={getForm()} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <AnimatedButton label="Delete" icon={Icons.Delete} labelClassName="text-red-800" iconClassName="text-red-600" onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={handleCancel} labelClassName="text-amber-800" iconClassName="text-amber-600" />
                    <AnimatedButton label="Update" icon={Icons.CheckCircle} labelClassName="text-green-800" iconClassName="text-green-600" onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const ClusterDashboardCard: FC<{ cluster: ICluster }> = (props) => {
    return <AdjustableCard showTools={true} icon={DEPLOY_CLUSTER_ICON}>
        <ClusterIntro cluster={props.cluster} />
    </AdjustableCard>
}

export const transformClusterData: IDataTransform<ICluster>  = (data?: { Cluster: ICluster[]}) => data?.Cluster ?? [];

export const ClusterGraphCard: FC<IGraphCardProps<ICluster>> = (props) => {
    return <GraphCardLoader loader={getOneClusterQuery} transform={data => transformClusterData(data)[0]} {...props}>
        {(data: ICluster) => (<ClusterDashboardCard cluster={data} />)}
    </GraphCardLoader>;
}

export const AddClusterDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Cluster"
        query={getAllClustersQuery}
        transform={transformClusterData} type={GraphElements.Cluster}
        icon={Icons.Deploy.Cluster.Default}
        link={InternalRoutes.Deployment.CreatCluster.path} />
    );
}